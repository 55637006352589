import { ActionIcon, Grid, NumberInput, Select, Space, } from '@mantine/core'

import React from 'react';
import { IconPlus, IconTrash } from '@tabler/icons-react';



export const JournalEntryRow = ({ form, idx, custodianAccounts, calculcateTotal, disabled }: any) => {


    return <>
        <Grid.Col span={{ xs: 6, lg: 6 }}>
            {/* 
            <TextInput
                label="Financial account"
                placeholder="owner"
                required
                disabled={disabled}


            /> */}

            <Select
                searchable
                allowDeselect
                value={(form.values.entriesList[idx].financialAccount?.value || null)}
                disabled={disabled}
                comboboxProps={{ withinPortal: true }}
                // nothingFound={"Nothing Found"}
                label="Custodian Account "
                placeholder="Please select a custodian account"
                // itemComponent={AutoCompleteItem}
                // filter={(value: any, item: any) => {
                //     if (!value) return true;
                //     return JSON.stringify(item)?.toLowerCase().includes(value?.toLowerCase())
                // }}
                data={custodianAccounts}
                onChange={
                    (value: any) => {
                        const custodianAccount = custodianAccounts.find((account: any) => {
                            return account.value === value;
                        })

                        if (custodianAccount) {


                            let currentEntries = form.values?.entriesList || []

                            let currency = custodianAccount._UDF_CCY_;

                            currentEntries[idx] = { ...currentEntries[idx], financialAccount: custodianAccount, currency: currency }

                            form?.setValues((prev: any) => ({ ...prev, entriesList: currentEntries }))


                        }

                    }
                }
                // onItemSubmit={


                //     (custodianAccount: any) => {
                //         let currency = custodianAccount._UDF_CCY_;

                //         form.setValues((prev: any) => ({
                //             ...prev,
                //             "newCustodianAccount": custodianAccount,
                //             currencyToValue: currency,
                //         }))
                //     }
                // }

                maxDropdownHeight={280}
            // sx={{ maxHeight: 200, overflow: 'auto' }}
            />


        </Grid.Col>
        <Grid.Col

            span={{ xs: 6, lg: disabled ? 3 : 2 }}

        >
            <>
                {/* {
                    console.log("form.values.entriesList?", form.values.entriesList)
                } */}
            </>
            <NumberInput
                decimalScale={4}
                label="Debit"
                placeholder="Debit"
                disabled={disabled}
                min={0}

                hideControls

                required
                // {...form.getInputProps("entriesList")}
                value={(form.values.entriesList[idx]?.debit)}
                // onChange={(value: any) => {
                //     handleDebitComapny(value, idx)
                // }}

                // parser={(value) => value.replace(new RegExp(`(,*)`, "g"), '')}

                // formatter={(value) =>
                //     !Number.isNaN(parseFloat(value))
                //         ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                //         : ''
                // }

                onChange={(value) => {
                    let currentEntries = form.values?.entriesList || []

                    if (currentEntries.length)
                        currentEntries[idx] = { ...currentEntries[idx], debit: value, credit: 0 }
                    else
                        currentEntries.push({ ...currentEntries[idx], debit: value, credit: 0 })
                    form?.setValues((prev: any) => ({ ...prev, entriesList: currentEntries }))
                    if (calculcateTotal) {
                        calculcateTotal()
                    }
                    // setEntriesList(currentEntries);
                }}
            // onChange={handleChange}
            />
        </Grid.Col>


        <Grid.Col span={{ lg: disabled ? 3 : 2, xs: 6 }}>

            <NumberInput
                decimalScale={4}
                label="Credit"
                placeholder="credit"
                hideControls
                name="credit"
                required
                disabled={disabled}
                max={0}
                value={(form.values.entriesList[idx]?.credit)}



                onChange={(value) => {
                    let currentEntries = form.values?.entriesList || []

                    if (currentEntries.length)
                        currentEntries[idx] = { ...currentEntries[idx], credit: value, debit: 0 }
                    else
                        currentEntries.push({ credit: value, debit: 0 })

                    form?.setValues((prev: any) => ({ ...prev, entriesList: currentEntries }))
                    if (calculcateTotal) {
                        calculcateTotal()
                    }

                }}


            />
            {/* } */}
        </Grid.Col>

        {!disabled && <>
            <Grid.Col span={{ xs: 2, lg: 1 }} >
                <Space h="xl" />
                <ActionIcon

                    style={{ justifyContent: "center" }} onClick={() => {

                        let currentEntries = form.values?.entriesList || []


                        if (currentEntries.length > 2) {
                            currentEntries.splice(idx + 1, 0, { credit: 0, debit: 0 });
                        }
                        else currentEntries = [...currentEntries, { credit: 0, debit: 0 }];

                        // setEntriesList(currentEntries);
                        form?.setValues((prev: any) => ({ ...prev, entriesList: currentEntries }))
                        if (calculcateTotal) {
                            calculcateTotal()
                        }

                    }}>
                    <IconPlus size={25} />
                </ActionIcon>
            </Grid.Col>
            <Grid.Col span={{ xs: 6, lg: 1 }}>
                <Space h="xl" />
                {form.values.entriesList?.length - 1 >= 1 &&
                    <ActionIcon


                        onClick={() => {
                            let currentEntries = form.values.entriesList || []
                            if (currentEntries?.length && currentEntries?.length - 1 >= 1) {         // currentEntries.splice(idx, 1);

                                currentEntries.splice(idx, 1);
                                console.log("Removing at index", idx, currentEntries);

                                form?.setValues((prev: any) => ({ ...prev, entriesList: currentEntries }))
                                if (calculcateTotal) {
                                    calculcateTotal()
                                }
                            }
                            // setEntriesList([...currentEntries])

                        }
                        }
                    >
                        <IconTrash size={25} />
                    </ActionIcon>
                }



            </Grid.Col>

        </>
        }

    </>

}

// export default JournalEntryRow