import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Grid, Notification, NotificationProps, Text } from '@mantine/core';
import { areEqualArrays } from '../../utils';
import UserGroupsSearch from '../../components/user_groups_search';
import { doc, updateDoc } from 'firebase/firestore';
import { AuthorizationClaims, ConfigDataType, KFHGroup } from '../../types';
import { ConfigContext, MyUserDataContext } from '../../contexts';
import { useFirestore } from '../../helpers/firebaseContext';

const CustomClaimsSettings = () => {
    const configContext = useContext(ConfigContext);
    const appContext = useContext(MyUserDataContext);

    const [authClaimsData, setAuthClaimsData] = useState<Partial<AuthorizationClaims>>({})
    const [notificationData, setNotificationData] = useState<null | NotificationProps>();

    const db = useFirestore();

    useEffect(() => {
        console.log("the config", configContext);

        if (configContext?.authorizationClaims) {
            setAuthClaimsData(configContext?.authorizationClaims);
        }
    }, [configContext, configContext?.authorizationClaims]);

    const onItemSubmit = (values: string[], key: string) => {
        if (key) {
            const authClaimsDataUpdated = {
                ...authClaimsData, claims: {
                    ...authClaimsData.claims,
                    [key]:
                        values.sort()
                }
            }
            setAuthClaimsData(authClaimsDataUpdated)
        }
    }


    const handleSubmit = () => {

        const configCollectionRef = doc(db, "config", "authorizationClaims",);

        let docData: any = {
            claims: { ...authClaimsData.claims },
            "updatedBy":
                appContext?.msal?.account?.username
            ,
        }
        console.log("handleSubmit =======>> ", docData);
        updateDoc(configCollectionRef, {
            ...docData
        }).then(() => {
            setNotificationData({ color: "green", children: <>Approvers updated Successfully</> })
        }).catch((e) => {
            console.error("handleSubmit eeeeee =======>> ", e);

            setNotificationData({ color: "red", children: <>Approvers Failed to Update</> })

        });
    }

    const handleReset = () => {
        console.log("handleReset ");

    }


    return (
        <div>

            <Card shadow="sm" p="xl" radius="md" withBorder>
                <Grid
                    justify='flex-start'
                    ta='center'
                >
                    <Grid.Col span={{ xs: 12 }}>
                        <Text fw={700} m={"xl"}> Update Permissions </Text>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12 }}>
                        {notificationData && <Grid.Col span={{ xs: 12 }}>

                            <Notification {...notificationData} onClose={() => setNotificationData(null)} />
                        </Grid.Col>}

                    </Grid.Col>
                    {
                        Object.keys(KFHGroup).map((keyString) => {
                            const key = keyString as KFHGroup;

                            return <Grid.Col key={keyString} span={{ xs: 12 }}>

                                <Grid.Col span={{ lg: 6, xs: 12 }}  >
                                    <Grid.Col span={{ xs: 12 }}  >
                                        <UserGroupsSearch values={authClaimsData?.claims?.[key]} label={key} objKey={key} onItemSubmit={onItemSubmit} apiURl={"/groups/"} />
                                    </Grid.Col>
                                </Grid.Col>

                                {!areEqualArrays(authClaimsData?.claims ? authClaimsData?.claims[key] : [], configContext?.authorizationClaims?.claims ? configContext?.authorizationClaims?.claims[key] ?? [] : []) && <Grid.Col span={{ lg: 6, xs: 12 }}  >

                                    {/* <Card shadow="sm" padding="lg" radius="md" withBorder> */}
                                    <Grid justify='flex-start'>



                                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                                            <Button
                                                c={"green"}
                                                variant='outline'
                                                onClick={handleSubmit}
                                            >
                                                Submit Change
                                            </Button>
                                        </Grid.Col>
                                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                                            <Button
                                                variant='outline'
                                                c={"red"}
                                                onClick={handleReset}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid.Col>
                                    </Grid>

                                    {/* </Card> */}

                                </Grid.Col>}
                            </Grid.Col>
                        })

                    }


                </Grid>
            </Card >

        </div >
    )
}

export default CustomClaimsSettings