import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Controls, Background, Handle, Position, applyNodeChanges, applyEdgeChanges, addEdge, Panel, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';
import Dagre from '@dagrejs/dagre';
import { Card, Divider, Menu, TextInput } from '@mantine/core';
import { Text } from '@mantine/core';
import { IconSettings, IconPhoto, IconMessageCircle, IconArrowsLeftRight } from '@tabler/icons-react';
// const edges = [{ id: '1-2', source: '1', target: '2' }];

// const nodes = [
//     {
//         id: '1',
//         data: { label: 'Hello' },
//         position: { x: 0, y: 0 },
//         type: 'input',
//     },
//     {
//         id: '2',
//         data: { label: 'World' },
//         position: { x: 100, y: 100 },
//     },
// ];

function NodeMenu({ opened, setOpened, position, nodeTypeCallback }: any) {

    //TODO: make menu position relative to mouse click

    return (
        <Menu shadow="md" width={200} opened={opened} onChange={setOpened} position={position} >
            <Menu.Dropdown  >
                <Menu.Label>Nodes</Menu.Label>
                <Menu.Item leftSection={<IconSettings size={14} />} onClick={() => nodeTypeCallback("functionNode")}>Function</Menu.Item>
                <Menu.Item leftSection={<IconMessageCircle size={14} />} onClick={() => nodeTypeCallback("functionNode")}>Send SMS</Menu.Item>
                <Menu.Item leftSection={<IconPhoto size={14} />} onClick={() => nodeTypeCallback("actionNode")}>Action</Menu.Item>
                <Menu.Item leftSection={<IconArrowsLeftRight size={14} />} onClick={() => nodeTypeCallback("waitNode")}>Wait for document</Menu.Item>
                <Menu.Item leftSection={<IconPhoto size={14} />} onClick={() => nodeTypeCallback("endNode")}>End</Menu.Item>

            </Menu.Dropdown>
        </Menu>
    );
}


function ContextMenu({ id, top, left, right, bottom, opened, setOpened, nodeTypeCallback, ...props }: any) {
    const { getNode, setNodes, addNodes, setEdges } = useReactFlow();
    const duplicateNode = useCallback(() => {
        const node = getNode(id)!;
        const position = {
            x: node.position.x + 50,
            y: node.position.y + 50,
        };

        addNodes({ ...node, id: `${node.id}-copy`, position });
    }, [id, getNode, addNodes]);

    const deleteNode = useCallback(() => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        setEdges((edges) => edges.filter((edge) => edge.source !== id));
    }, [id, setNodes, setEdges]);

    return (
        <div style={{ top, left, right, bottom }} className="context-menu" {...props}>
            {/* 
            <Menu shadow="md" width={200} opened={true} onChange={setOpened} >
                <Menu.Dropdown  >
                    <Menu.Label>Nodes</Menu.Label>
                    <Menu.Item icon={<IconSettings size={14} />} onClick={() => nodeTypeCallback("functionNode")}>Function</Menu.Item>
                    <Menu.Item icon={<IconMessageCircle size={14} />} onClick={() => nodeTypeCallback("functionNode")}>Send SMS</Menu.Item>
                    <Menu.Item icon={<IconPhoto size={14} />} onClick={() => nodeTypeCallback("actionNode")}>Action</Menu.Item>
                    <Menu.Item icon={<IconArrowsLeftRight size={14} />} onClick={() => nodeTypeCallback("waitNode")}>Wait for document</Menu.Item>
                    <Menu.Item icon={<IconPhoto size={14} />} onClick={() => nodeTypeCallback("endNode")}>End</Menu.Item>

                </Menu.Dropdown>
            </Menu> */}


            <p style={{ margin: '0.5em' }}>
                <small>node: {id}</small>
            </p>
            <button onClick={duplicateNode}>duplicate</button>
            <button onClick={deleteNode}>delete</button>
        </div>
    );
}



const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));


const getLayoutedElements = (nodes: any, edges: any, options: any) => {
    g.setGraph({ rankdir: options.direction });

    edges.forEach((edge: any) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node: any) => g.setNode(node.id, node));

    Dagre.layout(g);

    return {
        nodes: nodes.map((node: any) => {
            const { x, y } = g.node(node.id);

            return { ...node, position: { x, y } };
        }),
        edges,
    };
};



const availableFunctions: any = {

    "generateKYCLink":
    {
        name: "Generate KYC Link",
        handles: ["done"]
    },

    "sendSMS": {
        name: "Send SMS",
        handles: ["sent"],
    },

    "isHighRisk": {
        name: "High risk?",
        handles: ["true", "false"]
    },
}

let generateHandles = (handles: any) => {
    let handleHeight = 20; // assuming each handle has a height of 20
    let nodeHeight = 130; // assuming the node has a height of 100
    let totalHandlesHeight = handles.length * handleHeight;

    let startingTopPosition = (nodeHeight - totalHandlesHeight) / 2 + 20;
    if (handles.length % 2 !== 0) { // if number of handles is odd
        startingTopPosition -= handleHeight;
    }
    let handlesArray = [] as any;

    let maxLength = 0;

    handles.forEach((handle: any, index: number) => {
        let topPosition = startingTopPosition + (index * handleHeight);
        if (handle.length > maxLength) {
            maxLength = handle.length;
        }

        handlesArray.push(
            <Handle type="source" position={Position.Right} id={handle} style={{ top: topPosition, right: -1 }}>
                <Text fz="xs" style={{
                    left: `-${handle.length}em`,
                    top: "-0.5em",
                    position: "fixed",
                    textAlign: "right",
                    width: handle.length + "em"
                }} >{handle}</Text>
            </Handle >
        );
    });

    return [maxLength, handlesArray];

}



const serialize = (nodes: any, edges: any) => {

    console.log("serializing", nodes, edges);
    // JSON.stringify(obj, null, 2);

    //this is gonna be relatively slow since I'm searching arrays, but what the hell.

    let entries = {} as any
    nodes.forEach((node: any) => {

        let obj = {
            name: node.data.name,
            position: node.position,
        } as any;

        if (node.data.update_state) {
            obj.update_state = node.data.update_state;
        }
        if (node.data.color) {
            obj.color = node.data.color;
        }

        if (node.data.function) {
            obj.function = node.data.function;
            obj.move_to_function_result = {} as any;

            //loop through edges
            edges.forEach((edge: any) => {
                if (edge.source === node.id) {
                    obj.move_to_function_result[edge.sourceHandle] = edge.target;
                }
            });

        }
        if (node.data.actions) {
            obj.actions = node.data.actions;

            //loop through edges
            edges.forEach((edge: any) => {
                if (edge.source === node.id) {
                    //find the action that matches the handle
                    obj.actions.forEach((action: any) => {
                        if (action.name === edge.sourceHandle) {
                            action.move_to = edge.target;
                        }
                    });
                }
            });

        }
        if (node.data.start) {
            obj.start = node.data.start;
        }
        if (node.data.successful !== undefined) {
            obj.successful = node.data.successful;
        }
        if (node.data.wait_for_submission) {
            obj.wait_for_submission = node.data.wait_for_submission;

            //loop through edges
            edges.forEach((edge: any) => {
                if (edge.source === node.id) {
                    obj.wait_for_submission.move_to = edge.target;
                }
            });

        }

        entries[node.id] = obj;

    });

    // console.log(entries);

    return entries;

}

function BaseNode(props: any) {

    const data = props.data;

    // console.log(data);
    let handles = [] as any;
    let maxLength = data.name.length;

    const [name, setName] = useState(data.name);


    if (data.function) {
        let functionName = data.function as string;

        [maxLength, handles] = generateHandles(availableFunctions[functionName].handles);
        // console.log(handles)
    }
    else if (data.actions) {

        [maxLength, handles] = generateHandles(data.actions.map((action: any) => action.name));

    }
    else if (!data.successful) {
        handles.push(<Handle type="source" position={Position.Right} id="1" style={{ right: -1, top: 50 }} />);
    }

    // console.log(handles);

    //get max length of handle text and height

    if (maxLength < data.name.length) {
        maxLength = data.name.length;
    }

    let height = handles.length * 25 + 50;
    let width = maxLength * 8;

    return (

        <Card withBorder p="xl" style={{ height: height, width: width }}>
            <Card.Section>
                {/* <Title order={6} c={props.titleColor}>{data.name}</Title> */}
                <TextInput variant='unstyled' style={{ color: props.titleColor }} value={name} onChange={(event) => setName(event.currentTarget.value)}></TextInput>
                {/* <div>
                    <label htmlFor="text">{data.name}</label>
                </div> */}
                <Divider />

            </Card.Section>

            {!data.start &&
                <Handle type="target" position={Position.Left} style={{ left: -2 }} />
            }

            {props.children}

            {handles}
        </Card>
    );
}

function ActionNode({ data }: any) {
    return (
        <BaseNode data={data} titleColor="orange">

        </BaseNode>
    )
}

function FunctionNode({ data }: any) {
    return (
        <BaseNode data={data} titleColor="green">

        </BaseNode>
    )
}

function StartNode({ data }: any) {
    return (


        <BaseNode data={data} titleColor="blue">

        </BaseNode>

    );
}

function EndNode({ data }: any) {
    return (


        <BaseNode data={data} titleColor={data.color}>

        </BaseNode>

    );
}

function WaitNode({ data }: any) {
    return (


        <BaseNode data={data} titleColor="yellow">

        </BaseNode>

    );
}



function Flow({ flows, save }: any) {

    const nodeTypes = useMemo(() => ({ functionNode: FunctionNode, actionNode: ActionNode, startNode: StartNode, endNode: EndNode, waitNode: WaitNode }), []);

    const [nodes, setNodes] = useState([] as any);
    const [edges, setEdges] = useState([] as any);

    const [nodeMenuOpened, setNodeMenuOpened] = useState(false);

    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 } as any);

    let getId = () => {

        let id = Math.random().toString(36).substring(7);
        // eslint-disable-next-line 
        while (nodes.find((node: any) => node.id === id)) {
            id = Math.random().toString(36).substring(7);
        }
        return id;
    }


    const onLayout = useCallback(
        (direction: any) => {
            const layouted = getLayoutedElements(nodes, edges, { direction });

            setNodes([...layouted.nodes]);
            setEdges([...layouted.edges]);


        },
        [nodes, edges]
    );

    const { project } = useReactFlow();


    useEffect(() => {

        if (!flows || flows.length === 0) return;



        console.log(flows);

        let initialNodes = [] as any;

        //loop through object
        for (const [id, flow] of Object.entries(flows) as any) {
            let type = null;
            if (flow.function) {
                type = "functionNode";
            }
            else if (flow.actions) {
                type = "actionNode";
            }
            else if (flow.start) {
                type = "startNode";
            }
            else if (flow.successful !== undefined) {
                type = "endNode";
            }
            else if (flow.wait_for_submission) {
                type = "waitNode";
            }

            flow.label = flow.name;

            initialNodes.push({ id: id, data: flow, position: flow.position, type: type });

        };

        let initialEdges = [] as any;

        for (const [id, flow] of Object.entries(flows) as any) {

            if (flow.function) {
                // let functionName = flow.function as string;
                let possibleResults = flow.move_to_function_result;
                //loop through dictionary
                for (const [key, value] of Object.entries(possibleResults)) {
                    initialEdges.push({ id: id + "-" + key, source: id, sourceHandle: key, target: value, label: key });
                }
            }
            if (flow.actions) {
                for (const action of flow.actions) {
                    initialEdges.push({ id: id + "-" + action.name, source: id, target: action.move_to, sourceHandle: action.name, label: action.name });
                }

            }
            if (flow.start) {
                initialEdges.push({ id: id + "-" + flow.start, source: id, sourceHandle: "1", target: flow.start });

            }
            if (flow.wait_for_submission) {
                initialEdges.push({ id: id + "-submitted", source: id, target: flow.wait_for_submission.move_to, label: "submitted" });
            }
        };

        setNodes(initialNodes);
        setEdges(initialEdges);

        // onLayout('TB')

    }, [flows]);


    const onNodesChange = useCallback((changes: any) => setNodes((nds: any) => applyNodeChanges(changes, nds)), []);
    const onEdgesChange = useCallback((changes: any) => setEdges((eds: any) => applyEdgeChanges(changes, eds)), []);
    const onConnect = useCallback((params: any) => setEdges((eds: any) => addEdge(params, eds)), []);

    const connectingNodeId = useRef(null) as any;

    const reactFlowWrapper = useRef(null) as any;


    const onConnectStart = useCallback((_: any, { nodeId }: any) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event: any) => {
            const targetIsPane = event.target.classList.contains('react-flow__pane');

            if (targetIsPane) {
                // we need to remove the wrapper bounds, in order to get the correct position
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();


                setNodeMenuOpened(true);

                setClickPosition({ x: event.clientX - left, y: event.clientY - top });

                // const id = getId();
                // const newNode = {
                //     id,
                //     // we are removing the half of the node width (75) to center the new node
                //     position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
                //     data: { label: `Node ${id}` },
                // };


                // setNodes((nodes: any) => nodes.concat(newNode));
                // setEdges((edges: any) => edges.concat({ id, source: connectingNodeId.current, target: id }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [project]
    );


    const nodeTypeCallback = (nodeType: any) => {

        setNodeMenuOpened(false);

        const id = getId();
        const newNode = {
            id,
            // we are removing the half of the node width (75) to center the new node
            position: project({ x: clickPosition.x - 75, y: clickPosition.y }),
            data: { name: `${nodeType} ${id}` },
            type: nodeType,
        };

        setNodes((nodes: any) => nodes.concat(newNode));
        setEdges((edges: any) => edges.concat({ id, source: connectingNodeId.current, target: id }));

    };

    const [menu, setMenu] = useState(null as any);



    const onNodeContextMenu = useCallback(
        (event: any, node: any) => {
            // Prevent native context menu from showing
            event.preventDefault();

            // Calculate position of the context menu. We want to make sure it
            // doesn't get positioned off-screen.
            const pane = reactFlowWrapper.current.getBoundingClientRect();
            setMenu({
                id: node.id,
                top: event.clientY < pane.height - 200 && event.clientY,
                left: event.clientX < pane.width - 200 && event.clientX,
                right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
                bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
            });
        },
        [setMenu]
    );
    // Close the context menu if it's open whenever the window is clicked.
    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

    return (
        <div style={{ height: '100%' }}>

            <NodeMenu opened={nodeMenuOpened} setOpened={setNodeMenuOpened} position={clickPosition} nodeTypeCallback={nodeTypeCallback} />
            <ReactFlow nodes={nodes} edges={edges} nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                onPaneClick={onPaneClick}
                onNodeContextMenu={onNodeContextMenu}

                ref={reactFlowWrapper} >
                <Background />
                <Controls />
                {menu && <ContextMenu onClick={onPaneClick} {...menu} />}

                <Panel position="top-right">
                    <button onClick={() => onLayout('TB')}>Vertical layout</button>
                    <button onClick={() => onLayout('LR')}>Horizontal layout</button>
                    <button onClick={() => save(serialize(nodes, edges))}>Save</button>
                </Panel>

            </ReactFlow>


        </div>
    );
}

export default Flow;

