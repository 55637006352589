

import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import getFirebaseConf from './firebaseConf';


const app = initializeApp(getFirebaseConf());
const storage = getStorage(app)

export const uploadToStorage = async (file: any, path: string, tags?:Record<string, string>) => {
    if (file) {
        const listRef = ref(storage, path);
        let metadata = {};
        if (tags) {
            // tags.forEach((tag) => {
            //     metadata = { ...metadata, [tag]: true }
            // });
            metadata = { "customMetadata":{...tags} }
        }
        return await uploadBytesResumable(ref(listRef, file.name), file, metadata);
    }

} 