import { Box, Button, Collapse, Group, Table, Text } from "@mantine/core";

import { useDisclosure } from '@mantine/hooks';


export const InfoTable = (props: any) => {

    const [opened, { toggle }] = useDisclosure(false);


    if (typeof props.rows === "undefined") return null;
    let rows = props.rows;

    let fz = props.fz || "sm"

    if (!Array.isArray(rows) && typeof rows === "object") {
        if (Object.keys(props.rows).length === 0) return null;
        rows = Object.keys(props.rows).map((key: any) => {
            return { key, value: props.rows[key] }
        });
    }

    // console.log(typeof rows, "Rows is", rows)
    return <>
        <Button variant="light" color="teal" size="compact-xs" onClick={toggle}>{opened ? "Hide" : "Show"} data</Button>

        <Collapse in={opened}>
            <Table>
                <tbody>
                    {rows.map((item: any) => {
                        if (item.value === undefined || item.value === null || item.value === "") return null;

                        return <tr key={item.key}>
                            <td><Text fw={700} fz={fz}>{item.key}</Text></td>
                            <td><Text fz={fz}>{item.value}</Text></td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Collapse>
    </>

}