import { Alert, Button, Grid, Loader } from '@mantine/core'
import { IconAlertCircle, } from '@tabler/icons-react'
import React, { useContext, useState } from 'react'
import { MyUserDataContext } from '../../contexts';
import { httpsCallable } from 'firebase/functions';
import { Action, ActionType, BaseResult } from '../../types';
import { useNavigate } from 'react-router-dom';
import { useFunctions } from '../../helpers/firebaseContext';

const TransactionsActions = ({ transactionObj = {} }: any) => {
  const [pending, setPending] = useState<any>(false)
  const appContext = useContext(MyUserDataContext);
  const functions = useFunctions();
  const [msg, setMsg] = React.useState(null as any);
  const [isError, setIsError] = React.useState(false);

  const navigate = useNavigate();

  const handleStatusChange = async (status: ActionType) => {
    setPending(true)
    setMsg(null)
    setIsError(false)

    let token = await appContext.getAzureADToken!(appContext);

    if (!token) {
      console.log("No AD token");
      setMsg("No Azure token.  Please login again.");
      setIsError(true);
    }

    const actionValues: Action = {
      transactionID: transactionObj.id,
      customerID: transactionObj.customerID,
      action: status,
      token: token!
    }
    const takeAction = httpsCallable<Action, BaseResult>(functions, 'transactionAction');
    try {
      console.log("Taking action in handleStatusChange", actionValues);
      const result = await takeAction(actionValues);
      setPending(false);
      console.log("result is ", result);
      navigate("/transactions");

      return result
    } catch (error: any) {
      console.log("error is ", error);
      setMsg(error.message);
      setIsError(true);
    }
    setPending(false)
  }


  console.log("transactionObj============>>>>>>>>", transactionObj);

  if (!Object.keys(transactionObj).length)
    return <></>

  return (
    <>                    {
      !!Object.keys(transactionObj).length && transactionObj?.approvals?.length && <>



        {
          transactionObj?.approvals[transactionObj?.approvals?.length - 1]?.type === "approve" &&
          <Grid.Col span={{ xs: 12 }}>

            <Alert
              style={{ marginTop: 20 }}
              icon={<IconAlertCircle size={16} />}
              color={"green"}
              title={"Approved"}
            >

            </Alert>
          </Grid.Col>
        }

        {
          transactionObj?.approvals[transactionObj?.approvals?.length - 1]?.type === "reject" &&
          <Grid.Col span={{ xs: 12 }}>

            <Alert
              style={{ marginTop: 20 }}
              icon={<IconAlertCircle size={16} />}
              color={"red"}
              title={"Rejected"}
            >

            </Alert>
          </Grid.Col>
        }

        {
          transactionObj?.approvals[transactionObj?.approvals?.length - 1]?.type === "review" &&
          <Grid.Col span={{ xs: 12 }}>

            <Alert
              style={{ marginTop: 20 }}
              icon={<IconAlertCircle size={16} />}
              c={"yellow"}
              title={"Review in process"}
            >

            </Alert>
          </Grid.Col>
        }





      </>

    }

      <Grid.Col span={{ xs: 12 }}>

        {msg && <Alert
          style={{ marginTop: 20 }}
          icon={<IconAlertCircle size={16} />}
          c={isError ? "red" : "green"}
          title={isError ? "Failure" : "Success"}
        >
          {msg}
        </Alert>
        }
      </Grid.Col>
      {
        (!transactionObj?.approvals) &&

        <>
          {!pending ?
            <>


              <Grid.Col span={{ xs: 12, lg: 6 }}>
                <Button

                  color={"green"} fullWidth onClick={
                    () => handleStatusChange("approve")
                  } >
                  Approve
                </Button>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>
                <Button

                  color={"red"}
                  fullWidth onClick={
                    () => handleStatusChange("reject")
                  } >
                  Reject
                </Button>
              </Grid.Col>

            </> :
            <>
              <Grid.Col span={{ xs: 12 }}>

                <Alert
                  style={{ marginTop: 20 }}
                  icon={<Loader />}

                >
                  Sending Request

                </Alert>
              </Grid.Col>
            </>}

        </>
      }</>
  )
}

export default TransactionsActions