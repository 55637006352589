import { Accordion, Loader, Table, Title, Text, Group, ThemeIcon, Badge, SimpleGrid, Paper, Space, Tooltip } from '@mantine/core';
import React, { ReactNode, useContext } from 'react';
import { Customer, Fund, GWIHolding, Portfolio, Position } from '../../types';
import { IconCash, IconChartLine, IconFolder, IconGlobe } from '@tabler/icons-react';
import { CustomInfoCard } from './CustomInfoCard';
import NumberDisplay from '../../components/NumberDisplay';
import { ConfigContext, GlobalDataContext } from '../../contexts';
import { FundSummarizedData } from '../../components/fund_summarized_data';

export interface CustomerInfoProps {
  customerData: Customer;
  portfoliosData: Portfolio[];
}

// Interface for grouped positions
interface GroupedPosition {
  exchange: string;
  currency: string;
  title: string;
  list: Position[];
  combinedPosition: Position;
}

export const CustomerInfo = ({ customerData, portfoliosData }: CustomerInfoProps) => {

  //use context data
  const configContext = useContext(ConfigContext);
  const globalDataContext = useContext(GlobalDataContext);


  if (!portfoliosData || portfoliosData.length <= 0) {
    return <Paper ta={'center'} p={25} ><Text>There are no records to display</Text></Paper>
  }

  // Group holdings by their GL code
  const groupedHoldings: { [key: string]: GWIHolding[] } = {};

  let totalValue: number = 1;

  // Calculate total value of holdings and group them by GL code
  if (customerData.holdings) {
    //updatedHoldings will hold the total in KWD
    const updatedHoldings: Record<string, GWIHolding> = {};
    Object.entries(customerData.holdings).forEach(([key, holding]) => {

      //this will convert all the holdings total to KWD and if the fund currency is not available in "/config/exchange_rates" the total will be 0
      let fund = globalDataContext.funds?.find(obj => obj.code === holding.code);
      let holdingTotal = holding.total;
      if (fund) {
        if (fund.currency !== "KWD") {
          let rate = parseFloat(configContext.exchange_rates?.rates[fund.currency] ?? 0);
          holdingTotal = (holding.total * rate);
        }
      }

      updatedHoldings[key] = {
        ...holding,
        total: holdingTotal
      }
      if (!groupedHoldings[holding.gl_code]) {
        groupedHoldings[holding.gl_code] = [];
      }
      groupedHoldings[holding.gl_code].push(updatedHoldings[key]);
    });
    totalValue = Object.values(updatedHoldings).reduce((sum, holding) => sum + holding.total, 0);

  }

  //arange fund to be by map by code
  const fundByCode: { [key: string]: Fund } = {};

  globalDataContext.funds?.map((fund) => {
    if (fund.code) {
      fundByCode[fund.code] = fund;
    }
  });


  return (
    <Accordion chevronPosition="right" variant="contained">
      {portfoliosData?.map(portfolio => <PortfolioInfo portfolio={portfolio as Portfolio} customerData={customerData} />)}
      {customerData.holdings &&
        <Accordion.Item value="funds" key="funds">
          <Accordion.Control>
            <Group >
              <Title order={4}>{"Funds"}'s Portfolio</Title>
              <Badge color='green'>Active</Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Paper shadow="xs" p="md">
              <Space h="xs" />
              <HoldingsTable groupedHoldings={groupedHoldings} totalValue={totalValue} fundByCode={fundByCode} />
            </Paper>
          </Accordion.Panel>
        </Accordion.Item>
      }
    </Accordion>
  )




}


export interface PortfolioInfoProps {
  customerData: Customer;
  portfolio: Portfolio;
}


// Component to display portfolio information
const PortfolioInfo = ({ portfolio, customerData }: PortfolioInfoProps) => {

  // Skip rendering if portfolio type is 145 which is funds portfolio (already added in the CustomerInfo component)
  if (portfolio.portfolioTypeID == 145) {
    return null;
  }

  // Function to get table rows for positions
  function getRows(positionType: "foreign" | "local", positions: Position[]): React.ReactNode {
    if (!positions) {
      return undefined;
    }
    positions = calculatePortfolioPercentages(positions);
    const groupedPositions = groupPositionsByExchangeAndCurrency(positions);
    let rows: JSX.Element[] = [];

    // Generate rows for each grouped position
    for (const groupedPosition of groupedPositions) {
      if (groupedPosition.exchange && groupedPosition.currency) {
        rows.push(
          <Table.Tr key={groupedPosition.title}>
            <Table.Td ta='left' colSpan={16}>{groupedPosition.title}</Table.Td>
          </Table.Tr>
        );
      }
      // Generate rows for each position within the group
      for (const element of groupedPosition.list) {
        if (element.cash) {
          rows.push(
            <Table.Tr key={element.code}>
              {positionType == "local" && <Table.Td colSpan={7}>{"Total " + element.currency + " Cash"}</Table.Td>}
              {positionType == "foreign" && <>
                <Table.Td colSpan={4}>{"Total Cash In " + element.exchange}</Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
              </>
              }

              <Table.Td><NumberDisplay value={element.total_market_value} withCommas /></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td>-</Table.Td>
              {positionType == "foreign" && <>
                <Table.Td><NumberDisplay value={element.total_cost_year_close_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_market_value_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_fx} withCommas withBrackets /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_equity} withCommas withBrackets /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_unrealized_gain_loss_local} withCommas withBrackets /></Table.Td>
              </>}
              <Table.Td><NumberDisplay value={element.portfolio_percent} /></Table.Td>
            </Table.Tr>
          );
        } else {
          rows.push(
            <Table.Tr key={element.code}>
              <Table.Td>{element.code}</Table.Td>
              <Table.Td>{element.name}</Table.Td>
              <Table.Td><NumberDisplay value={element.quantity} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.bonus_shares_and_right_issues} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.average_cost} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.total_cost} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.total_market_value} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={element.unrealized_gain_loss} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={element.gain_loss_percent} withCommas withBrackets withColor /></Table.Td>
              {positionType == "foreign" && <>
                <Table.Td><NumberDisplay value={element.total_cost_year_close_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_market_value_local} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_fx} withCommas withBrackets withColor /></Table.Td>
                <Table.Td><NumberDisplay value={element.unrealized_equity} withCommas withBrackets withColor /></Table.Td>
                <Table.Td><NumberDisplay value={element.total_unrealized_gain_loss_local} withCommas withBrackets withColor /></Table.Td>
              </>}
              <Table.Td><NumberDisplay value={element.portfolio_percent} /></Table.Td>
            </Table.Tr>
          );
        }

      }
      // Add total securities row if there are positions
      if ((groupedPosition.list?.length ?? 0) > 0 && groupedPosition.combinedPosition.cash == false) {
        rows.push(
          <Table.Tr key={groupedPosition.title + "2"}>
            <Table.Td colSpan={4}>{"Total Securities in " + groupedPosition.exchange}</Table.Td>

            <Table.Td></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_cost} withCommas /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_market_value} withCommas /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_gain_loss} withCommas withBrackets withColor /></Table.Td>
            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.gain_loss_percent} withCommas withBrackets withColor /></Table.Td>
            {positionType == "foreign" && <>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_cost_year_close_local} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_market_value_local} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_fx} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.unrealized_equity} withCommas withBrackets withColor /></Table.Td>
              <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.total_unrealized_gain_loss_local} withCommas withBrackets withColor /></Table.Td>
            </>}

            <Table.Td><NumberDisplay value={groupedPosition.combinedPosition.portfolio_percent} /></Table.Td>
          </Table.Tr>
        );
      }
    }

    return rows;
  }

  // Function to get the positions table for a given type of position (foreign/local)
  function getPositionsTable(positionType: "foreign" | "local", positions: Position[]) {
    return <Paper shadow="xs" p="md">
      <Group>
        <ThemeIcon color="blue" variant="light">
          {<IconGlobe size={16} />}
        </ThemeIcon>
        <Title order={5}>
        </Title>
        {positionType == "foreign" ? "Foreign Position" : "Local Position" as ReactNode}
      </Group>
      <Space h="xs" />
      <Table striped withTableBorder withColumnBorders ta="center" >
        <Table.Thead>
          <Table.Tr >
            <Table.Th ta="center" >Sec. <br />No</Table.Th>
            <Table.Th ta="center" >Security <br />Name</Table.Th>
            <Table.Th ta="center" >Quantity</Table.Th>
            <Table.Th ta="center" >Bonus <br />Shares + <br />Right Issues</Table.Th>
            <Table.Th ta="center" >Avg <br />Cost</Table.Th>
            <Table.Th ta="center" >Total Cost</Table.Th>
            <Table.Th ta="center" >Total <br />Market Value</Table.Th>
            <Table.Th ta="center" >UR G/(L)</Table.Th>
            <Table.Th ta="center" >% G/(L)</Table.Th>
            {positionType == 'foreign' && <>
              <Table.Th ta="center" >Total Cost <br />(Year Close)</Table.Th>
              <Table.Th ta="center" >Total <br />Market Value</Table.Th>
              <Table.Th ta="center" >UR <br />FX</Table.Th>
              <Table.Th ta="center" >UR <br />Equity</Table.Th>
              <Table.Th ta="center" >Total UR <br />G/(L)</Table.Th>
            </>}
            <Table.Th ta="center" >%<br />Portfolio</Table.Th>
          </Table.Tr>
          {positionType == 'foreign' && <>
            <Table.Tr>
              <Table.Th colSpan={4}></Table.Th>
              <Table.Th colSpan={5} ta="center">Foreign Currency</Table.Th>
              <Table.Th colSpan={5} ta="center">Local Currency</Table.Th>
              <Table.Th colSpan={1}></Table.Th>
            </Table.Tr>
          </>}
        </Table.Thead>
        <Table.Tbody>
          {
            getRows(positionType, positions)
          }
        </Table.Tbody>
      </Table>
    </Paper>
  }


  return (
    <Accordion.Item value={portfolio.code!} key={portfolio.code}>
      <Accordion.Control>

        <Group >
          <Title order={4}>{portfolio.name}'s Portfolio</Title>
          <Badge color={portfolio.active ? 'green' : 'red'}>{portfolio.active ? 'Active' : 'Inactive'}</Badge>
        </Group>

      </Accordion.Control>
      <Accordion.Panel>
        <SimpleGrid cols={3}>
          <CustomInfoCard
            title="Portfolio Details"
            color="blue"
            icon={<IconFolder size={16} />}
            list={getPortfolioDetailsList(portfolio)}
          />
          <CustomInfoCard
            title="Position Financials"
            color="yellow"
            icon={<IconCash size={16} />}
            list={getPositionFinancialsList(portfolio)}
          />
          <CustomInfoCard
            title="Performance Metrics and Income"
            color="green"
            icon={<IconChartLine size={16} />}
            list={getPerformanceMetricsList(portfolio)}
          />
        </SimpleGrid>
        {portfolio.position?.local_position &&
          <>
            <br />
            {getPositionsTable('local', portfolio.position.local_position)}
          </>
        }
        {portfolio.position?.foreign_position &&
          <>
            <br />
            {getPositionsTable('foreign', portfolio.position.foreign_position)}
          </>
        }
      </Accordion.Panel>
    </Accordion.Item>
  )
}

// Component to display holdings table
const HoldingsTable = ({ groupedHoldings, totalValue, fundByCode }: { groupedHoldings: { [key: string]: GWIHolding[] }, totalValue: number, fundByCode: { [key: string]: Fund } }) => (
  <Table striped withTableBorder withColumnBorders ta="center">
    <Table.Thead>
      <Table.Tr>
        <Table.Th ta="center">Fund Name</Table.Th>
        <Table.Th ta="center">Fund Type</Table.Th>
        <Table.Th ta="center">Units</Table.Th>
        <Table.Th ta="center">Value (KWD)</Table.Th>
        <Table.Th ta="center">%</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
      {Object.entries(groupedHoldings).map(([gl_code, holdings]) => (
        holdings.map((holding, index) => (
          <>
            <Table.Tr key={holding.gl_code + '-' + index}>
              {index === 0 && (
                <>
                  <Table.Td rowSpan={holdings.length > 1 ? holdings.length + 1 : 1}>

                    {fundByCode[holding.gl_code] && <Tooltip 
                    transitionProps={{ transition: 'scale', duration: 500 }}
                    label={<FundSummarizedData fund={fundByCode[holding.gl_code]} />}>
                      <span>{holding.gl_name}</span>
                    </Tooltip>}
                    {!fundByCode[holding.gl_code] &&
                      <span>{holding.gl_name}</span>}
                  </Table.Td>
                </>
              )}
              <Table.Td>{holding.code}</Table.Td>
              <Table.Td><NumberDisplay value={holding.shares} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={holding.total} withCommas /></Table.Td>
              <Table.Td><NumberDisplay value={(holding.total / totalValue) * 100} withCommas withPercent /></Table.Td>
            </Table.Tr>
            {holdings.length > 1 && index == holdings.length - 1 &&
              <Table.Tr key={holding.gl_code + '-' + index}>

                <Table.Td><Text size='sm' fw='bold'>Total</Text></Table.Td>
                <Table.Td><NumberDisplay value={holdings.reduce((sum, holding) => sum + holding.shares, 0)} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={holdings.reduce((sum, holding) => sum + holding.total, 0)} withCommas /></Table.Td>
                <Table.Td><NumberDisplay value={(holdings.reduce((sum, holding) => sum + holding.total, 0) / totalValue) * 100} withCommas withPercent /></Table.Td>
              </Table.Tr>
            }
          </>
        ))

      ))}
      <Table.Tr key="total">

        <Table.Td colSpan={3}><Text size='sm' fw='bold'>Total</Text></Table.Td>
        <Table.Td><NumberDisplay value={totalValue} withCommas /></Table.Td>
        <Table.Td><NumberDisplay value={100} withCommas withPercent /></Table.Td>
      </Table.Tr>
    </Table.Tbody>
  </Table>
);

// Function to calculate portfolio percentages
const calculatePortfolioPercentages = (items: Position[]): Position[] => {
  const totalMarketValue = items.reduce((acc, item) => acc + (item.total_market_value ?? 0), 0);
  return items.map((item) => ({
    ...item,
    portfolio_percent: totalMarketValue === 0 ? 0 : ((item.total_market_value ?? 0) / totalMarketValue) * 100
  }));
};

// Function to group positions by exchange and currency
const groupPositionsByExchangeAndCurrency = (positions: Position[]): GroupedPosition[] => {
  const groupedMap = new Map<string, GroupedPosition>();

  for (const position of positions) {
    const key = `${position.exchange}-${position.currency}`;

    if (!groupedMap.has(key)) {
      groupedMap.set(key, {
        exchange: position.exchange ?? "",
        currency: position.currency ?? "",
        title: `${position.exchange} (${position.currency})`,
        list: [position],
        combinedPosition: { ...position }
      });
    } else {
      const groupedPosition = groupedMap.get(key)!;
      groupedPosition.list.push(position);
      combinePositions(groupedPosition.combinedPosition, position);
    }
  }

  return Array.from(groupedMap.values());
};

// Function to combine positions for summary
const combinePositions = (combined: Position, position: Position) => {
  combined.total_cost = (combined.total_cost ?? 0) + (position.total_cost ?? 0);
  combined.total_market_value = (combined.total_market_value ?? 0) + (position.total_market_value ?? 0);
  combined.unrealized_gain_loss = (combined.unrealized_gain_loss ?? 0) + (position.unrealized_gain_loss ?? 0);
  combined.gain_loss_percent = (combined.gain_loss_percent ?? 0) + (position.gain_loss_percent ?? 0);
  combined.total_cost_year_close_local = (combined.total_cost_year_close_local ?? 0) + (position.total_cost_year_close_local ?? 0);
  combined.total_market_value_local = (combined.total_market_value_local ?? 0) + (position.total_market_value_local ?? 0);
  combined.unrealized_fx = (combined.unrealized_fx ?? 0) + (position.unrealized_fx ?? 0);
  combined.unrealized_equity = (combined.unrealized_equity ?? 0) + (position.unrealized_equity ?? 0);
  combined.total_unrealized_gain_loss_local = (combined.total_unrealized_gain_loss_local ?? 0) + (position.total_unrealized_gain_loss_local ?? 0);
  combined.portfolio_percent = (combined.portfolio_percent ?? 0) + (position.portfolio_percent ?? 0);
};

// Functions to get various details for portfolio info cards
const getPortfolioDetailsList = (portfolio: Portfolio) => [
  { label: "PF Name", value: portfolio.investmentPortfolio },
  { label: "PF Number", value: portfolio.code },
  { label: "Opening Date", value: portfolio.position?.created },
  { label: "Managed By", value: portfolio.position?.managed_by },
  { label: "PF Manager", value: portfolio.position?.manager },
  { label: "PF Type", value: portfolio.position?.type },
  { label: "Mng Fees %", value: `${portfolio.position?.fees} %` },
  { label: "PF Status", value: portfolio.position?.status },
  { label: "Investment Portfolio", value: portfolio.investmentPortfolio },
  { label: "Portfolio Type", value: portfolio.portfolioType }
];

const getPositionFinancialsList = (portfolio: Portfolio) => [
  { label: "Cash", value: <NumberDisplay value={portfolio.position?.cash} withCommas /> },
  { label: "Securities", value: <NumberDisplay value={portfolio.position?.securities} withCommas /> },
  { label: "Dividend Due", value: <NumberDisplay value={portfolio.position?.dividend_due} withCommas /> },
  { label: "Sukuk Profit Receivable", value: <NumberDisplay value={portfolio.position?.sukuk_profit_receivable} withCommas /> },
  { label: "Other Receivables", value: portfolio.position?.type },
  { label: "Other Liabilities", value: <NumberDisplay value={portfolio.position?.other_liabilities} withCommas withColor withBrackets /> },
  { label: "Total Capital Market Value", value: <NumberDisplay value={portfolio.position?.total_capital_market_value} withCommas /> },
  { label: "Cash %", value: <NumberDisplay value={portfolio.position?.cash_percent} withCommas withPercent /> }
];

const getPerformanceMetricsList = (portfolio: Portfolio) => [
  { label: "Realized G/L YTD", value: <NumberDisplay value={portfolio.position?.realized_gain_loss_ytd} withCommas /> },
  { label: "Cash Dividend YTD", value: <NumberDisplay value={portfolio.position?.cash_dividend_ytd} withCommas /> },
  { label: "Unrealized P/L", value: <NumberDisplay value={portfolio.position?.unrealized_profit_loss} withCommas withBrackets withColor /> },
  { label: "Other Income", value: <NumberDisplay value={portfolio.position?.other_income} withCommas /> },
  { label: "Management Fee", value: <NumberDisplay value={portfolio.position?.management_fee} withCommas withBrackets withColor /> },
  { label: "Other Expenses", value: <NumberDisplay value={portfolio.position?.other_expenses} withCommas withColor withBrackets /> },
  { label: "Net Income", value: <NumberDisplay value={portfolio.position?.net_income} withCommas withBrackets withColor /> },
  { label: "Performance", value: <NumberDisplay value={portfolio.position?.performance} withCommas withPercent withColor /> }
];



