import useLocalStorage from "use-local-storage";
import { MultiSelect } from "@mantine/core";
import { where } from "firebase/firestore";
import { useEffect } from "react";

const KYCStatusFilter = (props: any) => {
  const [kycStatusSelections, setKycStatusSelections] = useLocalStorage<
    string[]
  >("kycStatusSelections", props.customQueries?.kycStatusSelections || []);

  useEffect(() => {
    if (kycStatusSelections.length) {
      props.setCustomQueries({
        ...props.customQueries,
        [props.tableKey + "-searchQ"]: [
          where("tags.kycStatus.code", "in", kycStatusSelections),
        ],
      });
    } else {
      props.setCustomQueries({
        ...props.customQueries,
        [props.tableKey + "-searchQ"]: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatusSelections]);

  const handleKYCFilterChange = (value: string[]) => {
    setKycStatusSelections(value);
  };
  const kycStatuses = [
    { value: "valid", label: "Valid" },
    { value: "invalid", label: "Invalid KYC" },
    { value: "approval", label: "Waiting for approval" },
    { value: "exec_approval", label: "Waiting for executive approval" },
    { value: "review", label: "Waiting for review" },
    { value: "no_kyc", label: "No KYC" },
    { value: "client_update", label: "Needs client update" },
    { value: "waiting_for_kyc", label: "Waiting for KYC" },
  ];
  return (
    <MultiSelect
    maw={200}
      data={kycStatuses}
      label="Filter by KYC status"
      clearButtonProps={{
        "aria-label": "Clear selection"
      }}
      clearable
      maxDropdownHeight={300}
      //transitionProps={{ duration: 150, transition: 'pop-top-left', timingFunction: 'ease' }}

      value={kycStatusSelections}
      onChange={handleKYCFilterChange}
      placeholder="No filter"
    />
  );
};

export default KYCStatusFilter;
