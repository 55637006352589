import { Group, Paper, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import "./stats_grid_icons_styles.css"


interface StatsGridIconsProps {
  stat: { title: string; value: string; diff: number, count: number, fundType?: string, id?: string };
}

export function StatsGridIcons({ stat }: StatsGridIconsProps) {

  const navigate = useNavigate();
  return (
    <Paper withBorder p="md" radius="md" key={stat.title}


      onClick={() => stat.fundType ? navigate(`/funds/${stat.fundType}/${stat.id}`) : null}
    >
      <Group justify="space-between" wrap='wrap' style={{ width: "100%", height: "100%", }}>
        <div
          style={{
            whiteSpace: "initial",
            width: "100%",
            height: "100%"
          }}
        >
          <Text c="dimmed" tt="uppercase" fw={700} fz="xs" className={"label"}>
            {stat.title}
          </Text>
          <Text fw={700} fz="xl"

            lineClamp={7}


          >
            {stat.value}
          </Text>

          <Text c="dimmed" fz="sm" mt="md">
            <Text component="span" c={stat.diff > 0 ? 'teal' : 'red'} fw={700}>
              {stat.count} applications
            </Text>{' '}
            {/* {stat.diff > 0 ? 'increase' : 'decrease'} compared to last month */}
          </Text>
        </div>
      </Group>
    </Paper>
  );


}