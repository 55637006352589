
import { Button, Card, Grid, Notification, NotificationProps, Space, Text, } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore';
import { areEqualArrays } from '../../utils';
import { ConfigDataType } from '../../types';
import { ConfigContext, MyUserDataContext } from '../../contexts';
import UserGroupsSearch from '../../components/user_groups_search';
import { useFirestore } from '../../helpers/firebaseContext';






const ApproversSettings = () => {

    const configContext: ConfigDataType = useContext(ConfigContext);
    const appContext: any = useContext(MyUserDataContext);

    const db = useFirestore();

    const [executiveApprovers, setExecutiveApprovers] = useState<string[]>();
    const [normalApprovers, setNormalApprovers] = useState<string[]>();
    const [notificationData, setNotificationData] = useState<null | NotificationProps>();

    const handleChangeExecutiveApprovers = (values: string[]) => {

        setExecutiveApprovers(values.sort())

    }


    const handleChangeNormalApprovers = (values: string[]) => {

        setNormalApprovers(values.sort())

    }


    useEffect(() => {

        if (configContext?.approvers?.kyc_executive)
            setExecutiveApprovers(configContext?.approvers?.kyc_executive.sort())
        if (configContext?.approvers?.kyc_normal)
            setNormalApprovers(configContext?.approvers?.kyc_normal.sort())
    }, [configContext?.approvers])





    const updateApprovers = (key: string) => {

        setNotificationData(null)

        const configCollectionRef = doc(db, "config", "approvers",);
        let docData: any = {

            "updatedBy":
                appContext?.msal?.account?.username
            ,
        }
        if (key === "kyc_normal")
            docData[key] = normalApprovers;
        else
            docData[key] = executiveApprovers;


        updateDoc(configCollectionRef, {
            ...docData
        }).then(() => {
            setNotificationData({ color: "green", children: <>Approvers updated Successfully</> })
        }).catch(() => {
            setNotificationData({ color: "red", children: <>Approvers Failed to Update</> })

        });
    }

    const resetExecutiveApprovers = () => {

        setNotificationData(null)
        setExecutiveApprovers(configContext?.approvers?.kyc_executive)
    }

    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>
            <Grid
                justify='flex-start'
                ta='center'
            >
                <Grid.Col span={{ xs: 12 }}>
                    <Text fw={700} m={"xl"}>Approvers</Text>
                </Grid.Col>

                {notificationData && <Grid.Col span={{ xs: 12 }}>

                    <Notification {...notificationData} onClose={() => setNotificationData(null)} />
                </Grid.Col>}

                <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    <Grid.Col span={{ xs: 12 }}  >
                        <UserGroupsSearch values={executiveApprovers} label={"Executive Approvers"} onItemSubmit={handleChangeExecutiveApprovers} apiURl={"/groups/"} />
                    </Grid.Col>
                </Grid.Col>
                {!areEqualArrays(executiveApprovers, configContext?.approvers?.kyc_executive) && <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    {/* <Card shadow="sm" padding="lg" radius="md" withBorder> */}
                    <Grid justify='flex-start'>



                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                color={"green"}
                                variant='outline'
                                onClick={() => updateApprovers("kyc_executive")}
                            >
                                Submit Change
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                variant='outline'
                                c={"red"}
                                onClick={resetExecutiveApprovers}
                            >
                                Cancel
                            </Button>
                        </Grid.Col>
                    </Grid>

                    {/* </Card> */}

                </Grid.Col>}

            </Grid >
            <Space h="xl" />



            <Grid
                justify='flex-start'
                ta='center'
            >


                <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    <Grid.Col span={{ xs: 12 }}  >

                        <UserGroupsSearch values={normalApprovers} label={"Normal Approvers"} onItemSubmit={handleChangeNormalApprovers} apiURl={"/groups/"} />

                    </Grid.Col>
                </Grid.Col>
                {!areEqualArrays(normalApprovers, configContext?.approvers?.kyc_normal) && <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    {/* <Card shadow="sm" padding="lg" radius="md" withBorder> */}
                    <Grid justify='flex-start'>



                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                color={"green"}
                                variant='outline'

                                onClick={() => updateApprovers("kyc_normal")}
                            >
                                Submit Change
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                variant='outline'
                                c={"red"}
                                onClick={resetExecutiveApprovers}
                            >
                                Cancel
                            </Button>
                        </Grid.Col>
                    </Grid>

                    {/* </Card> */}

                </Grid.Col >}

            </Grid >

        </Card >
    )
}

export default ApproversSettings
