import { MultiSelect } from "@mantine/core";
import { DocType, FileTag } from "../types";
import { DocTypes } from "./file_card";


interface FileTagPickerProps {
    onFileTagSelect: (selectedTags: FileTag[]) => void;
    title?: string;
    initialValues:FileTag[];
    customDocTypes?: Record<string, DocType>,
  }
  
  const FileTagPicker: React.FC<FileTagPickerProps> = ({ onFileTagSelect, title, initialValues, customDocTypes }) => {  

    let docTypes: Record<string, DocType> = customDocTypes ?? DocTypes;

    let values = initialValues.map(v=>v.toString());
  
    return (
      <div>
        <MultiSelect
            label={title??""}
            placeholder="Select tags"
            value={values}
            onChange={(values) => {
                let list = values as FileTag[];
                console.log("Selected tags: ", list);
                onFileTagSelect(list);
            }}
            data={
                Object.entries(docTypes).map(([key, value]) => ({
                    value: key,
                    label: value.name,
                }))
            }
          />

      </div>
    );
  };
  
  export default FileTagPicker;
