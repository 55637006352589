import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Space, } from '@mantine/core';
import { collection, collectionGroup, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
// import { MRT_ColumnDef, MantineReactTable, } from 'mantine-react-table';
import { CSVLink } from "react-csv";

import DataTable from 'react-data-table-component';
import { useFirestore } from '../../helpers/firebaseContext';
const availableFunds: any = {
    "closeEnded": {
        collection: "offline_kycs",
        subcollection: "submissions"
    },
    "openEnded": {
        collection: "orders",
        subcollection: "submissions",
        isCollectionGroup: true
    },
}

const FundDetails = () => {
    const [fundData, setFundData] = useState<any[]>()
    const [tableColumns, setTableColumns] = useState<Set<any>>()
    const mainColumns = ["name_en", "civilID", "mobile", "amount", "units"]
    const { fundID, fundType } = useParams();
    const fund = availableFunds[fundType!]


    const db = useFirestore();


    useEffect(() => {


        if (fundID && fund) {
            const fundCollection = fund.isCollectionGroup ?
                collectionGroup(db, fund.collection)
                : collection(db, fund.collection, fundID, "submissions");

            return onSnapshot(fundCollection, (snapShot) => {

                let data: any = snapShot.docs?.map((doc) => {
                    var docData = doc.data();

                    Object.keys(docData).map((key: string) => {


                        if (!docData[key])
                            return docData[key] = "-"
                        if (Array.isArray(docData[key])) {
                            if (key === "tin" || key === "documentsToInclude") {
                                const value = docData[key].map((arrayEntry: any) => {

                                    return Object.keys(arrayEntry).reduce(
                                        (acc, innerKey) =>
                                            arrayEntry[innerKey] && arrayEntry[innerKey].length > 0
                                                ? acc + innerKey + ": " + arrayEntry[innerKey] + " - "
                                                : acc,
                                        ""
                                    )
                                })

                                return docData[key] = value?.toString()

                            }

                            return docData[key] = docData[key].toString()
                        }

                        if (typeof docData[key] === 'string' || docData[key] instanceof String) {

                            return docData[key]
                        }
                        if (Object.keys(docData[key]).length) {

                            const value = Object.keys(docData[key]).reduce(
                                (acc, innerKey) =>
                                    docData[key][innerKey] && docData[key][innerKey].length > 0
                                        ? acc + innerKey + ": " + docData[key][innerKey] + " - "
                                        : acc,
                                ""
                            )
                            return docData[key] = value
                        }
                        return docData[key] = `${String(docData[key])} `

                    })
                    docData["id"] = doc.id;

                    return docData;
                });


                setFundData(data);
            })
        }
        /*
        useFirestoreCollection(configCollection)
        const { status, data: config } = useFirestoreCollectionData(configCollection, {
          idField: 'id', // this field will be added to the object created from each document
        });
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const columnsArray: any[] = []
        if (fundData)
            fundData.forEach((data: any) =>

                columnsArray.push(...Object.keys(data))
            )
        setTableColumns((new Set(columnsArray)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fundData])



    const columns = useMemo(
        () =>
            tableColumns ? [...tableColumns]?.map((col: string) => {
                if (mainColumns.includes(col))
                    return {
                        // header: col,
                        // accessorKey: col,
                        // accessorFn: (row: any) => `${String(row[col])} `,
                        // enableHiding: true,
                        name: col,
                        selector: (row: any) => `${String(row[col])} `,




                    }
                else return {
                    // header: col,
                    // accessorKey: col,
                    // enableHiding: true,

                    name: col,
                    selector: (row: any) => col,

                }
            }) : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tableColumns],
    );


    return (
        <div>
            <Space />

            {
                tableColumns && <Grid justify='flex-end' m={"lg"}>

                    <Grid.Col span={{ lg: 1 }}>



                        {
                            fundData && <CSVLink
                                data={
                                    fundData
                                }
                                filename={`${String(new Date())}.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Export to CSV
                            </CSVLink>
                        }

                    </Grid.Col >
                </Grid >
            }
            {
                (fundData && tableColumns?.size) ?
                    <>

                        <DataTable
                            paginationPerPage={1}
                            dense={true}
                            columns={columns}
                            data={fundData}
                        // pagination
                        />

                        {/* <MantineReactTable
                            mantineTableHeadCellProps={{
                                align: "center"
                            }
                            }
                            mantineTableBodyCellProps={{
                                align: "center"
                            }}
                            columns={

                                columns
                            }
                            data={fundData}
                            initialState={{
                                columnVisibility: [...tableColumns].reduce((prev, curr) => {
                                    if (!mainColumns.includes(curr))
                                        prev[curr] = false
                                    else
                                        prev[curr] = true

                                    return prev;
                                }, {})
                            }}
                        /> */}

                    </>

                    :

                    <>
                        Invalid Fund Data

                    </>

            }

        </div >
    )
}

export default FundDetails