import { Button, Card, Divider, Grid, Group, Text, Table, Title, Switch, Loader } from '@mantine/core';
import { httpsCallable } from 'firebase/functions';

import { BaseResult } from '../../types';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';
import { useMemo, useState } from 'react';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import useFirestoreDoc from '../../helpers/useFirestoreDoc';
import { showNotification } from '@mantine/notifications';


type FirebaseGatewayStateField = {
    state: string;
    lastUpdated: Timestamp;
}

type FirebaseGatewayConfig = {
    sync: boolean;
}

const FirebaseGatewaySettings = () => {
    const functions = useFunctions();

    const [loading, setLoading] = useState(false)

    const db = useFirestore();

    
    const firebaseGatewayStateDoc = doc(db, "firebase_gateway", "state");
    const firebaseGatewayConfigDoc = doc(db, "firebase_gateway", "config");


    const {data: firebaseGatewayState, loading: firebaseGatewayStateLoading} = useFirestoreDoc<Record<string, FirebaseGatewayStateField>>(firebaseGatewayStateDoc)
    const sortedFirebaseGatewayState = useMemo<Record<string, FirebaseGatewayStateField>>(() => {
        if (!firebaseGatewayState) return {};
        return Object.entries(firebaseGatewayState)
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    }, [firebaseGatewayState]);
    const {data: firebaseGatewayConfig, loading: firebaseGatewayConfigLoading} = useFirestoreDoc<FirebaseGatewayConfig>(firebaseGatewayConfigDoc)

    const getTimeSinceLastUpdate = (lastUpdated: Timestamp) => {
        const now = new Date();
        const lastUpdateDate = lastUpdated.toDate();
        const diffInMs = now.getTime() - lastUpdateDate.getTime();
        const diffInMinutes = Math.round(diffInMs / 60000);
        const diffInHours = Math.round(diffInMinutes / 60);
        const diffInDays = Math.round(diffInHours / 24);

        if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
        if (diffInHours < 24) return `${diffInHours} hours ago`;
        return `${diffInDays} days ago`;
    }

    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>
            <Title order={2} mb="md" 
              style={{
                backgroundImage: 'linear-gradient(to right, #FF6B6B, #4ECDC4)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 700,
                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                letterSpacing: '1px',
                textTransform: 'uppercase'
              }}>
              Firebase Gateway State
            </Title>
            <Grid gutter="md" mb="xl">
                <Grid.Col span={6}>
                    <Card shadow="sm" p="md" radius="md" withBorder>
                        <Group p="apart" mb="xs">
                            <Text w={500}>Enable Syncing</Text>
                            <Switch
                                checked={firebaseGatewayConfig?.sync}
                                onChange={async (event) => {
                                    setLoading(true);
                                    const newState = event.currentTarget.checked ? 'enabled' : 'disabled';
                                    try {
                                        await updateDoc(firebaseGatewayConfigDoc, { sync: event.currentTarget.checked });
                                        showNotification({
                                            title: 'Sync state updated',
                                            message: `Syncing is now ${newState}`,
                                            color: 'green',
                                        });
                                    } catch (error) {
                                        showNotification({
                                            title: 'Error',
                                            message: `Failed to update sync state: ${error}`,
                                            color: 'red',
                                        });
                                    }
                                    setLoading(false);
                                }}
                                disabled={loading}
                            />
                        </Group>
                        <Text size="sm" c="dimmed">
                            Toggle this switch to enable or disable syncing with the Firebase Gateway.
                        </Text>
                    </Card>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Card shadow="sm" p="md" radius="md" withBorder>
                        <Group p="apart" mb="xs">
                            <Text w={500}>Force Sync</Text>
                            {firebaseGatewayState?.sync && firebaseGatewayState.sync?.state === "SYNCING" ? (
                                <Loader size="sm" />
                            ) : (
                                <Button
                                    onClick={async () => {
                                        setLoading(true);
                                        const forceSyncFunction = httpsCallable(functions, 'forceFirebaseGatewaySync');
                                        try {
                                            await forceSyncFunction();
                                            showNotification({
                                                title: 'Sync initiated',
                                                message: 'Force sync has been initiated',
                                                color: 'green',
                                            });
                                        } catch (error) {
                                            showNotification({
                                                title: 'Error',
                                                message: `Failed to initiate force sync: ${error}`,
                                                color: 'red',
                                            });
                                        }
                                        setLoading(false);
                                    }}
                                    loading={loading}
                                    disabled={firebaseGatewayState && firebaseGatewayState.sync?.state === "SYNCING"}
                                >
                                    Force Sync
                                </Button>
                            )}
                        </Group>
                        <Text size="sm" c="dimmed">
                            Click this button to force an immediate sync with the Firebase Gateway.
                        </Text>
                    </Card>
                </Grid.Col>
            </Grid>
            {firebaseGatewayStateLoading ? (
                <Text>Loading...</Text>
            ) : sortedFirebaseGatewayState ? (
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th style={{ width: '25%' }}>Service Name</Table.Th>
                            <Table.Th style={{ width: '25%' }}>State</Table.Th>
                            <Table.Th style={{ width: '25%' }}>Last Updated</Table.Th>
                            <Table.Th style={{ width: '25%' }}>Time Since Last Update</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {Object.entries(sortedFirebaseGatewayState).map(([serviceName, field]) => (
                            serviceName !== "sync" && (
                                <Table.Tr key={serviceName}>
                                    <Table.Td style={{ width: '25%' }}>{serviceName}</Table.Td>
                                    <Table.Td style={{ width: '25%' }}>
                                        <Text c={field.state === 'DONE' || field.state === 'IDLE' ? 'green' : field.state === 'STOPPED' ? 'red' : 'yellow'}>
                                            {field.state}
                                        </Text>
                                    </Table.Td>
                                    <Table.Td style={{ width: '25%' }}>{field.lastUpdated.toDate().toLocaleString()}</Table.Td>
                                    <Table.Td style={{ width: '25%' }}>{getTimeSinceLastUpdate(field.lastUpdated)}</Table.Td>
                                </Table.Tr>
                            )
                        ))}
                    </Table.Tbody>
                </Table>
            ) : (
                <Text>No data available</Text>
            )}
        </Card>
    )
}

export default FirebaseGatewaySettings