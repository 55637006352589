import React from "react";

import { SimpleGrid, } from "@mantine/core";
import { StatsGridIcons } from "./stats_grid_icons";


//create CustomerInfo component
export default function Dashboard(props: any) {

    return (
        <SimpleGrid cols={{ base: 3, md: 3, xs: 1 }}
        >
            {props.fundsData?.map((fund: any) => (

                <StatsGridIcons stat={{
                    title: fund.name,
                    value: fund.nav_per_unit?.toFixed(3),
                    diff: 0,
                    count: 0
                }} />
            ))}
        </SimpleGrid>
    );

}