

import { Badge, Button, Group, Space, } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, Configure, useSortBy } from 'react-instantsearch';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useContext } from 'react';
import { MyUserDataContext } from '../contexts';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';
import { TypesenseTransaction, } from '../types';

import { useNavigate } from 'react-router-dom';

//typesense

interface InitialState {
    query?: string;
    page?: number;
}
const TRANSACTION_TYPES = [{
    "label": "Cash In",
    "value": "cash_in",
    // type: "cash",
    // action: "Deposit"
},
{
    "label": "Journal Entry",
    "value": "journal_entry",
    // type: "cash",
    // action: "Deposit"
},
{
    "label": "Cash Out",
    "value": "cash_out",
    // type: "cash",
    // action: "Withdraw"
},
{
    "label": "Security In",
    "value": "security_in",
    // type: "security",
    // action: "ReceiveLong"
},
{
    "label": "Security Out",
    "value": "security_out",
    // type: "security",
    // action: "DeliverLong"
},
{
    "label": "FX + Transfer",
    "value": "fx",
    // type: "fx",
    // action: "SpotFX"  //not needed
},

]
const TableDisplay = ({ setSelectedRows, selectedRows, renderTags, ActionsComponent, indexName, ...props }: any) => {
    const { items } = useHits();
    const navigate = useNavigate();



    const { refine } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );


    const columns: TableColumn<TypesenseTransaction>[] = [
        {
            name: "type",
            selector: (row) => TRANSACTION_TYPES.filter((type) => {
                return type.value === row.type
            })[0]?.label,

            sortable: true,
        },
        {
            name: "amount",
            selector: (row) => row.amount!,
            sortable: true,
        },


        {
            name: "Portfolio",
            selector: (row) => row.portfolioID,
            sortable: true,
        },

        {
            name: "Currency",
            selector: (row) => row.currency!,
            sortable: true,
        },



        {
            name: "Custodian Account",
            wrap: true,
            selector: (row) => row.custodianAccount,
            sortable: true,
        },

        {
            name: "Date",
            selector: (row) => row.date
        },


        {
            name: "Status",
            selector: (row) => row.status,

            cell: (row) => {
                const status = row.status

                return <Badge color={status === "approve" ? "green" : status === "reject" ? "red" : status === "review" ? "yellow" : "blue"}>{status}</Badge>

            },

            // sortable: true,
        },
        {
            name: 'Details',
            selector: (row) => row.transactionID,
            cell: (row) =>
                <Button component="a" size={"compact-xs"} variant="subtle"
                    onClick={() => {
                        navigate(`/transactions/${row.transactionID}/`);
                    }}
                >
                    View Details
                </Button>

            // <Anchor href={`/${"transactions"}/${row.id}`} >View Details</Anchor>,
            // grow: true,
        },
        {
            name: "Action",
            selector: (row) => row.transactionID,

            cell: (row) => <ActionsComponent transaction={row} />
            ,
            sortable: false,
        },

    ];



    return <DataTable
        columns={columns}
        selectableRows
        sortServer
        onSort={(column, sortDirection) => {
            if (column.id) {
                let sortByValue = `${indexName}/sort/${column.id}`
                if (sortDirection === "asc") {
                    sortByValue += `:asc`
                }
                else {
                    sortByValue += `:desc`
                }

                refine(sortByValue)
            }
        }}
        data={items as unknown as TypesenseTransaction[]}
    />
}

interface TransactionsInstantSearchProps {
    indexName: string,

    renderTags?: boolean
    ActionsComponent?: (value: TypesenseTransaction) => JSX.Element


    topElements?: JSX.Element
    additionalChildren?: JSX.Element
}
const TransactionsInstantSearch = ({ indexName, renderTags, ActionsComponent, topElements, additionalChildren }: TransactionsInstantSearchProps) => {

    // const [transactionsStatusFilter, setTransactionsStatusFilter] = useState<string | null>("")
    const userData = useContext(MyUserDataContext);


    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],
        },
        additionalSearchParameters: {

            query_by: "customerID,status,type,portfolioID,custodianAccount"
        }

    })
    const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!;
    }

    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,
        setUiState,
    }) => {
        // Custom logic
        // setUiState(uiState);
        // console.log("setting ui state", uiState);
        setInitialUIStateForIndex(uiState[indexName]);
    };

    // console.log("session for ", indexName, initialUIStateForIndex);

    // if (!initialUIStateForIndex)  {
    //     return <div></div>;
    // }
    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}


        >




            <Configure

            // filters={transactionsStatusFilter ? `${transactionsStatusFilter}` : ""}

            />

            <Group justify="space-between" align='center' style={{ marginBottom: 20 }} >

                {topElements}


                <SearchBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        width: "80%",
                        // marginBottom: 20
                    }}
                    autoFocus
                    placeholder='Search...'
                />






                {additionalChildren}

            </Group>

            <TableDisplay


                ActionsComponent={ActionsComponent}

                indexName={indexName}
            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >

                <HitsPerPage

                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />

        </InstantSearch >



    )
}

export default TransactionsInstantSearch