
import { Button, Checkbox, Group, Space } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, useInstantSearch, Configure, useSortBy } from 'react-instantsearch';
import { TagBadge } from './tag_badge';
import TypesenseInstantSearchAdapter, { SearchClient } from "typesense-instantsearch-adapter";
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { MyUserDataContext } from '../contexts';
import DataTable from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';
import { KYCStatusCode, Tag } from '../types';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';


//typesense

interface InitialState {
    query?: string;
    page?: number;
}

// Define the interface for the ref
export interface RefreshButtonRef {
    refresh: () => void;
}


export const RefreshButton = forwardRef<RefreshButtonRef>((props, ref) => {
    const { refresh } = useInstantSearch();

    useImperativeHandle(ref, () => ({
        refresh
    }));

    return (
        <Button onClick={() => refresh()}>
            Refresh
        </Button>
    );
});

const TableDisplay = ({ indexName, setSelectedRows, selectedRows, renderTags, ActionsComponent, navigateDetails, ...props }: any) => {
    const { items } = useHits();

    const { refine } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );

    const handleChange = ({ selectedRows }: any) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        if (setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };

    const columns: any[] = [
        {
            name: 'Name',
            id: "name_en",
            sortable: true,
            selector: (row: any) => row.name_en,
            // grow: true,
        },
        {
            name: 'Mobile',
            id: "mobile",
            selector: (row: any) => row.mobile,
            sortable: true,
        },
        {
            name: 'Civil ID',
            id: "civilID",
            selector: (row: any) => row.civilID,
            sortable: true,
            // grow: true,
        },
        {
            name: 'Tags',
            selector: (row: any) => <>
                {Object.keys(row.tags).sort().map((tagKey, i) => {
                    let tag = row.tags[tagKey] as Tag;
                    // console.log(tagKey, i);
                    // tagsList.push(<Badge size="xs" variant="filled" c={tag.bgColor} key={tagKey} style={{ backgroundColor: tag.bgColor, color: tag.color }}>{tag.title}</Badge>);
                    return <TagBadge m={3} tag={tag} key={tagKey + " " + i} />
                })}
            </>,
            // grow: false,
            wrap: true,
        },

        {
            id: "lastUnsubmittedKYCMessage.count",
            name: "Actions",
            selector: (row: any) => <>

                {<ActionsComponent customerObj={row} />}
            </>,

            grow: true,
            sortable: true

        }



    ];


    return <DataTable
        sortServer
        onSort={(column, sortDirection) => {
            if (column.id) {
                let sortByValue = `${indexName}/sort/${column.id}`
                if (sortDirection === "asc") {
                    sortByValue += `:asc`
                }
                else {
                    sortByValue += `:desc`
                }

                refine(sortByValue)
            }
        }}
        columns={columns}

        selectableRows
        onSelectedRowsChange={handleChange}
        data={items.map((hitObj) => {
            return { ...hitObj, customerID: hitObj.id }
        })}
    />

}

interface CustomerInstantSearchProps {
    indexName: string,
    setSelectedRows?: any
    renderTags?: boolean
    ActionsComponent?: any
    navigateDetails?: any
    selectable?: boolean
    selectedRows?: any[]
    topElements?: JSX.Element
}
const CustomerInstantSearch = ({ indexName, selectable, setSelectedRows, selectedRows = [], navigateDetails, renderTags, ActionsComponent, topElements }: CustomerInstantSearchProps) => {
    const [orderStatusFilter, setCustomerKYCStatusFilter] = useState<string | null>("")

    const userData = useContext(MyUserDataContext);
    let customClaims = userData.firebaseTokenResult?.claims.customClaims as any;

    const handleRowsToShow = () => {


        const kycStatuses: string[] = []


        if (customClaims["cr"] === true) {
            kycStatuses.push(...[KYCStatusCode.review, KYCStatusCode.approval, KYCStatusCode.exec_approval])

        }


        if (kycStatuses.length) {
            if (kycStatuses.length === 1) {
                setCustomerKYCStatusFilter(`tags.kycStatus.code:${kycStatuses[0]}`)

            }
            else {

                setCustomerKYCStatusFilter(`tags.kycStatus.code:[${kycStatuses.join()}]`)
            }
        }
        else {
            setCustomerKYCStatusFilter(null)
        }

    }
    const typesenseInstantsearchAdapter: TypesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],
        },
        additionalSearchParameters: {

            query_by: "name_en,name_ar,mobile,civilID,tags"
        }

    })

    let typesenseSearchClient: SearchClient = typesenseInstantsearchAdapter.searchClient as SearchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION!;
    }


    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,
        setUiState,
    }) => {
        // Custom logic
        // setUiState(uiState);
        // console.log("setting ui state", uiState);
        setInitialUIStateForIndex(uiState[indexName]);
    };

    // console.log("session for ", indexName, initialUIStateForIndex);

    // if (!initialUIStateForIndex)  {
    //     return <div></div>;
    // }
    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}

        >
            <Configure

                filters={orderStatusFilter ? `${orderStatusFilter}` : ""}
            // query={"q=*&filter_by=lastUnsubmittedKYCMessage.count:>=4"}

            />

            <Group justify="space-between">

                {topElements}

                <SearchBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        width: "50%",
                    }}
                    autoFocus placeholder='Search...'


                />
                <Checkbox
                    label="Show all customers"
                    defaultChecked={true}
                    onChange={(event) => {
                        if (!event.currentTarget.checked) {
                            handleRowsToShow()
                        }
                        else {
                            setCustomerKYCStatusFilter(null)
                        }
                    }

                    }
                />
                <RefreshButton />

                {/* <Text>Index: {process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION}</Text> */}
            </Group>


            <TableDisplay
                indexName={indexName}

                selectedRows={selectedRows}
                setSelectedRows={selectable ? setSelectedRows : undefined}
                navigateDetails={navigateDetails}
                ActionsComponent={ActionsComponent}
                renderTags={renderTags}

            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >

                <HitsPerPage
                    // style={{
                    //     display: "flex",
                    //     justifyContent: "flex-end",
                    //     background: "red",
                    //     width: "20%",
                    // }}
                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />

        </InstantSearch >



    )
}

export default CustomerInstantSearch