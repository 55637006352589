import { Textarea } from '@mantine/core'
import React from 'react'

const CommentsSection = ({ value, setValue, disabled }: any) => {


    return (
        <>
            <Textarea
                disabled={disabled}
                placeholder="Your comments"
                label="Your comment"
                withAsterisk
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
            />
        </>
    )
}

export default CommentsSection