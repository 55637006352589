import { createContext } from "react";
import { ConfigDataType, UserDataType, AppConfigType, GlobalDataType } from "./types";

// create context
const MyUserDataContext = createContext<UserDataType>({});
const ConfigContext = createContext<ConfigDataType>({});
const AppConfigContext = createContext<AppConfigType>({});
const GlobalDataContext = createContext<GlobalDataType>({});



export { MyUserDataContext, ConfigContext, AppConfigContext, GlobalDataContext };