import React from "react";
import CustomDataTable from "../../components/custom_data_table";
import { Group, Space, Title } from "@mantine/core";
// import KYCStatusFilter from "../../components/KYCStatusFilter";
// import CustomTextSearchField from "../../components/custom_text_search_field";
import useLocalStorage from "use-local-storage";
import { limit, orderBy } from "firebase/firestore";



const AuditList = () => {
  const columns: any[] = [
    {
      name: "Date",
      selector: (row: any) => (new Date(row.data()?.CHANGEDATE?.seconds * 1000)).toLocaleDateString() + " " + (new Date(row.data()?.CHANGEDATE?.seconds * 1000)).toLocaleTimeString(),
      sortable: true,
      grow: false,
    },

    {
      name: "Object",
      selector: (row: any) => row.data()?.objectName,
      sortable: true,
    },

    //ID is part of OBJECTKEY like this:  AccountCalendar[200].  Only get the 200 part
    {
      name: "ID",
      selector: (row: any) => row.data()?.OBJECTKEY?.split("[")[1].split("]")[0],
      sortable: true,
      grow: true
    },

    {
      name: "Field",
      selector: (row: any) => row.data()?.MEMBERNAME,
      sortable: true,
    },

    {
      name: "From",
      selector: (row: any) => row.data()?.OLDVALUE?.length > 100 ? row.data()?.OLDVALUE.substring(0, 50) + "..." : row.data()?.OLDVALUE,
      sortable: true,
    },

    {
      name: "To",
      selector: (row: any) => row.data()?.NEWVALUE?.length > 100 ? row.data()?.NEWVALUE.substring(0, 50) + "..." : row.data()?.NEWVALUE,
      sortable: true,
    },
    {
      name: "User",
      selector: (row: any) => row.data()?.USER,
      sortable: true,
      grow: true,
    },
    {
      name: "IP",
      selector: (row: any) => row.data()?.IPADDRESS,
      sortable: true,
    },

  ];

  // const itemsColRef = collection(, 'customers')


  // const filteredItems = fakeUsers.filter(
  // 	item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  // );
  const TABLE_KEY = "geneva_audit_logs";

  const [storedSearchText, setStoredSearchText] = useLocalStorage<{
    [key: string]: string[] | string;
  }>("storedSearchText", { [TABLE_KEY + "-searchText"]: "" });

  const [customFilters, setCustomFilters] = React.useState<any>({
    [TABLE_KEY + "-searchText"]: storedSearchText[TABLE_KEY + "-searchText"],
  });
  console.log("customFilters ====>", customFilters);




  return (
    <>
      <Group justify="space-between">
        <Title>Audit Log</Title>
        {/* <Button
          leftSection={<BsPlusCircle />}
          onClick={() => {
            navigate("/add_order");
          }}
        >
          {" "}
          Add order{" "}
        </Button> */}
      </Group>
      <Space h="xl" />
      {/* <Card shadow="sm" p="lg" radius="md" withBorder> */}
      <CustomDataTable
        orderByAttribute={"id"}
        title="Audit Log"
        tableKey={TABLE_KEY}
        isCollectionGroup={true}

        columns={columns}
        customQueries={{ filters: [limit(8000), orderBy("CHANGEDATE", "desc")] }}
        // setCustomQueries={setCustomQueries}
        selectableRows
        selectableRowsVisibleOnly
        selectableRowsHighlight
        setCustomFilters={setCustomFilters}

        setStoredSearchText={setStoredSearchText}
        customFilters={{ [`${TABLE_KEY}-searchText`]: "-accessadmin" }}
        // customFilters={customFilters}

        pagination //Removed this because it's not really a large amount of users yet.  Will add it back in later
        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: "All",
        }}
        // onRowClicked={(row: any) => {
        //   console.log(row);
        //   // navigate('/orders/' + row.data()?.id, { state: row });
        //   navigate("/orders/" + row.data()?.id);
        // }}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />
      {/* </Card> */}
      {/* </Col>
            </Row> */}
    </>
  );
};

const Audit = () => {
  return (
    <main style={{ padding: "1rem 0" }}>
      <AuditList />
    </main>
  );
};

export default Audit;
