// FirebaseProvider.tsx
import React, { ReactNode } from 'react';
import { FirestoreContext, FunctionsContext, StorageContext } from './firebaseContext';
import { firestore, functions, storage } from './firebaseContext';

interface FirebaseProviderProps {
  children: ReactNode;
}

const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
  return (
    <FirestoreContext.Provider value={firestore}>
      <FunctionsContext.Provider value={functions}>
        <StorageContext.Provider value={storage}>
          {children}
        </StorageContext.Provider>
      </FunctionsContext.Provider>
    </FirestoreContext.Provider>
  );
};

export default FirebaseProvider;
