// firebaseContext.ts
import React, { createContext, useContext, ReactNode } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import { Auth, getAuth } from 'firebase/auth';
import getFirebaseConf from './firebaseConf';



// Initialize Firebase app and services
const app: FirebaseApp = initializeApp(getFirebaseConf());
const firestore: Firestore = getFirestore(app);
const functions: Functions = getFunctions(app);
const storage: FirebaseStorage = getStorage(app);
const auth: Auth = getAuth(app);

// Define context types
interface FirebaseContextType {
  firestore: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
  auth: Auth;
}

// Create context objects
const FirestoreContext = createContext<Firestore | undefined>(undefined);
const FunctionsContext = createContext<Functions | undefined>(undefined);
const StorageContext = createContext<FirebaseStorage | undefined>(undefined);
const authContext = createContext<Auth | undefined>(undefined);

// Custom hooks for accessing the contexts
const useFirestore = (): Firestore => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error('useFirestore must be used within a FirebaseProvider');
  }
  return context;
};

const useFunctions = (): Functions => {
  const context = useContext(FunctionsContext);
  if (!context) {
    throw new Error('useFunctions must be used within a FirebaseProvider');
  }
  return context;
};

const useStorage = (): FirebaseStorage => {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error('useStorage must be used within a FirebaseProvider');
  }
  return context;
};

const useAuth = (): Auth => {
  const context = useContext(authContext);
  if (!context) {
    throw new Error('useAuth must be used within a FirebaseProvider');
  }
  return context;
};

export { FirestoreContext, FunctionsContext, StorageContext, useFirestore, useFunctions, useStorage, useAuth, app, firestore, functions, storage, auth };
