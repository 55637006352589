import { getMetadata, getStorage, listAll, ref } from 'firebase/storage';
import { FileTag } from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (path: string) => {

    const storage = getStorage();
    const listRef = ref(storage, path);

    try {
        const files = await listAll(listRef);
        // Check metadata for each file in the folder
        for (const file of files.items) {
            const metadata = await getMetadata(file);
            var isFileTagged = false;
            for (const tag of Object.values(FileTag)) {
                if (metadata.customMetadata && metadata.customMetadata[tag] === 'true') {
                    isFileTagged = true;
                    break;
                }
            }
            if (!isFileTagged) {
                return false;
            }
        }

        return true;
    } catch (error) {
        console.error('Error checking kyc folder files metadata:', error);
        return false;
    }
};