import { FullMetadata, getDownloadURL, getMetadata, getStorage, listAll, ref } from 'firebase/storage';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (path: any, filters: any) => {

    const storage = getStorage();
    const listRef = ref(storage, path);
    const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';
    // const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === 'rejected';

    console.log("listRef =======>>", listRef);

    let linkData: any = []
    await listAll(listRef)
        .then(async (res) => {

            //BUG: I'm replacing pdf to png, when really I should only replace the extension and not the whole string.  hope this won't bite me.
            // console.log("Listing all", res.items, links);
            let items = res.items;
            console.log("items =======>>", items);

            let linkPromises: any = []
            let thumbPromises: any = []
            let metadataPromises: any = []

            try {

                linkPromises = items.map(item => getDownloadURL(item));
            }
            catch (e) {
                console.log("item download url  fail");

            }
            try {

                thumbPromises = items.map(item => getDownloadURL(ref(storage, `${path}thumbnails/${item.name.replace("pdf", "png")}`)));
            }
            catch (e) {
                console.log("item thumbnail download url  fail");

            }
            try {

                metadataPromises = items.map(item => getMetadata(item));
            }
            catch (e) {
                console.log("item metadata fail");

            }
            //TODO: How to add these to a single promise?
            let linksResult = await Promise.allSettled(linkPromises);
            let metadatasResult = await Promise.allSettled(metadataPromises);
            let thumbsResult = await Promise.allSettled(thumbPromises);

            linkData = linkPromises.map((link: any, index: any) => {
                console.log("We goin with " + index);
                let d = {} as any;

                if (isFulfilled(linksResult[index])) {
                    d["link"] = (linksResult[index] as PromiseFulfilledResult<string>).value;
                }
                else {
                    console.log("Couldn't load " + items[index].name);
                    return null;
                }


                if (isFulfilled(metadatasResult[index])) {
                    d["metadata"] = (metadatasResult[index] as PromiseFulfilledResult<FullMetadata>).value;
                }

                if (isFulfilled(thumbsResult[index])) {
                    d["thumb"] = (thumbsResult[index] as PromiseFulfilledResult<string>).value

                }
                let metadataNames: any = []
                if (d?.metadata?.customMetadata)
                    metadataNames = Object.entries(d?.metadata?.customMetadata).map((metaObj: any) => {
                        return metaObj[0];
                    })
                let returnObj: any = {
                    name: items[index].name,
                    link: d.link,
                    thumb: d.thumb,
                    ref: items[index],
                    metadata: d.metadata,
                    metadataName: metadataNames,
                    created: new Date(d.metadata?.timeCreated)

                    // else {
                    //     return null;
                    // }
                }

                try {
                    returnObj = {
                        ...returnObj,
                        thumbRef: ref(storage, `${path}thumbnails/${items[index].name.replace("pdf", "png")}`),

                    }
                }
                catch (er) {
                    console.log("ERRRRR ========>>>>", er);

                }
                return returnObj;
            });
            // console.log(linkData);
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
        });

    return linkData
}