import React, { useEffect, useState } from "react";

import { collection, getDocs, query, where } from "firebase/firestore";
import { SimpleGrid, Tabs } from "@mantine/core";
import Dashboard from "../components/dashboard";
import { StatsGridIcons } from "../components/stats_grid_icons";
import { useFirestore } from "../helpers/firebaseContext";
import useFirestoreCollection from "../helpers/useFirestoreCollection";

//create CustomerInfo component
export default function Home(props: any) {

  const db = useFirestore();

  // const { data: fundsData } = useFirestoreCollectionData(
  //   query(collection(db, "funds"), where("active", "==", true)),
  // );

  // const { data: offlineKycData } = useFirestoreCollectionData(
  //   query(collection(db, "offline_kycs")),
  //   { idField: "id" }
  // );

  const fundsDataQuery = query(collection(db, "funds"), where("active", "==", true));
  const offlineKycDataQuery = query(collection(db, "offline_kycs"));

  const { data: fundsData } = useFirestoreCollection(fundsDataQuery);
  const { data: offlineKycData } = useFirestoreCollection(offlineKycDataQuery, true);




  const [fundKYCs, setFundKYCs] = useState<any>({});


  useEffect(() => {

    if (!offlineKycData) {
      return;
    }

    const fetchSubmissions = async () => {
      let kycsData = {} as any;

      for (const offlineFund of offlineKycData) {
        offlineFund.name = offlineFund.name || offlineFund.id;
        const submissionsSnap = await getDocs(collection(db, "offline_kycs", offlineFund.id, "submissions"));

        let totalAmount = 0;
        submissionsSnap.forEach(doc => {
          totalAmount += parseInt(doc.data().amount.replaceAll(",", ""));
        });

        kycsData[offlineFund.id] = {
          ...offlineFund,
          totalSubmissions: submissionsSnap.size,
          totalAmount: totalAmount.toLocaleString(),
          fundType: "closeEnded"
        };
      }

      setFundKYCs(kycsData);
    };

    fetchSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offlineKycData, db]);




  // const navigate = useNavigate();

  // const tabValue = null;
  const [activeTab, setActiveTab] = React.useState<string | null>("dashboard");

  // console.log("tabValue", activeTab);
  return (
    <>
      {/* <Tabs defaultValue="kyc" value={tabValue} onChange={(value: any) => navigate(`/customers/${id}/${value}`)}> */}
      <Tabs onChange={setActiveTab} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab value="dashboard">Dashboard</Tabs.Tab>
          <Tabs.Tab value="fundraising">Fund raising performance</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="dashboard">
          <Dashboard fundsData={fundsData} />
        </Tabs.Panel>
        <Tabs.Panel value="fundraising">
          <SimpleGrid p={50} cols={5}
            spacing={{ sm: 1, }}
          >

            {Object.values(fundKYCs).map((fund: any) => {

              //get the fund submissions
              return <StatsGridIcons stat={{
                title: fund.name,
                id: fund.id,
                value: fund.totalAmount,
                diff: 0,
                count: fund.totalSubmissions,
                fundType: fund.fundType
              }} />
            })}


          </SimpleGrid>
        </Tabs.Panel>

      </Tabs>
    </>
  );
}



