
import React, { PropsWithChildren, useEffect, useState } from 'react';
import 'dayjs/locale/en-gb';
import { DocumentData, onSnapshot, DocumentReference, doc, QueryDocumentSnapshot, collection, query, orderBy, limit } from 'firebase/firestore';

import { Group, Text, Accordion, ThemeIcon, Card, Space, } from '@mantine/core';
import { Customer, DocType, } from '../types';
import { IconScript, } from '@tabler/icons-react';

import { CustomerKYC } from '../routes/customers/customer_kyc';

import { useFirestore, useFunctions } from '../helpers/firebaseContext';
import useFirestoreDoc from '../helpers/useFirestoreDoc';

// import useFirestoreDocData from '../helpers/useFirestoreDocData';



export interface ProductLayoutProps<T> extends PropsWithChildren {
    product?: T,
    customerRef: DocumentReference,
    productRef?: DocumentReference,
    productDoc?: QueryDocumentSnapshot<T>
    updatedProductData?: Partial<T>,
    defaultOpen?: string[],
    showGeneratePDF?: boolean
    showKycVersion?: boolean
    setParentKycData?: (value: DocumentData | null) => void
    setParentKycTags?: (value: DocumentData | null) => void
    setParentKycState?: (value: DocumentData | undefined) => void
    metaTypes: DocType[];
    setMetaTypes: React.Dispatch<React.SetStateAction<DocType[]>>;
  
}

function ProductLayout<T>({ customerRef, defaultOpen = [], showGeneratePDF, showKycVersion, setParentKycData, setParentKycTags, setParentKycState, children, metaTypes, setMetaTypes }: ProductLayoutProps<T>) {

    const db = useFirestore();

    //make pending state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    // const [metaTypes, setMetaTypes] = useState<DocType[]>([]);  //this is for CustomerKYC to set the metatypes of files that exist on the KYC.  That way, we can populate the Generate PDF form properly.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [kycData, setKycData] = React.useState<DocumentData | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [kycTags, setKycTags] = React.useState<DocumentData | null>(null);


    const functions = useFunctions();
    functions.region = "europe-west1";

    // const [rejectionReasonSelections, setRejectionReasonSelections] = React.useState<string[]>([]);





    const customerFullDataDoc = doc(db, "customers", customerRef?.id as string);

    const { loading: customerLoading, data: customerFullData } = useFirestoreDoc<Customer>(customerFullDataDoc);

    //Used onSnapshot instead of 'useFirestoreDoc' because the kycStateDoc is depending on kycData
    const [kycState, setKycState] = useState<DocumentData | undefined>(undefined);
    const [kycLoading, setKycLoading] = useState<boolean>(false);

    useEffect(() => {
        if (setParentKycData) {
            setParentKycData(kycData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycData])


    useEffect(() => {
        if (setParentKycTags) {
            setParentKycTags(kycTags)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycTags])
    useEffect(() => {
        if (setParentKycState) {
            setParentKycState(kycState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycState])

    useEffect(() => {
        const kycStateDoc = doc(db, `customers/${customerRef?.id}/kyc/${kycData?.id}/private`, "state");
        setKycLoading(true);

        const unsubscribe = onSnapshot(kycStateDoc, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("kycState Cached data", snapshot.data());
            } else {
                console.log("kycState Server data", snapshot.data());
            }

            const data = snapshot.data();

            setKycState(data);
            setKycLoading(false);
        });

        // Cleanup function to unsubscribe when the component unmounts or dependencies change
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycData]);

    useEffect(() => {
        const kycCollection = collection(customerRef as DocumentReference, "kyc");
        const kycQuery = query(kycCollection, orderBy("created", "desc"), limit(1));

        let kycUnsubscriber = onSnapshot(kycQuery,
            (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("Cached data", snapshot.docs.length);
                    // return;
                }
                else {
                    console.log("Server data", snapshot.docs.length);
                }

                if (!snapshot.empty) {
                    let data = snapshot.docs[0].data();
                    data.id = snapshot.docs[0].id;
                    setKycData(data);
                    // console.log("LE DATRA!", snapshot.docs[0].data())
                }

            },
            (error) => {
                console.log(error);
            })

        let customerUnsubscriber = onSnapshot(customerRef as DocumentReference,
            (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("Cached data", snapshot);
                    // return;
                }
                else {
                    console.log("Server data", snapshot);
                }

                if (snapshot) {
                    setKycTags(snapshot?.data()?.tags?.kycStatus);
                }

            },
            (error) => {
                console.log(error);
            })
        return () => {
            console.log("Cleaning up...")
            kycUnsubscriber();
            customerUnsubscriber();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    if (kycLoading || customerLoading || !customerFullData) {

        return <p>Fetching kyc status...</p>;
    }


    let kycCompleted: boolean = false;

    if (kycData?.created && kycState?.status_code === "valid") {
        //compare the day and make sure the kyc date is always the same or after the order date.  Ignore the time.
        let kycDate = kycData?.created?.toDate();


        kycDate.setHours(0, 0, 0, 0);


    }







    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder>







            <Accordion multiple transitionDuration={1000} defaultValue={['kyc', ...defaultOpen]}>
                <Accordion.Item key="kyc" value="kyc">
                    <Accordion.Control disabled={!(kycState && customerFullData)} >
                        <Group p="apart">
                            <ThemeIcon
                                variant="gradient"
                                size="xl"
                                aria-label="Gradient action icon"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                            >
                                <IconScript size={30} />
                            </ThemeIcon>
                            <Text
                                component="span"
                                ta="center"
                                variant="gradient"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                                size="xl"
                                fw={700}
                                style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                            >
                                KYC Information ({kycCompleted ? kycTags?.title : kycState?.status || "No KYC"})
                            </Text>
                        </Group>

                    </Accordion.Control>
                    <Accordion.Panel>

                        {/* below is the old disableUpload condition */}
                        {/* kycState?.status_code === "rejected" || orderStatusCode === "rejected" */}
                        <CustomerKYC
                            metaTypes={metaTypes}
                            setMetaTypes={setMetaTypes}
                            customerData={customerFullData!}
                            showButton={true}
                            showActiveLinks={false}
                            showKycVersion={!!showKycVersion}
                            showGeneratePDF={!!showGeneratePDF} />
                        {/* )} */}
                    </Accordion.Panel>
                </Accordion.Item>
                <Space h={"xl"} />
                {children}

            </Accordion>





        </Card>

    );

}
export default ProductLayout
