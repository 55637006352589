import { Card, Grid, TextInput, TextInputProps, Transition } from '@mantine/core';
import { useContext, useState } from 'react'
import { UploadBox } from '../components/upload_box';
import { DateInput, DateInputProps } from '@mantine/dates';
import { parseDayFirst } from '../helpers/common';
import { FileRejection, FileWithPath } from '@mantine/dropzone';
import { AppConfigContext } from '../contexts';
import { CustomFileWithPath, UploadFileResult } from '../types';

interface BankAccountInfoType {
    setIsError?: (value: boolean) => void
    handleUploadResponse?: (response: UploadFileResult, filesName: string[], setUploadedFilesFunction?: (values?: (any)[]) => void, file?: CustomFileWithPath[], currentFiles?: FileWithPath[]) => void
    ibanProps: TextInputProps
    transferDateProps: DateInputProps | { hide: boolean }
    setMsg?: (value: string) => void
    onRemoveFile?: (file: FileWithPath) => void
    clearFiles?: boolean
    onBlurIban: () => void
    acctNumberProps: TextInputProps
    onBlurAccountNumber: () => void
    orderID?: string
    hideUploadSlip?: boolean

}

const BankAccountInfo = ({ handleUploadResponse, setIsError, setMsg, onRemoveFile, clearFiles, onBlurIban, ibanProps, transferDateProps, acctNumberProps, onBlurAccountNumber, orderID, hideUploadSlip }: BankAccountInfoType) => {
    const [uploading, setUploading] = useState(false);
    const appConfigContext = useContext(AppConfigContext);

    return (
        <Grid columns={24} justify={hideUploadSlip ? 'start' : 'center'}>
            {!hideUploadSlip && <Grid.Col span={{ xs: 12, lg: 12 }}>

                <Transition
                    mounted={true}
                    transition="pop"
                    duration={200}
                    timingFunction="ease"
                >
                    {(styles) => (<Card withBorder>

                        <UploadBox
                            loading={uploading}
                            label="Drag slip here or click to select file"
                            multiple={true}
                            clearFiles={clearFiles}
                            onRemoveFile={(file: FileWithPath) => {
                                //find the list item with that filename
                                console.log("Removing file", file);
                                // console.log("slipFilePaths should be", form.values.slipFilePaths?.filter((path: string) => path.split("/").pop() !== file.name));
                                if (onRemoveFile) {

                                    onRemoveFile(file);
                                }

                            }}
                            onDropAny={async (files, filesRejected: FileRejection[], setUploadedFiles, uploadedFiles?: FileWithPath[]) => {
                                // form.setFieldValue("order.acct", "");
                                // form.setFieldValue("order.iban", "");

                                if (files?.length === 0) return;



                                console.log("Files dropped!", files, filesRejected, uploadedFiles);

                                setUploading(true);
                                const filesName: string[] = []
                                //upload file to fileUpload endpoint
                                const formData = new FormData();
                                files?.forEach((element: any) => {
                                    filesName.push(element?.name!)
                                    formData.append("file[]", element) // check this out for more info: https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
                                })

                                if (orderID) {
                                    formData.append("type", "order_slip");
                                    formData.append("orderID", orderID);
                                }
                                else {
                                    formData.append("type", "payment_slip");

                                }

                                const response = await (
                                    await fetch(`${appConfigContext.apiUrl}/uploadFile`, {
                                        method: "POST",
                                        body: formData,
                                    })
                                ).json() as UploadFileResult;


                                if (response.info) {
                                    if (handleUploadResponse) {
                                        setUploading(false);

                                        handleUploadResponse(response, filesName, setUploadedFiles, files, uploadedFiles)

                                    }
                                }

                                if (response.status === "error") {

                                    if (response.message) {
                                        if (setIsError)
                                            setIsError(true);
                                        console.log("No Microsoft Token");
                                        if (setMsg)
                                            setMsg(response.message);

                                    }
                                }

                                setUploading(false);
                            }}
                        />




                    </Card>)}
                </Transition>
            </Grid.Col>}
            <Grid.Col
                span={{

                    xs: 12
                }}
            >
                <TextInput
                    // withAsterisk
                    label="IBAN"
                    placeholder="Enter IBAN"

                    // disabled
                    {...ibanProps}
                    onBlur={onBlurIban
                    }
                />
                {!(transferDateProps as { hide: boolean })?.hide
                    && <DateInput

                        locale="en-gb"
                        dateParser={parseDayFirst}
                        label="Transfer date"
                        name="transferDate"
                        placeholder="Pick date"
                        valueFormat="DD/MM/YYYY"
                        {...transferDateProps}
                    />}

                <TextInput
                    // withAsterisk
                    label="Account Number (only for KFH clients)"
                    // disabled={true}
                    placeholder="Filled automatically"

                    {...acctNumberProps}
                    onBlur={
                        onBlurAccountNumber
                    }
                />

            </Grid.Col>

        </Grid >
    )
}

export default BankAccountInfo