
import { Button, Card, Grid, Group, Loader, NotificationProps, NumberInput, Stack, Text, } from '@mantine/core'
import { useContext, useState } from 'react'
import { Timestamp, collection, doc, query, updateDoc, where, deleteField } from 'firebase/firestore';
import { ConfigContext, MyUserDataContext } from '../../contexts';
import { Fund } from '../../types';
import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { parseDayFirst } from '../../helpers/common';
import { useFirestore } from '../../helpers/firebaseContext';
import useFirestoreCollection from '../../helpers/useFirestoreCollection';

const NavUpdateSettings = () => {

    const configContext = useContext(ConfigContext);
    const appContext = useContext(MyUserDataContext);

    const db = useFirestore();

    const fundCollection = collection(db, "funds");

    const fundsQuery = query(fundCollection, where("active", "==", true));

    const { loading: fundsLoading, data: fundsData } = useFirestoreCollection(fundsQuery, true);


    const [notificationData, setNotificationData] = useState<null | NotificationProps>();


    const updateNav = (fundID: string, nav_per_unit: number, nav_date: Timestamp) => {

        console.log("Is this on?");
        const id = notifications.show({
            loading: true,
            title: 'Saving new NAV for' + fundID + '...',
            message: 'Give it a minute...',
            autoClose: false,
            withCloseButton: false,
        });



        // setNotificationData(null)

        const fundRef = doc(db, "funds", fundID);
        updateDoc(fundRef, {
            "new_nav_awaiting_approval": {
                "nav_per_unit": nav_per_unit,
                "nav_date": nav_date,
                "updatedBy": appContext?.msal?.account?.username,
            }
        }).then(() => {
            notifications.update({
                id,
                color: 'teal',
                title: 'Saved new NAV for ' + fundID,
                message: 'Nice!  It is now ' + nav_per_unit + '',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        }).catch((e) => {
            console.log(e);
            notifications.update({
                id,
                color: 'red',
                title: 'Failed to save new NAV for ' + fundID,
                message: e.message,
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        });
    }


    const approveNav = (fundID: string, nav_per_unit: number, nav_date: Timestamp, updatedBy: string) => {

        console.log("Is this on?");
        const id = notifications.show({
            loading: true,
            title: 'Approving new NAV for' + fundID + '...',
            message: 'Give it a minute...',
            autoClose: false,
            withCloseButton: false,
        });






        const fundRef = doc(db, "funds", fundID);
        updateDoc(fundRef, {
            "new_nav_awaiting_approval": deleteField(),

            "nav_per_unit": nav_per_unit,
            "nav_date": nav_date,
            "approvedBy": appContext?.msal?.account?.username,
            "updatedBy": appContext?.msal?.account?.username,

        }).then(() => {
            // setNotificationData({ color: "green", children: <>Risk Countries updated Successfully</> })
            notifications.update({
                id,
                color: 'teal',
                title: 'Saved new NAV for ' + fundID,
                message: 'Nice!  It is now ' + nav_per_unit + '',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        }).catch((e) => {
            // setNotificationData({ color: "red", children: <>Risk Countries Failed to Update</> })
            console.log(e);
            notifications.update({
                id,
                color: 'red',
                title: 'Failed to save new NAV for ' + fundID,
                message: e.message,
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        });
    }



    if (fundsLoading
    ) {
        return <Loader />
    }

    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>
            {fundsData?.map((fundData) => {
                const fund = fundData as Fund;

                return <Grid
                    p={8}
                    justify='flex-start'
                    align='flex-end'
                    key={fund.id}
                // ta='center'
                >

                    {/* {notificationData && <Grid.Col span={{ xs: 12 }}>

                        <Notification {...notificationData} onClose={() => setNotificationData(null)} />
                    </Grid.Col>} */}
                    {/* <Grid.Col span={{ xs: 12 }}>
                        <Text fw={700} m={"xl"}>{fund.name_en}</Text>
                    </Grid.Col> */}
                    <Grid.Col span={{ lg: 2, xs: 12 }}  >
                        <NumberInput
                            label={`${fund.name_en} NAV per unit`}
                            // placeholder="NAV per unit"
                            value={fund.nav_per_unit}
                            onChange={(value) => {
                                fund.nav_per_unit = Number(value)
                            }}
                        />

                    </Grid.Col>
                    <Grid.Col span={{ lg: 2, xs: 12 }}  >
                        <DateInput
                            firstDayOfWeek={0}
                            locale="en-gb"
                            dateParser={parseDayFirst}
                            label="NAV Date"
                            name="navDate"
                            placeholder="Pick date"
                            valueFormat="DD/MM/YYYY"
                            onChange={(value) => {
                                if (value) {
                                    fund.nav_date = Timestamp.fromDate(value);
                                }
                            }}
                            getDayProps={(date) => {

                                if (date.getDay() !== fund.nav_update_day_of_week) {
                                    return { disabled: true };
                                }

                                return {};
                            }}

                        />

                    </Grid.Col>
                    <Grid.Col span={{ lg: 2, xs: 12 }}  >

                        <Button
                            c={"green"}
                            variant='outline'
                            onClick={() => {
                                updateNav(fund.id, Number(fund.nav_per_unit), fund.nav_date!);
                            }}
                        >
                            Submit Change
                        </Button>
                    </Grid.Col>
                    {fund.new_nav_awaiting_approval && <Grid.Col span={{ lg: 6, xs: 12 }}  >
                        <Group>
                            <Stack>
                                <Text size='xs'>New NAV by {fund.new_nav_awaiting_approval.updatedBy} </Text>
                                <Group justify='space-between'>
                                    <Text size='xs'>NAV: {fund.new_nav_awaiting_approval.nav_per_unit}</Text>
                                    <Text size='xs'>Date: {fund.new_nav_awaiting_approval.nav_date.toDate().toLocaleDateString("en-gb")}</Text>
                                </Group>
                            </Stack>
                            <Button
                                c={"orange"}
                                size='xs'
                                variant='outline'
                                disabled={process.env.REACT_APP_ENV !== "development" && fund.new_nav_awaiting_approval.updatedBy === appContext?.msal?.account?.username}
                                onClick={() => {
                                    approveNav(fund.id, Number(fund.new_nav_awaiting_approval!.nav_per_unit), fund.new_nav_awaiting_approval!.nav_date, fund.new_nav_awaiting_approval!.updatedBy);
                                }}
                            >
                                Approve change
                            </Button>
                        </Group>
                    </Grid.Col>
                    }

                </Grid >
            })}

            {/* <Space h="xl" /> */}

        </Card >
    )
}

export default NavUpdateSettings