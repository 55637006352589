const  getFirebaseConf=()=>{
    let firebaseConfig = {
        apiKey: "AIzaSyAyIZBEmpfIpG7VzztGLdL_1XnphB72rYw",
        authDomain: "kfh-capital-crm-test.firebaseapp.com",
        projectId: "kfh-capital-crm-test",
        storageBucket: "kfh-capital-crm-test.appspot.com",
        messagingSenderId: "17095975918",
        appId: "1:17095975918:web:2479c809b213aad758a4ff",
        measurementId: "G-S5H54FBK7P"
      };
      
      if (process.env.REACT_APP_ENV === 'production') {
        //yalhusayan@kfhcapital.com.kw production
        firebaseConfig = {
          apiKey: "AIzaSyBbljwec4j9D9mn3zS8kgVtJtsT-fm9coI",
          // authDomain: "kfh-capital-crm.firebaseapp.com",
          authDomain: "portal.kfhcapital.com.kw",
          projectId: "kfh-capital-crm",
          storageBucket: "kfh-capital-crm.appspot.com",
          messagingSenderId: "633630348973",
          appId: "1:633630348973:web:a3b1c062f514877345d824",
          measurementId: "G-R83SFVRWMZ"
        };
      }

      return firebaseConfig
}

export default getFirebaseConf;