import { getBytes } from "firebase/storage";
import getKycFiles from "./getKycFiles"
import * as _ from 'underscore';
import { encode } from "base64-arraybuffer";

export const getLatestFilesBinaries = async (pathKyc: string, pathOrders: string, documentsToInclude: any) => {

    const buffersArray = []
    const linkDataKYC = await getKycFiles(pathKyc, null) || []
    const linkDataOrders = await getKycFiles(pathOrders, null) || []
    let linkData = linkDataKYC.concat(...linkDataOrders)
    let filesList: any = []
    let includedMetas: any = []
    await linkData?.forEach((tempFilesArrObj: any) => {

        if (tempFilesArrObj?.metadata?.customMetadata) {


            //meta data that is set to true
            const activeMetas = Object.keys(tempFilesArrObj?.metadata?.customMetadata).filter(function (key) {
                return tempFilesArrObj?.metadata?.customMetadata[key] === "true"
            });

            filesList.push(tempFilesArrObj)
            includedMetas = [...new Set([...includedMetas, ...activeMetas])]

            console.log("Adding, ", activeMetas, " to the list", tempFilesArrObj);

            //shortcircuit since we want all files.
            return;

            if (!filesList.length || (activeMetas.includes("paymentSlip"))) {
                filesList.push(tempFilesArrObj)
                includedMetas = [...new Set([...includedMetas, "paymentSlip"])]
            }
            else {

                const metaExists = (_.intersection(includedMetas, activeMetas)) || []
                if (!metaExists.length) {
                    filesList.push(tempFilesArrObj)
                    includedMetas = [...new Set([...includedMetas, ...activeMetas])]
                }
                else filesList?.forEach((fileArrayObj: any, idx: any) => {
                    const metaExists2 = (_.intersection(metaExists, fileArrayObj?.metadataName))

                    if (
                        metaExists2.length &&
                        fileArrayObj?.created <= tempFilesArrObj?.created
                    ) {
                        //find the one with the duplicate meta
                        filesList[idx] = tempFilesArrObj
                    }
                })
            }
        }
    })
    let metaIncluded: any = []  //to avoid duplicates, but we're not using it anymore.

    let activeMetas: any = []


    // console.log("DUDE!", includedMetas, filesList);

    for (let j = 0; j < filesList.length; j++) {
        let include = false;
        const fileObj = filesList[j]
        const activeFilteredMetas = Object.keys(fileObj?.metadata?.customMetadata).filter(function (key) {
            if (key === "hasExpiryDate") return false;  //hasExpiryDate has no PDF
            return fileObj?.metadata?.customMetadata[key] === "true"
        });
        activeMetas.concat(activeFilteredMetas)
        await activeFilteredMetas?.forEach((
            metaKey: any
        ) => {

            if (
                documentsToInclude.some((doc: any) => doc.metaKey === metaKey) && !metaIncluded.includes(metaKey)) {
                // if (metaKey !== "paymentSlip") {  //commented out because we want to include all files
                //     metaIncluded.push(metaKey)
                // }
                include = true;

            }
        })

        let downloadedObj: any;
        try {
            if (include) {
                downloadedObj = await getBytes(fileObj.ref);

                let type = ""
                if (fileObj.name.toLowerCase().endsWith(".png")) {
                    type = "png"
                }

                if ((fileObj.name.toLowerCase().endsWith(".jpg") || fileObj.name.toLowerCase().endsWith(".jpeg"))) {
                    type = "jpg"
                }
                if ((fileObj.name.toLowerCase().endsWith(".pdf"))) {
                    type = "pdf"
                }
                if (downloadedObj) {
                    await buffersArray.push({ file: encode(downloadedObj), type: type, fileMetas: activeFilteredMetas })
                }

            }
        }
        catch (e) {
            console.log("Error fetching file =============>>>", e);
        }
    }


    return buffersArray

}