import { Button, Grid } from '@mantine/core';
import { httpsCallable } from 'firebase/functions';
import React from 'react'
import { GenerateRMUpdateLinkRequest, UpdateLink } from '../../types';
import { useFunctions } from '../../helpers/firebaseContext';


const CustomerKycLinks = ({ link, kycStatus, idx, userData }: any) => {
    const [confirming, setConfirming] = React.useState(false);
    const functions = useFunctions();
    functions.region = "europe-west1";
    const generateRMUpdateLink = httpsCallable(functions, "generateRMUpdateLink");

    let now = new Date();
    let difference = link.expires.toDate().getTime() - now.getTime();

    let days = Math.ceil(difference / (1000 * 3600 * 24));
    const kycURL = process.env.NODE_ENV !== 'production' ? "http://localhost:64665/" : (process.env.REACT_APP_ENV !== 'production') ? "https://kfh-capital-kyc-test.firebaseapp.com/" : "https://kyc.kfhcapital.com.kw/";


    return (
        <Grid style={{ marginBottom: 10 }}>
            <Grid.Col span={{ xs: 2 }}>
                Link No : {idx + 1}
                {/* <Anchor href={`${kycURL}/${link.id}`} target="_blank">{link.id}</Anchor> */}
            </Grid.Col>
            <Grid.Col span={{ xs: 2 }}>
                Type : {link.updateType}
            </Grid.Col>
            <Grid.Col span={{ xs: 2 }}>
                Currently on Step : {link?.filledData?.step || "-"}
            </Grid.Col>
            <Grid.Col span={{ xs: 2 }}>
                Expires in :  {
                    days + " days"
                }
            </Grid.Col>
            {


                < Grid.Col span={{ xs: 2 }}>
                    <Button
                        loading={confirming}
                        onClick={async () => {
                            try {
                                setConfirming(true)
                                let token = await userData.getAzureADToken!(userData);
                                const request: GenerateRMUpdateLinkRequest = {
                                    linkID: link.id,
                                    token: token,

                                }

                                const linkResult: any = await generateRMUpdateLink(request);

                                if (linkResult.data) {
                                    await window.open(`${linkResult.data}`, '_blank', 'noopener,noreferrer');
                                }
                            } catch (error) {
                                console.log("error=====>>", error);
                            }


                            setConfirming(false)
                        }}
                    >
                        Assist Client
                    </Button>
                </Grid.Col>
            }
            {(link as UpdateLink).manual &&

                < Grid.Col span={{ xs: 2 }}>
                    <Button
                        loading={confirming}
                        onClick={() => {
                            window.open(kycURL + (link as UpdateLink).id, "_blank");
                        }}
                    >
                        Open Manual KYC
                    </Button>
                </Grid.Col>
            }

        </Grid >
    )
}

export default CustomerKycLinks