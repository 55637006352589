import { Select, SelectProps } from '@mantine/core'
import React from 'react'


export interface SenderIDSelectProps extends Partial<SelectProps> {

}
const SenderIDSelect = (props: SenderIDSelectProps) => {
  return (
    <Select
      {...props}
      data={["KFHC-KYC", "KFHC-FUNDS", "KFHTrade"]}

    />
  )
}

export default SenderIDSelect