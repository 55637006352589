import React, { useState, useEffect } from "react";

import { DocumentReference, QueryDocumentSnapshot, doc } from "firebase/firestore";
import { onSnapshot, where, collectionGroup, query, collection } from "firebase/firestore";

import { useParams } from "react-router-dom";
import { Tabs } from "@mantine/core";

import { History } from "../../components/history";


import { Service } from "../../types";
import { useFirestore } from "../../helpers/firebaseContext";
import ServiceInfo from "./ServiceInfo";


//create CustomerInfo component
export default function ServicePage(props: any) {
  const { id } = useParams();

  const db = useFirestore();
  const [serviceData, setServiceData] = useState<Service>();
  const [serviceDocument, setServiceDocument] = useState<QueryDocumentSnapshot<Service>>();

  const [customerRef, setCustomerRef] = useState<DocumentReference>();
  const [serviceRef, setServiceRef] = useState<DocumentReference>();



  // const navigate = useNavigate();

  // const tabValue = null;
  const [activeTab, setActiveTab] = React.useState<string | null>("info");

  useEffect(() => {
    const serviceQuerySnapshot = query(collectionGroup(db, "services"), where('id', '==', id))
    console.log("serviceQuerySnapshot =====>>>", serviceQuerySnapshot);
    console.log("id VALUE =====>>>", id);


    return onSnapshot(
      serviceQuerySnapshot,
      (snapshot) => {
        console.log("SANPSHOTTT ====>>>", snapshot);

        if (!snapshot.empty) {
          const serviceRef = snapshot?.docs[0] as QueryDocumentSnapshot<Service>;
          setServiceDocument(serviceRef)
          const serviceData = serviceRef.data();
          setServiceRef(snapshot?.docs[0].ref);
          const fullPath = snapshot?.docs[0].ref.path; // get the full path of the service document
          const pathParts = fullPath.split('/');
          const customerId = pathParts[pathParts.length - 3]; // get the customerId from the path
          setServiceData(serviceData as Service)
          setCustomerRef(doc(db, 'customers', customerId))
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!serviceData || !customerRef || !serviceRef) {
    console.log("LOADING Service is", serviceData);
    console.log("LOADING customerRef is", customerRef);
    console.log("LOADING serviceRef is", serviceRef);
    return <div>Loading...</div>
  }

  console.log("Service is", serviceData);

  return (
    <>
      {/* <Tabs defaultValue="kyc" value={tabValue} onChange={(value: any) => navigate(`/customers/${id}/${value}`)}> */}
      <Tabs onChange={setActiveTab} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab
            value="info"
          // rightSection={<TagBadge tag={serviceData?.tags?.serviceStatus} />}
          >
            Info
          </Tabs.Tab>
          <Tabs.Tab value="history">History</Tabs.Tab>
        </Tabs.List>


        <Tabs.Panel value="info">
          <ServiceInfo serviceDoc={serviceDocument} service={serviceData} customerRef={customerRef} serviceRef={serviceRef} />
        </Tabs.Panel >


        <Tabs.Panel value="history">

          {serviceData && <History
            historyCollection={collection(
              serviceRef,
              "history"
            )}
          />}

        </Tabs.Panel>
      </Tabs >
    </>
  );
}
