import React, { useRef, useState } from "react";

import { collection, doc, query } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { Loader, Tabs } from "@mantine/core";

import { TagBadge } from "../../components/tag_badge";
import { History } from "../../components/history";
import { CustomerKYC } from "./customer_kyc";
import { CustomerInfo } from "./customer_info";
// import { Orders } from "./orders";
import { Transaction } from "./transactions";
import { Customer, CustomerFundData, DocType, Portfolio } from "../../types";
import { useFirestore } from "../../helpers/firebaseContext";
import useFirestoreDoc from "../../helpers/useFirestoreDoc";
import OrdersInstantSearch from "../../components/orders_instant_search";
import { RefreshButton, RefreshButtonRef } from "../../components/customer_instant_search";
import { RenderActions } from "../orders/orders";
import useFirestoreCollection from "../../helpers/useFirestoreCollection";
import PositionBadges from "../../components/position_badgers";
import { CustomerFundsTransactions } from "./customer_funds_transactions";



//create CustomerInfo component
export default function CustomerMain(props: any) {
  const { id } = useParams();

  const db = useFirestore();
  const navigate = useNavigate();

  const refreshRef = useRef<RefreshButtonRef | null>(null);



  const [activeTab, setActiveTab] = React.useState<string | null>("kyc");

  const [metaTypes, setMetaTypes] = useState<DocType[]>([]);


  const customerDataDoc = doc(db, "customers", id as string);
  const { loading: customerLoading, data: customerData } = useFirestoreDoc<Customer>(customerDataDoc, true);

  const portfolioDataQuery = query(collection(db, "customers", id as string, "portfolios"));
  const { loading: portfoliosLoading, data: portfoliosData } = useFirestoreCollection(portfolioDataQuery);

  const fundDataQuery = query(collection(db, "customers", id as string, "fund_data"));
  const { loading: fundDataLoading, data: fundData } = useFirestoreCollection(fundDataQuery);


  if (customerLoading || !customerData || portfoliosLoading || fundDataLoading) {
    return <Loader />
  }

  const castedPortfolioData = (portfoliosData as Portfolio[]);


  return (
    <>
      {/* <Tabs defaultValue="kyc" value={tabValue} onChange={(value: any) => navigate(`/customers/${id}/${value}`)}> */}
      <Tabs onChange={setActiveTab} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab value="positions"
            rightSection={<PositionBadges customerData={customerData} portfolioData={castedPortfolioData} />}
          >Positions</Tabs.Tab>
          <Tabs.Tab value="fundsTransactions"
          >Fund Transactions</Tabs.Tab>
          <Tabs.Tab
            value="kyc"
            rightSection={<TagBadge tag={customerData?.tags?.kycStatus} />}
          >
            KYC
          </Tabs.Tab>
          <Tabs.Tab value="history">History</Tabs.Tab>
          <Tabs.Tab value="orders">Orders</Tabs.Tab>
          {/* <Tabs.Tab value="transactions">Transactions</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value="positions">
          <CustomerInfo customerData={customerData!} portfoliosData={castedPortfolioData} />
        </Tabs.Panel>
        <Tabs.Panel value="fundsTransactions">
          <CustomerFundsTransactions fundData={fundData != undefined ? (fundData as CustomerFundData[]) : []} />
        </Tabs.Panel>
        <Tabs.Panel value="kyc">
          <CustomerKYC metaTypes={metaTypes} setMetaTypes={setMetaTypes} customerData={customerData!} showButton={true} showKycVersion={true} showActiveLinks={true} showGeneratePDF={true} />
        </Tabs.Panel>
        <Tabs.Panel value="orders">
          <>

            <OrdersInstantSearch

              selectable={false}

              renderTags={true}
              navigateDetails={(obj: any) =>
                navigate("/orders/" + obj.id)
              }
              allowShowAll={false}
              defaultFilter={`customerObj.id:=${id}`}
              ActionsComponent={RenderActions}
              indexName={process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!}

              additionalChildren={<RefreshButton ref={refreshRef} />}



            />
          </>
          {/* <CustomerOrdersInstantSearch
            indexName={process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!}

          /> */}
          {/* <Orders
            ordersCollection={collection(
              db,
              "customers",
              id as string,
              "orders"
            )}
          /> */}
        </Tabs.Panel>
        {/* <Tabs.Panel value="transactions">
          <Transaction
            transactionsCollection={collection(
              db,
              "customers",
              id as string,
              "transactions"
            )}
          />
        </Tabs.Panel> */}
        <Tabs.Panel value="history">
          <History
            historyCollection={collection(
              db,
              "customers",
              id as string,
              "history"
            )}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
