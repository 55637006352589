import { Address, AdressKeys } from "../types";

export const englishOnlyRegX = RegExp("[^\\\u0000-\\\u007F]+");

export const isNumericRegX = RegExp('^[0-9]$');

export const getAddressDetailsEnglish = (values: Address): string => {


    const firstKeyValue: AdressKeys = Object.entries(values)[0][0] as AdressKeys

    let isArabicText: boolean = englishOnlyRegX.test(values[firstKeyValue]!) || englishOnlyRegX.test(values["StreetName"]!) || false;

    const addressDetailsEnglish: string = Object.entries(values)?.map((entry: any) => {

        let key = entry[0]
        let value = entry[1]
        if (!value) {
            return "";
        }
        let isNumericValue = isNumericRegX.test(value) || false;

        if (!isNumericValue) {
            isArabicText = englishOnlyRegX.test(key) || isArabicText;
        }


        if (isArabicText) {
            // if (isNumericValue)
            // value = englishToArabicNumbers(value).split("").reverse().join()
            return (`${addressKeyTranslation["ar"]![key]}: ${value}`);
        } else {
            return (`${addressKeyTranslation["en"]![key]}: ${value}`);
        }


    }).join(" , ")
    return addressDetailsEnglish

}


export const addressKeyTranslation: any = {
    "en": {
        "Area": "Area",
        "BlockNumber": "Block Number",
        "BuildingNumber": "Building Number",
        "FloorNumber": "Floor Number",
        "Governerate": "Governerate",
        "PaciBuildingNumber": "PaciBuildingNumber",
        "StreetName": "Street Name",
        "UnitNumber": "",
        "UnitType": "",
    },
    "ar": {
        "Area": "المنطقة",
        "BlockNumber": "القطعة",
        "BuildingNumber": "رقم المبنى",
        "FloorNumber": "الطابق",
        "Governerate": "المحافظة",
        "PaciBuildingNumber": "رفم المبنى الإلكتروني",
        "StreetName": "اسم الشارع",
        "UnitNumber": "رقم الوحدة",
        "UnitType": "نوع الوحدة",
    }
};