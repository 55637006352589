import { useState, useEffect } from 'react';
import { onSnapshot, DocumentData, DocumentReference, collection, doc } from 'firebase/firestore';
import { Approval } from '../types';

type ApprovalsResult = {
    actions: Approval[];
    actionsAfterRejections: Approval[];
    loading: boolean;
};

function useApprovals<T>(docRef: DocumentReference<DocumentData>): ApprovalsResult {
    const [loading, setLoading] = useState<boolean>(false);

    const [actions, setActions] = useState<Approval[]>([]);
    const [actionsAfterRejections, setActionsAfterRejections] = useState<Approval[]>([]);


    useEffect(() => {
        setLoading(true);

        const privateDataRef = doc(collection(
            docRef,
            "private"
        ), "actions");;

        return onSnapshot(privateDataRef, (snapshot) => {
            //convert it to a dictionary and set it to the state
            if (!snapshot.exists()) {
                console.log("No actions document for", docRef.path);
                return;
            }

            let approvals = snapshot.data()?.approvals as Approval[];

            if (!approvals) {
                console.log("No approvals for", docRef.path);
                return;
            }

            setActions(approvals);


            if (approvals.length > 0) {

                let approvalsAfterRejection = [...approvals];

                for (let i = approvalsAfterRejection.length - 1; i >= 0; i--) {

                    if (approvalsAfterRejection[i].type === "needs_update") {
                        approvalsAfterRejection = approvalsAfterRejection.slice(i + 1);
                        break;
                    }
                }
                console.log("filled out actions!");
                setActionsAfterRejections(approvalsAfterRejection);
            }

            setLoading(false);
        })
    }, [docRef]);

    return { actions, actionsAfterRejections, loading };
}



export default useApprovals;
