import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Group, Space, Title, Menu, UnstyledButton, useMantineTheme, Select } from "@mantine/core";
import { IconDots, IconCashBanknote, IconCertificate2, IconShoppingCartPlus, IconCertificate, IconDeviceMobileMessage } from '@tabler/icons-react';

import { BsPlusCircle } from "react-icons/bs";

import { Button } from "@mantine/core";

import { TagBadge } from "../../components/tag_badge";

import CustomDataTable from "../../components/custom_data_table";
import SendSMSModal from "../../components/send_sms_modal";
import KYCStatusFilter from "../../components/kyc_status_filter";
import CustomTextSearchField from "../../components/custom_text_search_field";
import useLocalStorage from "use-local-storage";
import CustomModal from "../../components/custom_modal";
import RequestKYCUpdate from "../requestKYCUpdate";
import GeneratePDFModal from "../../components/generate_pdf_modal";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { Customer } from "../../types";
import { useDebouncedState } from "@mantine/hooks";
const TABLE_KEY = "customers";

const CustomSearchFields = (props: any) => {
    const { selectedRows } = props;


    return (
        <>
            <Group>
                <Button
                    color="grape"
                    disabled={selectedRows.length === 0}
                    onClick={() => props.setSMSOpened(true)}
                >
                    Send SMS to {selectedRows.length} customers
                </Button>

                {/* <Button c="orange" loading={approvingAll} onClick={() => {setApprovingAll(true); console.log("ey");}}>Approve all</Button> */}
                <Button
                    color="yellow"
                    disabled={selectedRows.length === 0}
                    onClick={() => props.setGeneratePDFOpen(true)}
                >
                    {`Generate KYC for ${selectedRows.length} customers`}
                </Button>
            </Group>
            <Group>
                <Select
                    label="Search By"
                    maw={150}
                    defaultValue={props.customFiltersAttribute[TABLE_KEY + "-searchTextAttr"]}
                    onChange={(value) => props?.setCustomFiltersAttribute({ [TABLE_KEY + "-searchTextAttr"]: value })}
                    data={[
                        { value: "civilID", label: "Civil ID" },
                        { value: "name_en", label: "Name (English)" },
                        { value: "name_ar", label: "Name (Arabic)" },
                        { value: "mobile", label: "Mobile" },

                    ]}



                />
                <CustomTextSearchField {...props} />
                <KYCStatusFilter {...props} />
            </Group>
        </>
    );
};

const CustomersFirebaseListSearch = () => {
    const navigate = useNavigate();
    const [SMSOpened, setSMSOpened] = useState(false);
    const [generatePDFOpen, setGeneratePDFOpen] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [storedSearchText, setStoredSearchText] = useLocalStorage<{
        [key: string]: string[] | string;
    }>("storedSearchText", { [TABLE_KEY + "-searchText"]: "" });

    const [customFilters, setCustomFilters] = useDebouncedState({
        [TABLE_KEY + "-searchText"]: storedSearchText[TABLE_KEY + "-searchText"],
    }, 800);

    const [customFiltersAttribute, setCustomFiltersAttribute] = useState<{ [x: string]: string; }>({
        [TABLE_KEY + "-searchTextAttr"]: (storedSearchText[TABLE_KEY + "-searchTextAttr"] as string) || "civilID",
    });

    const [customQueries, setCustomQueries] = useState({
        [TABLE_KEY + "-searchQ"]: [],
    });
    const handleSelectRows = (rows: any) => {
        setSelectedRows(rows);
        // setReloadData(Math.random());
    };
    const theme = useMantineTheme();

    const columns: any[] = [
        {
            name: "Name",
            selector: (row: QueryDocumentSnapshot<Customer>) => row.data().name_en,
            sortable: true,
        },
        {
            name: "Mobile",
            selector: (row: QueryDocumentSnapshot<Customer>) => row.data().mobile,
            sortable: true,
            grow: true,
        },
        {
            name: "Civil ID",
            selector: (row: QueryDocumentSnapshot<Customer>) => row.data().civilID,
            sortable: true,
            grow: 0.5,
        },
        {
            name: "Last Operation",
            selector: (row: QueryDocumentSnapshot<Customer>) =>
                row.data().lastOperation ? row.data()?.lastOperation?.msg : "",
            sortable: true,
        },
        /*
         
          {
            name: "Last Date",
            selector: (row: { lastOperation: any }) => {
              return row.lastOperation
                ? row.lastOperation.date.toDate().toLocaleString()
                : "";
            },
            sortable: true,
            sortFunction: (rowA: any, rowB: any) => {
              if (!rowA.lastOperation) return -1;
              if (!rowB.lastOperation) return -1;
              const a = rowA.lastOperation.date.toDate();
              const b = rowB.lastOperation.date.toDate();
              return b - a;
            },
          },
        */
        /*
          {
              name: 'KYC Expires',
              selector: (row: any) => {
                  var now = new Date();
                  var difference = row.kycExpiry.getTime() - now.getTime();
                  var days = Math.ceil(difference / (1000 * 3600 * 24));
         
                  return days + " days";
              },
              sortable: true,
         
          },
          */
        /*
          Keep in mind these
          ActionItem
          SimpleGrid (for photos)
          AppShell (for sidebar and topbar)
          Tabs
          */

        {
            name: "Tags",
            wrap: true,
            selector: (row: QueryDocumentSnapshot<Customer>) => {
                if (!row.data().tags) return;

                //loop through tags and create a badge for each
                let tagsList = [];
                if (row.data().tags) {
                    for (let tagKey of Object.keys(row.data().tags!).sort()) {
                        let tag = row.data().tags![tagKey];
                        // tagsList.push(<Badge size="xs" variant="filled" c={tag.bgColor} key={tagKey} style={{ backgroundColor: tag.bgColor, color: tag.color }}>{tag.title}</Badge>);
                        tagsList.push(<TagBadge tag={tag} key={tagKey} />);
                        tagsList.push(" ");
                    }
                }

                return <>{tagsList}</>;
                /*
                      if (row.risk === "high") {
                          return (<Badge bg="danger">High risk</Badge>)
                      }
                      return (<Badge bg="success">Low risk</Badge>)
                      */
            },
        },

        /*
        {
          name: "Positions",
          // sortable: true,
        
          selector: (row: any) => {
            if (!row.holdings) return;
        
            // let total = 0;
        
            let holdingsList = [];
        
            for (let holdingsKey of Object.keys(row.holdings).sort()) {
              let holding = row.holdings[holdingsKey];
              holdingsList.push(<Group><Text fz="xs">{holding.fund}:</Text> <Text fz="xs" fw={500}>{Number(holding.total).toFixed(0)} </Text></Group>);
            }
            // return 
            return <Stack>{holdingsList}</Stack>
        
          },
        },
        */
        {
            name: 'Details',
            selector: (row: QueryDocumentSnapshot<Customer>) => <Button component="a" size={"compact-xs"} variant="subtle"
                onClick={() => {
                    navigate("/customers/" + row.id, { state: { ...row.data(), id: row.id } });
                }}
            >
                View Details
            </Button>,

            // <Anchor href={`/${"transactions"}/${row.id}`} >View Details</Anchor>,
            grow: 1,
        },

        {
            name: "Action",
            selector: (row: QueryDocumentSnapshot<Customer>) =>
                <Menu
                    position="bottom-start"
                    withArrow
                    arrowPosition="center"
                >
                    <Menu.Target>
                        <UnstyledButton pr={12}> <IconDots />  </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>

                        <Menu.Item
                            leftSection={<IconDeviceMobileMessage size={16} color={theme.colors.blue[6]} stroke={1.5} />}
                            onClick={async () => {
                                return;
                            }
                            }
                        >
                            SMS
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconCashBanknote size={16} color={theme.colors.blue[6]} stroke={1.5} />}
                            onClick={() => {
                                navigate(`/transactions/add/`, { state: { type: "cash_in", customer: row.id } });
                            }}
                        >

                            Cash in
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconCashBanknote size={16} color={theme.colors.blue[6]} stroke={1.5} />}
                            onClick={() => {
                                navigate(`/transactions/add/`, { state: { type: "cash_out", customer: { ...row.data(), "id": row.id } } });
                            }}
                        >
                            Cash out
                        </Menu.Item>
                        <Menu.Item
                            leftSection={<IconCertificate size={16} color={theme.colors.blue[6]} stroke={1.5} />}
                            onClick={() => {
                                navigate(`/transactions/add/`, { state: { type: "security_in", customer: row.id } });
                            }}
                        >
                            Security In
                        </Menu.Item>
                        <Menu.Item
                            leftSection={
                                <IconCertificate2 size={16} color={theme.colors.blue[6]} stroke={1.5} />
                            }
                            onClick={() => {
                                navigate(`/transactions/add/`, { state: { type: "security_out", customer: row.id } });
                            }}
                        >
                            Security Out
                        </Menu.Item>
                        <Menu.Item
                            leftSection={
                                <IconCertificate2 size={16} color={theme.colors.blue[6]} stroke={1.5} />
                            }
                            onClick={() => {
                                navigate(`/transactions/add/`, { state: { type: "fx", customer: row.id } });
                            }}
                        >
                            FX + Transfer
                        </Menu.Item>
                        <Menu.Item
                            leftSection={
                                <IconShoppingCartPlus size={16} color={theme.colors.blue[6]} stroke={1.5} />
                            }

                            onClick={() => {
                                navigate(`/add_order/${row.data().civilID}`);
                            }}
                        >
                            Add Order
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            ,
            sortable: false,
            grow: true
        },

    ];

    return (
        <>


            <SendSMSModal
                rows={selectedRows}
                opened={SMSOpened}
                setOpened={setSMSOpened}
            />
            <GeneratePDFModal
                rows={selectedRows}
                opened={generatePDFOpen}
                setOpened={setGeneratePDFOpen}
            />
            <CustomModal
                title={<Title>Add Client</Title>}
                opened={addClientModal}
                // setOpened={setAddClientModal`}
                onClose={() => setAddClientModal(false)}
                // overlayOpacity={0.22}
                // overlayBlur={3}
                centered
            >
                <RequestKYCUpdate setModal={setAddClientModal} />
            </CustomModal>
            <Group justify="space-between">
                <Title>Clients</Title>
                <Button
                    leftSection={<BsPlusCircle />}
                    onClick={() => setAddClientModal(true)}
                >
                    Add client
                </Button>
            </Group>
            <Space h="xl" />
            {/* <Card shadow="sm" p="lg" radius="md" withBorder> */}
            <CustomDataTable
                orderByAttribute="civilID"
                tableKey={TABLE_KEY}
                title="Customer List"
                columns={columns}
                // onRowClicked={(row: any) => {
                //   console.log(row);
                //   navigate("/customers/" + row.id, { state: row });
                // }}
                CustomSearchFields={
                    <CustomSearchFields
                        customFiltersAttribute={customFiltersAttribute}
                        setCustomFiltersAttribute={setCustomFiltersAttribute}
                        customFilters={customFilters}
                        setCustomFilters={setCustomFilters}
                        selectedRows={selectedRows}
                        storedSearchText={storedSearchText}
                        setStoredSearchText={setStoredSearchText}
                        setSMSOpened={setSMSOpened}
                        setGeneratePDFOpen={setGeneratePDFOpen}
                        tableKey={TABLE_KEY}
                        customQueries={customQueries}
                        setCustomQueries={setCustomQueries}
                    />
                }

                filterAttributes={customFiltersAttribute[TABLE_KEY + "-searchTextAttr"]}
                customFilters={customFilters}

                customQueries={customQueries}

                paginationComponentOptions={{
                    selectAllRowsItem: true,
                    selectAllRowsItemText: "All",
                }}
                selectableRows
                selectableRowsVisibleOnly
                selectableRowsHighlight
                selectedRows={selectedRows}
                setSelectedRows={handleSelectRows}
            />
            {/* </Card> */}
        </>
    );
};


export default CustomersFirebaseListSearch;