import { Alert, Grid, NumberInput, } from '@mantine/core'



import React, { useEffect, useState } from 'react';
import { JournalEntryRow } from '../../components/journal_entry_row';


const JournalEntryForm = ({ form, custodianAccounts, disabled }: any) => {

    const [journalsTotal, setJournalsTotal] = useState(0);



    useEffect(() => {
        if (!form.values?.entriesList)
            form?.setValues((prev: any) => ({ ...prev, entriesList: [{ debit: 0, credit: 0, financialAccount: null }] }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if (form.values.entriesList?.length) {
    //         setEntriesList(form.values.entriesList);
    //     }

    // }, [form.values.entriesList,])



    const calculcateTotal = () => {
        let calcValue = 0;

        form.values.entriesList.map((entryValue: any) =>
            calcValue += (entryValue.credit + entryValue.debit)
        )



        setJournalsTotal(calcValue);
    }


    if (!form.values.customerID)
        return <Grid.Col span={{ xs: 12 }} >

            <Alert
                style={{ width: "100%" }}
            >
                Please Select a customer
            </Alert>
        </Grid.Col>


    return (

        <>



            {form.values?.entriesList?.map((entry: any, idx: number) => {

                return <JournalEntryRow
                    disabled={disabled}

                    calculcateTotal={calculcateTotal}
                    custodianAccounts={custodianAccounts}
                    form={form}
                    idx={idx}
                    key={idx}
                />
            })


            }

            <Grid.Col span={{ lg: disabled ? 3 : 2, xs: 3, }} offset={{ lg: 4 }}>

                Total (Debit+Credit)
            </Grid.Col>
            <Grid.Col span={{ xs: 3, lg: disabled ? 3 : 2 }}>

                <NumberInput
                    decimalScale={4}
                    disabled={true}
                    onChange={() => { console.log("CHANGE TOTAL") }}
                    required
                    value={journalsTotal}
                />

            </Grid.Col>



        </>





    )
}

export default JournalEntryForm