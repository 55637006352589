import { Alert, Button, Grid, MultiSelect, NumberInput, Select, Space, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { User } from 'firebase/auth'
import { FieldValue, Firestore, QueryDocumentSnapshot, Timestamp, UpdateData, deleteField, setDoc, updateDoc } from 'firebase/firestore'


import { ReactNode, useEffect, useState } from 'react'
import { modals } from '@mantine/modals'
import { IconAlertCircle } from '@tabler/icons-react'

import { ObjectArrayType } from './customer_kyc'
import { EditableComponentType, UpdatedDataType } from '../../types'


interface ItemInfoEditFormType<T> {
    formItem?: JSX.Element,
    componentType: EditableComponentType
    data: ObjectArrayType[] | string[]
    value: string[] | string | number,
    handleChange?: (value: string | number | null) => { [key: string]: any };
    rightSection?: ReactNode;
    // itemData: KYC
    user: User
    // customerID: string
    db: Firestore
    fieldName: string
    itemDoc: QueryDocumentSnapshot<T>
}

interface CustomFormProps {
    comments: string
    kycID: string
    user: User
    fieldName?: string
    updatedData?: string[] | number | string | null,
    otherValue?: string
}

function ItemInfoEditForm<T extends {
    id?: string,
    updated_data?: UpdatedDataType[],
}>({ componentType, data, value, user, rightSection, fieldName, itemDoc, handleChange }: ItemInfoEditFormType<T>) {
    const [loading, setLoading] = useState<boolean>(false);
    const [dependantData, setDependantData] = useState<{ [key: string]: string[] | string | number }>()

    const itemRef = itemDoc.ref;
    const itemData = itemDoc.data();

    const otherFieldName: keyof T = fieldName + "_other_field" as keyof T



    const form = useForm<CustomFormProps>({
        initialValues: {
            comments: "",
            kycID: itemData.id!,
            user: user,
            fieldName: fieldName,
            updatedData: Array.isArray(value) ? value as string[] : value as string | number,
            otherValue: itemData[otherFieldName] as string
        },
        validate: {
            comments: (val?: string) => (!val || val.length <= 0 ? "Please enter why you are performing an update" : null)
        }
    });

    useEffect(() => {
        if (!(form.getInputProps("updatedData")?.value === "other" ||

            (Array.isArray(form.getInputProps("updatedData")?.value) && form.getInputProps("updatedData").value?.includes("other"))

        )
        ) {

            form.setValues((prev) => ({ ...prev, otherValue: undefined }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.getInputProps("updatedData").value])

    return (

        <form
            onSubmit={form.onSubmit(async (values) => {



                if (itemRef) {
                    setLoading(true)
                    let updatedItemData: Partial<T> = {
                        [fieldName as keyof T]: values.updatedData,
                    } as Partial<T>;
                    const updated_fields: { [key: string]: (string | null)[] | number | string | FieldValue }[] = []
                    const initial_values: { [key: string]: (string | null)[] | number | string }[] = []

                    if (dependantData) {
                        Object.keys(dependantData)?.map((keyValue) => {
                            updatedItemData[keyValue as keyof T] = dependantData[keyValue] as T[keyof T];
                            updated_fields.push({ [keyValue]: dependantData[keyValue] });
                            initial_values.push({ [keyValue]: (itemData[keyValue as keyof T]) as ((string | null)[] | number | string) ?? "" })
                        })
                    }
                    let updateValueCompare: (string | null)[] | number | string | null = values.updatedData!;
                    let updateValueCompareWith: T[keyof T] = itemData[fieldName as keyof T];
                    if (Array.isArray(values.updatedData)) {
                        updateValueCompare = (values.updatedData.sort().join(","))
                        // updateValueCompareWith = (itemData[fieldName as keyof T] as string[])?.sort()?.join(",");


                    }
                    if (updateValueCompare !== updateValueCompareWith) {

                        if (!updateValueCompare) {

                            updated_fields.push({ [fieldName]: "DELETED" });
                            updatedItemData = {
                                ...updatedItemData,
                                [fieldName]: deleteField()
                            }
                        }
                        else {
                            updated_fields.push({ [fieldName]: (updateValueCompare) });
                        }
                        initial_values.push({ [fieldName]: (updateValueCompareWith ?? "") as string })
                    }


                    if (values.otherValue !== itemData[otherFieldName]) {
                        initial_values.push({ [otherFieldName as string]: (itemData[otherFieldName] ?? "") as string })


                        if (values.otherValue) {
                            updated_fields.push({ [otherFieldName]: values.otherValue })
                            updatedItemData[otherFieldName] = values!.otherValue! as T[keyof T]
                        }
                        else {
                            updated_fields.push({ [otherFieldName]: "DELETED" })

                            updatedItemData = {
                                ...updatedItemData,
                                [otherFieldName as keyof T]: deleteField()
                            }


                        }
                    }

                    if (updated_fields.length) {

                        const intialUpdatedByList = itemData.updated_data || []


                        updatedItemData["updated_data"] = [...intialUpdatedByList, {
                            updated_by: values.user.email!,
                            updated_on: Timestamp.now(),
                            initial_value: initial_values,
                            updated_fields: updated_fields,
                            reason: values.comments

                        }]



                        // await updateDoc(itemRef, updateditemData)
                        await setDoc(itemRef, updatedItemData, { merge: true });
                        setLoading(false)

                        modals.closeAll();
                    }
                }

            })}
        >



            {componentType === "Select" ? <Select
                disabled={loading}
                rightSection={rightSection}
                data={data as string[] | ObjectArrayType[]}
                value={form.getInputProps("updatedData").value as string}
                onChange={(selectedValue, option) => {
                    form.setValues((prev) => ({ ...prev, updatedData: selectedValue }))
                    if (handleChange)
                        setDependantData(handleChange(selectedValue))
                }
                }

            /> : componentType === "MultiSelect" ?
                <MultiSelect
                    disabled={loading}
                    rightSection={rightSection}

                    data={data as string[] | ObjectArrayType[]}

                    value={form.getInputProps("updatedData").value as string[]}
                    onChange={(selectedValues: string[]) => {
                        form.setValues((prev) => ({ ...prev, updatedData: selectedValues }))

                    }
                    }

                /> :
                componentType === "NumberInput" ?
                    <TextInput
                        type='number'
                        disabled={loading}
                        // defaultValue={0}
                        value={form.getInputProps("updatedData")?.value}


                        onChange={(e) => {



                            form.setValues((prev) => ({ ...prev, updatedData: parseInt(e.target.value) }))
                            if (handleChange)
                                setDependantData(handleChange(e.target.value))
                        }
                        }



                    />
                    :
                    <TextInput
                        disabled={loading}

                        value={form.getInputProps("updatedData").value as string}

                        onChange={(e) => {
                            form.setValues((prev) => ({ ...prev, updatedData: e.target.value }))
                            if (handleChange)
                                setDependantData(handleChange(e.target.value))
                        }
                        }
                    />

            }



            <>
                {
                    (form.getInputProps("updatedData")?.value === "other" ||
                        (Array.isArray(form.getInputProps("updatedData")?.value) && form.getInputProps("updatedData").value?.includes("other"))
                    ) && <>
                        <Space h="lg" />
                        <Text>{"Other "}</Text>
                        <TextInput
                            disabled={loading}

                            value={form.getInputProps("otherValue").value}

                            onChange={(e) => {
                                form.setValues((prev) => ({ ...prev, otherValue: e.target.value }))

                            }

                            }
                        />

                    </>
                }
            </>



            <Text mt={5}>{"Reason for edit"}</Text>
            <TextInput

                {...form.getInputProps("comments")}
                required
                disabled={loading}
                value={form.getInputProps("comments").value}
                error={form.errors.comments}
                onChange={(e) =>
                    form.setValues((prev) => ({ ...prev, comments: e.target.value }))

                }
            />

            {form.errors != null && Object.keys(form.errors).length > 0 &&
                <>
                    <Space h="lg" />
                    <Alert
                        icon={<IconAlertCircle size={16} />}
                        color="red"
                        title="Check form"
                    >
                        {Object.values(form.errors).join(", ")}
                    </Alert>

                </>
            }

            <Space h="lg" />
            <Grid >
                <Grid.Col span={6}>

                    <Button fullWidth mt={5} type="submit"
                        variant='outline'
                        // disabled={loading}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </Grid.Col>
                <Grid.Col span={6}>

                    <Button fullWidth mt={5}
                        disabled={loading}
                        // loading={loading}

                        onClick={() => modals.closeAll()}
                        variant='outline'
                        color={"red"}
                    >
                        Cancel
                    </Button>

                </Grid.Col>

            </Grid>

        </form >


    )
}

export default ItemInfoEditForm