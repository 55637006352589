import React, { useContext, useEffect, useState } from 'react'
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"

import { MyUserDataContext } from '../contexts';
import { Loader, MultiSelect } from '@mantine/core';









const UserGroupsSearch = (props: any) => {
    const onItemSubmit: any = props.onItemSubmit
    const values: string[] = props.values;
    const objKey: string[] | null = props.objKey;
    const apiURl: string | null = props.apiURl
    const [autocompleteData, setAutocompleteData] = useState<MicrosoftGraph.User[]>([]);
    const [chosenData, setChosenData] = useState<string[]>();
    const [autocompleteLoading, setAutocompleteLoading] = useState<boolean>(false);
    const appContext = useContext(MyUserDataContext);

    // const kfhcapitalGroup = process.env.REACT_APP_KFH_MICROSOFT_GROUP

    useEffect(() => {
        if (apiURl?.length)
            appContext.microsoftClient
                ?.api(apiURl)
                .header("ConsistencyLevel", "eventual")
                .top(999)
                .get()
                .then((userRes) => {
                    let userResults: MicrosoftGraph.User[] = userRes.value
                    setAutocompleteData(userResults);
                    setAutocompleteLoading(false);
                });
        setChosenData(values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (values: string[]) => {
        if (onItemSubmit) {
            console.log("objKeyzzzz ========>>>", objKey);

            if (objKey)
                onItemSubmit(values, objKey)

            onItemSubmit(values)
        }
    }

    useEffect(() => {
        setChosenData(values)
    }, [values])


    return (
        <>
            <MultiSelect style={{ height: "100%" }}
                data={autocompleteData.map((value: any) => {
                    return {
                        ...value,
                        label: value.displayName,
                        value: value.id
                    }
                })}
                value={chosenData}
                // valueComponent={autoCompleteItem}
                // itemComponent={autoCompleteItem}
                searchable
                onChange={handleChange}
                comboboxProps={{ withinPortal: true }}
                // dropdownPosition="bottom"
                placeholder="choose Approver"
                label={props.label || ""}
            />
            <Loader
                size="xs"
                style={(theme) => ({
                    width: "50px",
                    maxWidth: "50px",
                    visibility: autocompleteLoading ? "visible" : "hidden",
                })}
            />
        </>
    )
}

export default UserGroupsSearch