import { Button, Group, Modal, Paper, PasswordInput, Space, Stack, Text, TextInput } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import classes from '../customAppStyle.module.css';
import { MultiFactorInfo, MultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, TotpMultiFactorGenerator, getAuth, getMultiFactorResolver, signInWithEmailAndPassword } from 'firebase/auth';
import { useForm } from '@mantine/form';
import { auth } from '../helpers/firebaseContext';


interface EmailLoginProps {

}



interface EmailLoginType {
    email: string;
    password: string;
}


export function EmailLogin(props: EmailLoginProps) {

    // const app = useFirebaseApp();
    // const auth = getAuth(app);



    const form = useForm<EmailLoginType>({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 8 characters' : null),
        },
    });


    const [verificationCode, setVerificationCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [checkingVerificationCode, setCheckingVerificationCode] = useState<boolean>(false);
    const [showVerificationInput, setShowVerificationInput] = useState<boolean>(false);
    const [verificationId, setVerificationId] = useState<string | null>(null);
    const [OTPError, setOTPError] = useState<string | null>(null);
    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
    const [multifactorResolver, setMultifactorResolver] = useState<MultiFactorResolver | null>(null);

    const [hint, setHint] = useState<MultiFactorInfo | undefined>();

    useEffect(() => {
        recaptchaVerifierRef.current = new RecaptchaVerifier("sign-in-button", {
            "size": "invisible",
            "callback": (response: any) => {
                console.log("ReCAPTCHA solved, proceed with verification.", response, form.values);
                // emailLogin(form.values);
            }
        }, auth);
        recaptchaVerifierRef.current.render().catch(e => console.error("ReCAPTCHA render error:", e));


    }, [auth]); // Initialize the reCAPTCHA verifier on component mount





    useEffect(() => {
        if (!multifactorResolver) return;

        console.log(multifactorResolver.hints);

        let selectedIndex = 0; // Assuming the first factor is selected for simplicity

        // console.log("We have this many hints", multifactorResolver.hints.length, multifactorResolver.hints);
        setHint(multifactorResolver.hints[selectedIndex]);

        if (multifactorResolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
                multiFactorHint: multifactorResolver.hints[selectedIndex],
                session: multifactorResolver.session,
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current!)
                .then((id) => {
                    console.log("Verification code sent", id);
                    setVerificationId(id);
                    setShowVerificationInput(true); // Show input field for the user to enter the verification code
                })
                .catch((error) => {
                    console.error("SMS verification error:", error);
                    setLoading(false);
                    form.setFieldError('email', "Can't send SMS verification code.  Please contact yalhusayan@kfhcapital.com.kw");
                }
                );
        } else if (multifactorResolver.hints[selectedIndex].factorId === TotpMultiFactorGenerator.FACTOR_ID) {
            // Handle TOTP MFA here
        } else {
            // Unsupported second factor
        }
    }, [multifactorResolver]); // Depend on multifactorResolver




    function handleVerificationCodeSubmit() {
        if (!verificationId || verificationCode === "") return;

        setCheckingVerificationCode(true);
        setOTPError(null);
        const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneCredential);

        multifactorResolver!.resolveSignIn(multiFactorAssertion)
            .then((userCredential) => {
                console.log("User signed in with 2FA", userCredential.user);
                setShowVerificationInput(false);
                setLoading(false);
                setCheckingVerificationCode(false);

            })
            .catch((error) => {
                console.error("Error signing in with 2FA:", error);
                setOTPError(error.message);
                // setLoading(false);
                setCheckingVerificationCode(false);
            });
    }




    async function emailLogin(values: EmailLoginType) {
        console.log("Signing in.");
        setLoading(true);

        try {


            await recaptchaVerifierRef.current?.verify();
        } catch (e: any) {
            console.log(e.message);
            if (e.code === "auth/network-request-failed") {
                form.setFieldError('email', "Can't reach the server.  Please ask IT to allow you to access this site.");
            }
            else {
                form.setFieldError('email', e.message);
            }
            setLoading(false);
            return;

        }
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                console.log(userCredential.user);
                setLoading(false);
            })
            .catch((error) => {
                if (error.code === 'auth/multi-factor-auth-required') {
                    console.log(error);
                    setMultifactorResolver(getMultiFactorResolver(auth, error));

                } else {
                    console.log("Login error", error)
                    form.setFieldError('email', error.message);
                    setLoading(false);
                }
            });
    }

    return (

        <>
            <form onSubmit={form.onSubmit(emailLogin)}>

                <TextInput
                    required
                    label="Your KFH Email"
                    placeholder="xxxx@kfh.com"
                    value={form.values.email?.toLowerCase()}
                    onChange={(event) => form.setFieldValue('email', event.currentTarget.value?.toLowerCase())}
                    error={form.errors.email}
                    radius="md"
                />

                <PasswordInput
                    required
                    label="Password"
                    placeholder="Your password"
                    value={form.values.password}
                    onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                    error={form.errors.password}
                    radius="md"
                />
                <Space h="lg" />


                <Group grow className={classes.controls}>
                    <Button type='submit' disabled={loading} color="green" leftSection={<IconMail />} className={classes.control}>Log in with user/pass (For KFH)</Button>
                </Group>
                <div id="sign-in-button" />

            </form>
            <Modal
                opened={showVerificationInput}
                onClose={() => { setShowVerificationInput(false); setLoading(false); setCheckingVerificationCode(false); }}
                title={`Enter OTP sent to ${hint?.displayName}`}>

                <Stack>
                    <TextInput
                        value={verificationCode}
                        onChange={(event) => setVerificationCode(event.currentTarget.value)}
                        placeholder="OTP"
                        maxLength={6}
                        minLength={6}
                        disabled={checkingVerificationCode}
                        error={OTPError}
                    />
                    <Button loading={checkingVerificationCode} onClick={handleVerificationCodeSubmit}>Submit</Button>

                    <Text c="dimmed" size='xs'>If you don't receive the OTP, please inform us at yalhusayan@kfhcapital.com.kw</Text>
                </Stack>
            </Modal>
        </>
    );


}