import { Combobox, Text, TextInput, TextInputProps, useCombobox } from "@mantine/core";

import { Key, Ref, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react"

import { InstantSearch, useHits, useInstantSearch, useSearchBox } from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { MyUserDataContext } from "../contexts";
import { CustomAutocomplete, RMUser } from "../types";
import { createRenderingInfoPrinter } from "../utils";


const debug = createRenderingInfoPrinter("SearchBoxComponent");

interface RmsSelectComponentProps {
    // setRmUser: (item: any) => void;
    defaultValue?: string
    textProps?: TextInputProps,
    handleSubmit?: (item: any) => void,
    rmUser?: (RMUser & CustomAutocomplete)[]

}
export interface AutocompleteComponentHandle {
    submitItem: (item: any) => void;
}


const SearchBoxComponent =
    forwardRef<AutocompleteComponentHandle, RmsSelectComponentProps>(
        ({ handleSubmit, textProps, defaultValue, ...props }: any, ref: Ref<AutocompleteComponentHandle>) => {

            debug();
            const combobox = useCombobox({});

            const memoizedSearch = useCallback((query: any, search: any) => {
                // console.log("Searching!");
                search(query);

            }, []);

            const { refine } = useSearchBox({
                queryHook: memoizedSearch,
            });

            const [inputValue, setInputValue] = useState<string>(defaultValue || "");

            //I hate this, but useInstantSearch and useHits apparently change when the component is reloaded, thus triggering setCustomers in a loop.
            const [searchChanged, setSearchChanged] = useState<boolean>(false);


            const { status } = useInstantSearch()
            const { items } = useHits();



            useEffect(() => {

                // console.log("input value changed to", inputValue);
                // if (setRmUser) {
                //     setRmUser(items)
                // }

                // console.log("SET CUSTOMER =====>>>", items)
                setSearchChanged(true);

                refine(inputValue);


                // eslint-disable-next-line 
            }, [inputValue])

            useEffect(() => {
                console.log("Status is", status, "items is", items.length);
                if (status === "idle" && searchChanged) {
                    // setRmUser(items)
                    setSearchChanged(false);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [status, searchChanged]);

            // useEffect(() => {
            //     if (rmUser.length === 1) {
            //         console.log("HITS IS  ONLY 1 !!!!! =======>>>", items[0]);
            //         combobox.selectFirstOption();
            //         combobox.clickSelectedOption();
            //         // handleSubmit(items[0])

            //     }
            //     else {
            //         console.log("HITS MORE THAN 1 !!!!! =======>>>", items);
            //     }
            // }, [rmUser])

            // const inputRef = useRef<HTMLInputElement>(null);




            useImperativeHandle(ref, () => ({

                submitItem(item: string) {


                    setInputValue(item)
                    refine(item);

                    handleSubmit(item)

                }
            }));

            return <Combobox
                store={combobox}
                onOptionSubmit={(val) => {

                    combobox.closeDropdown();
                    const foundRM: RMUser | undefined = items.find((rmObj) => rmObj.id === val) as RMUser | undefined
                    console.log("FOUND RM USER =======>>", foundRM);
                    console.log("FOUND RM VAL =======>>", val);
                    if (foundRM)
                        setInputValue(foundRM?.name_en)

                    if (handleSubmit) {

                        handleSubmit(foundRM)
                    }

                }}
            >
                <Combobox.Target>
                    <TextInput
                        required
                        {...textProps}


                        // ref={inputRef}
                        value={textProps?.disabled ? "" : inputValue}
                        rightSection={<Combobox.Chevron />}

                        onChange={(e) => {

                            setInputValue(e.currentTarget.value)

                        }}
                        onKeyDown={(e) => {
                            // console.log("ON KEY DOWN!!!", e.key);
                            //was it a backspace?
                            if (e.key === "Backspace") {
                                console.log("backspace");
                                setInputValue("")
                                handleSubmit()
                            }
                        }}
                        onClick={() => combobox.openDropdown()}
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}

                    />
                </Combobox.Target>

                <Combobox.Dropdown>

                    <Combobox.Options
                        mah={200} style={{ overflowY: 'auto' }}
                    >{
                            items.length > 0 ? items.map((item: any, i: Key) => (
                                <Combobox.Option value={item.id} key={i}>
                                    <Text>{`${item.id} - ${item.name_ar}`}</Text>
                                </Combobox.Option>
                            )) : <Combobox.Empty>Nothing found</Combobox.Empty>
                        }</Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
        });

export const RmsSelectComponent = forwardRef<AutocompleteComponentHandle, RmsSelectComponentProps>(
    ({ textProps, defaultValue, handleSubmit, ...props }: RmsSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {

        const userData = useContext(MyUserDataContext);

        const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
            server: {
                apiKey: userData.rmsCollectionSearchKey!, // Be sure to use a Search API Key
                nodes: [
                    {
                        host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                        port: 443,
                        protocol: 'https'
                    },
                ],

            },

            additionalSearchParameters: {
                query_by: "email,name_en,rm_id,name_en",
            },

        })
        const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

        return (
            <InstantSearch
                indexName={process.env.REACT_APP_TYPESENSE_RMS_COLLECTION as string}
                searchClient={typesenseSearchClient}
            >
                <SearchBoxComponent ref={ref} textProps={textProps} defaultValue={defaultValue} handleSubmit={handleSubmit} />
            </InstantSearch>
        )
    });

