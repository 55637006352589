import React from "react";

import { Card, Group, Space, Stack, Text, Container, TextInput, SegmentedControl } from "@mantine/core";

import { Button } from "@mantine/core";


export default function Reports() {



    return (

        <Card shadow="sm" padding="sm">
            <iframe title="Report"
                width="1200"
                height="800"
                src="https://lookerstudio.google.com/embed/reporting/4ce71091-b17e-4bca-89d3-1012e3ee916a/page/i1HqD"
                style={{ border: 0 }}
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            />

        </Card>
    );
}
