/* eslint-disable eqeqeq */

import { useContext, useState } from "react";
import { useForm } from "@mantine/form";

import {
  Alert,
  Grid,
  Group,
  Modal,
  Select,
  Space,
  Title,
} from "@mantine/core";

import { Button } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { ActionType, NotificationMSG, } from "../types";
import { MyUserDataContext } from "../contexts";

type FormProps = {
  actionValue: string;
};

interface CustomListActionsModalType<T> {
  rows?: T[],
  opened: boolean,
  setOpened: (value: boolean) => void
  availableActions: { value: ActionType, label: string }[]
  renderErrors: (failedItem: T) => any
  handleSubmit: (item: T, action: string, token?: string) => void
  getItemsData: (action: string, rows: T[]) => T[]
}

function CustomListActionsModal<T>(props: CustomListActionsModalType<T>) {
  const userContext = useContext(MyUserDataContext);

  const selectedRows: T[] = props.rows || []
  const opened: boolean = props.opened;
  const setOpened = props.setOpened;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [failedUpdateItems, setFailedUpdateItems] = useState<{ [errorName: string]: T[] }>({});
  const [filteredItems, setFilteredItems] = useState<T[] | null>(null);
  const [failedUpdateAction, setFailedUpdateAction] = useState<string>();

  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState<NotificationMSG | null>(null);
  const availableActions = props.availableActions
  const handleSubmit = props.handleSubmit



  const form = useForm<FormProps>({


  });
  const getItemsData = (action: string) => {

    const itemsData = props.getItemsData(action, selectedRows)
    setFilteredItems(itemsData)
    return itemsData

  }


  const renderErrors = Object.keys(failedUpdateItems!)?.map((failedOrderError) =>
    <Group title="">

      <Title order={6}>{failedOrderError}</Title>

      {failedUpdateItems![failedOrderError].map((failedOrder) =>
        <>
          {props?.renderErrors(failedOrder)}

        </>
      )}

    </Group>
  )


  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setMsg(null);
          setOpened(false);
          setConfirming(false)
          setFailedUpdateItems({})
          setFilteredItems(null)

        }}
        title="Update Status"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {




            // setConfirmDialogError(null);
            await setMsg(null);
            await setFailedUpdateItems({})
            await setFilteredItems(null)

            // props?.setPending(true)

            setConfirming(true);
            console.log("yeaaa we are here");

            try {

              let token = await userContext.getAzureADToken!(userContext);

              if (!token) {
                setIsError(true);

                setMsg({ type: "error", message: "No Microsoft Token.  Try refreshing." });
                return;
              }

              const itemsData = await getItemsData(values.actionValue as string)


              for (let item of itemsData) {


                try {

                  const res: any = await handleSubmit(item, values.actionValue, token)
                }
                catch (e: any) {
                  if (e?.message) {
                    const newFailedOrders = failedUpdateItems

                    if (newFailedOrders[e?.message]?.length) {
                      newFailedOrders[e.message] = [...newFailedOrders[e.message], item]
                    }
                    else {
                      newFailedOrders[e.message] = [item]

                    }
                    setFailedUpdateItems(newFailedOrders)
                    setFailedUpdateAction(values.actionValue)
                  }
                }

              }
              // setIsError(true);
              setMsg({ type: "success", message: "Success" });
            } catch (error: any) {
              // setIsError(true);
              console.log(error);
              // setMsg(error.message);
              // setMsg({type:"error",msg:});
              setMsg({ type: "error", message: error.message });


              // setConfirmDialogError(error.message);
            }

            setConfirming(false);

          })}
        >

          <Grid>

            <Grid.Col span={{ md: 12, lg: 12 }}>

              <Select
                {...form.getInputProps("actionValue")}
                onChange={(value) => {

                  form.setFieldValue("actionValue", value as string)
                  setMsg(null);
                  setFailedUpdateItems({})
                  setFilteredItems(null)

                }}
                label="Choose status to change to"
                placeholder="Pick value"
                data={availableActions}
              />
            </Grid.Col>
          </Grid>
          <Space h="xl" />
          {

            failedUpdateItems && !!Object.keys(failedUpdateItems)?.length && <>

              <Alert
                icon={<IconAlertCircle size={16} />}
                color={"yellow"}
                title={`Some Items could not update to ${failedUpdateAction}`}
              >
                {renderErrors}

              </Alert>
            </>
          }

          <Space h="xl" />
          <Group>
            {

              filteredItems && filteredItems?.length < selectedRows?.length && <>

                ( {(selectedRows?.length - filteredItems?.length)} / {selectedRows?.length} ) cannot be changed to {failedUpdateAction}

              </>
            }
          </Group>
          <Space h="xl" />
          {(msg && (filteredItems?.length === selectedRows?.length)) && <Alert
            icon={<IconAlertCircle size={16} />}
            color={msg?.type === "success" ? "green" : "red"}
            title={msg?.type}
          >
            {msg?.message}

          </Alert>}
          <Space h="xl" />

          <Group>
            <Button color="grape" type="submit" loading={confirming} >
              Confirm
            </Button>

          </Group>
        </form>
      </Modal >
    </>
  );
}

export default CustomListActionsModal;