import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { BsPlusCircle } from "react-icons/bs";


import CustomDataTable from "../../components/custom_data_table";
import { Badge, Button, Card, Grid, Group, Menu, Modal, Space, Text, Title, Tooltip, UnstyledButton, useMantineTheme } from "@mantine/core";
// import KYCStatusFilter from "../../components/KYCStatusFilter";

import useLocalStorage from "use-local-storage";
import { IconDots, IconTrashFilled } from "@tabler/icons-react";
import { QueryDocumentSnapshot, orderBy, where } from "firebase/firestore";
import { MyUserDataContext } from "../../contexts";
import { useDisclosure } from "@mantine/hooks";
import { FirebaseTransaction, ProcessActionsDocument, TypesenseTransaction } from "../../types";
import { getFunctions, httpsCallable } from "firebase/functions";
import TransactionsInstantSearch from "../../components/transactions_instant_search";
import { RefreshButton, RefreshButtonRef } from "../../components/customer_instant_search";



const TRANSACTION_TYPES = [{
    "label": "Cash In",
    "value": "cash_in",
    // type: "cash",
    // action: "Deposit"
},
{
    "label": "Journal Entry",
    "value": "journal_entry",
    // type: "cash",
    // action: "Deposit"
},
{
    "label": "Cash Out",
    "value": "cash_out",
    // type: "cash",
    // action: "Withdraw"
},
{
    "label": "Security In",
    "value": "security_in",
    // type: "security",
    // action: "ReceiveLong"
},
{
    "label": "Security Out",
    "value": "security_out",
    // type: "security",
    // action: "DeliverLong"
},
{
    "label": "FX + Transfer",
    "value": "fx",
    // type: "fx",
    // action: "SpotFX"  //not needed
},

]

const SubscriptionAndRedemptionList = () => {

    const theme = useMantineTheme();
    const appContext = useContext(MyUserDataContext);

    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [chosenTransaction, setChosenTransaction] = useState<any>(null);

    const [deleteModalOpen, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { refreshTransactionsIn } = location.state || {};


    const functions = getFunctions();

    const refreshRef = useRef<RefreshButtonRef | null>(null);

    const handleExternalRefresh = () => {
        if (refreshRef.current) {
            refreshRef.current.refresh();
        }
    };

    useEffect(() => {
        if (refreshTransactionsIn) {
            const timer = setTimeout(() => {
                // Action to perform after delay
                console.log('Refreshing orders...');
                handleExternalRefresh();
                // Place your code here to refresh orders or any other intended action
            }, refreshTransactionsIn);

            // Clean up the timer when the component unmounts or if the value of refreshTransactionsIn changes
            return () => clearTimeout(timer);
        }
    }, [refreshTransactionsIn]); // Dependency array, re-run effect if refreshTransactionsIn changes


    const handleDelete = (row: any) => {

        setChosenTransaction(row)
        open()

    }
    const handleCloseDelete = () => {
        setChosenTransaction(null)
        setError(null)
        setPending(false)
        close()
    }
    const handleStatusChange = async (transactionID: any, customerID: any, status: string, token: string | undefined) => {
        const actionValues = { transactionID: transactionID, customerID: customerID, action: status, token: token }
        const takeAction = httpsCallable(functions, 'transactionAction');
        const result = await takeAction(actionValues);
        return result
    }


    const handleConfirmDelete = async (row: TypesenseTransaction) => {

        setPending(true)
        setError(null)
        let token = await appContext.getAzureADToken!(appContext);
        try {
            await handleStatusChange(row?.transactionID, row?.customerID, "delete", token)
            // modals.closeAll()
            handleCloseDelete()
        } catch (error: any) {
            console.log("error is ", error);

            setError(error);
        }
        setPending(false)

    }

    const RenderActions = (transaction: TypesenseTransaction) => {
        return <Menu
            position="bottom-start"
            withArrow
            arrowPosition="center"
        >
            <Menu.Target>
                <UnstyledButton pr={12}> <IconDots />  </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>


                <Menu.Item
                    leftSection={<IconTrashFilled size={16}

                        color={theme.colors.blue[6]} stroke={1.5} />}
                    onClick={() => handleDelete(transaction)}
                >

                    Delete
                </Menu.Item>




            </Menu.Dropdown>
        </Menu>

    }
    const searchableTransactions = useMemo(() =>
        <>
            <Group justify="space-between">
                <Tooltip label={`indexName=${process.env.REACT_APP_TYPESENSE_TRANSACTION_COLLECTION}`}><Title>Transactions</Title></Tooltip>
                <Button
                    leftSection={<BsPlusCircle />}
                    onClick={() => {
                        navigate("/transactions/add");
                    }}
                >
                    {" "}
                    Add Transactions{" "}
                </Button>
            </Group>

            <Space h="xl" />
            <Card shadow="sm" p="lg" radius="md" withBorder>
                <Grid justify="space-between">
                    <Grid.Col>

                    </Grid.Col>
                    <Grid.Col>
                        <TransactionsInstantSearch
                            additionalChildren={<RefreshButton ref={refreshRef} />}


                            ActionsComponent={RenderActions}

                            indexName={process.env.REACT_APP_TYPESENSE_TRANSACTION_COLLECTION!}

                        />
                    </Grid.Col>
                </Grid>
            </Card>


            {chosenTransaction && deleteModalOpen && <Modal
                title={
                    <Text size="md" fw={"bold"}>
                        Delete Transaction
                    </Text>
                }
                centered={true}
                opened={deleteModalOpen}
                onClose={close}

            >

                <Text size="md" >
                    Are you sure you want to delete the transcation? This action is destructive.
                </Text>
                <Text size={"sm"} c="red">
                    {error?.message}

                </Text>


                <Group mt="xl" justify="center">
                    <Button variant="outline" disabled={pending} onClick={handleCloseDelete}>
                        No don't delete it
                    </Button>

                    <Button color="red" disabled={pending} onClick={() => handleConfirmDelete(chosenTransaction)}>
                        Delete Transaction
                    </Button>
                </Group>

            </Modal>}
        </>,
        // eslint-disable-next-line 
        [chosenTransaction, deleteModalOpen]);



    return (
        <>
            {searchableTransactions}



        </>
    );
};

const TransactionsList = () => {


    return (
        <main style={{ padding: "1rem 0" }}>
            {/* {searchableTransactions} */}
            <SubscriptionAndRedemptionList />
        </main>
    );
};

export default TransactionsList;
