import { AuthenticationResult } from "@azure/msal-browser";
import { IdTokenResult, User } from "firebase/auth";
import { Client } from "@microsoft/microsoft-graph-client";
import { FieldValue, Timestamp } from "firebase/firestore";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import { MimeType } from "file-type/core";
// import { File } from "buffer";
import { PDFImage } from "pdf-lib";
import { ComboboxItem, ComboboxProps, } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
// import { info } from "console";



export type UserDataType = {
    pdf?: any,
    user?: User | null,
    msal?: AuthenticationResult,
    firebaseTokenResult?: IdTokenResult,
    getAzureADToken?: (userData: UserDataType) => Promise<string | undefined>,
    microsoftClient?: Client | null,
    customSearchKey?: string,
    rmsCollectionSearchKey?: string
}

export enum KFHGroup {
    "kfh_rm" = "kfh_rm",
    "rm" = "rm",
    "oper" = "oper",
    "cr" = "cr",
    "exec" = "exec",
    "sys" = "sys",
    "risk" = "risk",
    "admin" = "admin",
}

export interface AuthorizationClaims {
    claims: Partial<{
        [key in KFHGroup]: string[]
    }>
    updatedBy: string,
}

export type ConfigDataType = {
    risk?: { countries: string[] },
    approvers?: { [key: string]: string[] },
    // segment_groups?: { [key: string]: any },
    exchange_rates?: { [key: string]: any },
    authorizationClaims?: AuthorizationClaims,
    version?: {
        kycapp: string,
        webapp: string,
    },
}

export type AppConfigType = {
    apiUrl?: string,
}
export type GlobalDataType = {
    funds?: Fund[],
}



///////////////////////////////////////////////////////////////
// Data types shared by both firebase functions and client.
// May need to move this to a separate file.
///////////////////////////////////////////////////////////////


export interface LastOperation {
    date: Timestamp,
    msg: string,
}

export interface Tag {
    title: string,
    badgeColor: string,
    code: string,
}


export type Gender = "M" | "F";

export type SegmentType = "Ruwad" | "Private Banking" | "Tamayoz" | "Individual Finance" | "KFH Capital";
export interface GWIHolding {
    fund: string,
    shares: number,
    total: number,
    code: string,
    gl_code: string,
    gl_name: string,
}


export interface NotificationMSG {
    type: "success" | "error",
    message: string,

}

export interface UserFiles {
    type: string,
    file: string,

}


export interface CustomerPDFData extends Omit<Customer, "birthDate" | "civilIDExpiryDate" | "passportExpiryDate">, Omit<KYC, "birthDate" | "civilIDExpiryDate" | "passportExpiryDate" | "created"> {
    userFiles?: UserFiles[]
    passportExpiryDate?: string,
    civilIDExpiryDate?: string,
    birthDate?: string,

    created?: string,
    paciStamp?: PDFImage | Uint8Array | ArrayBuffer | string, //base64

    sourcesOfIncomeSentence?: string,
    sourcesOfWealthSentence?: string,

}



export interface LastValidKYC {
    created: Timestamp,
    kycID: string,
    civilIDExpiryDate?: Timestamp,
    passportExpiryDate?: Timestamp,
    kycExpiryDate?: Timestamp,
}


export interface EmployeeSignature {
    email: string,
    signature: string, //base64
    photo?: string, //base64
}

export interface Customer {

    commercial_register?: string,
    civilID?: string,
    crmID?: string,
    email?: string,
    paciEmail?: string,
    paciMobile?: string,
    id?: string,
    lastOperation?: LastOperation,
    mobile: string,
    name_en: string,
    name_ar: string,
    value?: string,
    gender?: Gender,
    tags?: { [key: string]: Tag },
    highRiskOverride?: string
    lowRiskOverride?: string
    kfhtrade_id?: string,
    nationality?: string,
    signature?: PDFImage | Uint8Array | ArrayBuffer | string, //base64
    paciData?: PACIData,
    tradexID?: string,
    lastValidKYC?: LastValidKYC;
    lastUnsubmittedKYCMessage?: {
        date: Timestamp,
        count: number,
    }
    oneTimeOverrideKYCData?: {
        civilIDExpiryDate?: Timestamp,
        houseID?: string,
        gender?: Gender,
        nationality?: string,
        passportExpiryDate?: Timestamp,
        passportNumber?: string,
        overrideCivilID?: boolean,
        overridePassport?: boolean,
    }
    holdings?: Record<string, GWIHolding>,
    passportData?: PassportData
    address_from_finder?: AddressFromFinder,
    virtual?: boolean
    agreements?: { [key: string]: Agreement }
}
export interface CustomerFundData {

    gl_code?: string,
    gl_name?: string,
    transactions?: Record<string, CustomerFundDataTransaction>,

}
export interface CustomerFundDataTransaction {

    amount?: number,
    code?: string,
    currency?: string,
    deal_date?: Timestamp,
    description?: string,
    fund?: string,
    period?: number,
    shares?: number,
    type?: string,
    updated_at?: Timestamp,
    amountInKWD?: number,

}

export interface TypesenseCustomer extends Customer {

    holdings_arr?: GWIHolding[]

}

export interface PassportData {
    id: string,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    expiryDate?: Timestamp,
}

export type SubscriptionType = "subscription" | "redemption";
export type ServiceType = "kfhtrade" | "kfhbrokerage" | "portfolio";

export interface Account {
    iban: string,
    account?: string,
    custom?: boolean,
}

export interface TypesenseOrder extends Partial<Order> {
    customerID: string
}
export interface UpdatedDataType {
    updated_by?: string
    updated_on?: Timestamp
    initial_value?: { [key: string]: (string | null)[] | number | string | FieldValue }[],
    updated_field_name?: string[]
    updated_fields?: { [key: string]: (string | null)[] | number | string | FieldValue }[]
    reason?: string
}



export interface BasicProductAttributes {
    id?: string,
    customerObj?: Customer,
    created?: Timestamp,
    rm?: RMUser,
    submittedBy?: RMUser,
    tags?: { [key: string]: Tag },
    approvedAt?: Timestamp,  //this is the date when the order was approved and thus will be used for NAV calculation
    draft?: boolean,  //is it a draft?  Then its orderState will always be a draft which you can send or delete.
    manual?: boolean,  //is it a manual?  Then its orderState will always be a manual and cannot be updated.
    voting?: boolean, //specify whether this order is voting or not and it will affect the pdf file.
    skipKYC?: boolean,
    paciData?: PACIData,
    signature?: PDFImage | Uint8Array | ArrayBuffer | string, //base64
    senderID?: string,
    segment?: string,
    iban?: string,
    needsDocuments?: boolean

}
export interface Service extends BasicProductAttributes {
    serviceType: ServiceType,


}
// CombinedType interface definition using Partial utility type for both Service and Order
export interface GenericProduct extends Partial<Service>, Partial<Order>, Partial<Agreement> {
    // type?: ServiceType | SubscriptionType,
}
export interface Order extends BasicProductAttributes {
    type: SubscriptionType,
    acct?: string,
    fund: Fund,
    units?: number,
    maxUnits?: boolean,
    unitsUpdatedBy?: string,
    transferDate?: Timestamp,
    transferAmount?: number,
    nav_per_unit?: number,  //one day I will settle on a naming convention for this.
    nav_date?: Timestamp,
    postNavAmount?: number,  //this should be the amount after fees are deducted
    fees?: number,
    // postNavUnits?: number,
    surplus?: number,
    updated_data?: UpdatedDataType[],
    estimated_units?: number,
    kyc_id?: string,
}




export interface EditableObjData {
    previousValue: string[] | number,
    currentValue: string[] | number,
    updatedOn: Timestamp,
    updatedBy: string,
    reason: string,
}
export type ItemInfoEditedObj<T> = {
    [key in keyof Partial<T>]?: EditableObjData;
};

export interface OrderDocument extends Order {
    name_en?: string
    name_ar?: string
    email?: string
    civilID?: string
    mobile?: string;
    status?: string
}

export enum KYCStatusCode {
    approval = "approval",
    review = "review",
    exec_approval = "exec_approval",
    client_update = "client_update",
    rejected = "rejected",
    no_kyc = "no_kyc",
    valid = "valid",
    invalid = "invalid",  //what the hell is invalid?
}

export enum KYCRiskCode {
    low = "low",
    medium = "medium",
    high = "high",
}


export enum OrderStatusCode {
    approval = "approval",  //under_review
    review = "review", //under_review
    waiting_for_nav = "waiting_for_nav",  //processing
    waiting_for_kyc = "waiting_for_kyc",  //under review
    need_signature = "need_signature",  //need signature
    processing = "processing",  //processing
    rejected = "rejected",  //rejected
    commitment = "commitment",  //NO
    done = "done", //done
    need_slip = "need_slip",  //need slip
    need_kyc = "need_kyc",  //need signature   (needs heavy testing)
    manual = "manual",  //NO
    unknown = "unknown",  //NO
}


export enum ServiceStatusCode {
    authorized_signatory_approval = "authorized_signatory_approval",
    waiting_for_kyc = "waiting_for_kyc",
    need_signature = "need_signature",
    rejected = "rejected",
    done = "done",
    need_slip = "need_slip",
    need_kyc = "need_kyc",
    unknown = "unknown",
}



export enum FileTag {
    Passport = "passport",
    FrontOfCivilID = "frontOfCivilID",
    BackOfCivilID = "backOfCivilID",
    PaymentSlip = "paymentSlip",
    SalaryCertificate = "salaryCertificate",
    ProofOfInheritance = "proofOfInheritance",
    ProofOfCredit = "proofOfCredit",
    ProofOfCompanyOwnership = "proofOfCompanyOwnership",
    AMLScreening = "amlScreening",
    Other = "other"
}

export enum DocumentTag {
    PaciAuth = "paciAuth",
    ProofOfIncome = "proofOfIncome",
    RequiredFeesTransfer = "requiredFeesTransfer-"

}

export const AllDocumentTags = { ...FileTag, ...DocumentTag }


export interface DocType {
    icon: any,
    name: string,
    color: string,
    key: string
}

export interface KYCState {
    status: string,
    status_code: KYCStatusCode,
    risk_code: KYCRiskCode,
    risk: string,
    created?: Timestamp,
}

export enum PoliticalPosition {
    royalFamilies = "royalFamilies",
    memberOfParliament = "memberOfParliament",
    seniorOfficial = "seniorOfficial",
    seniorExec = "seniorExec",
    politicalPartyOfficer = "politicalPartyOfficer",
}






export const sourcesOfIncome = ['salary', 'loan', 'profit', 'inheritance', 'other'];
export type SourcesOfIncome = typeof sourcesOfIncome[number];


export const sourcesOfWealth = ['investmentBusinessProfit', 'inheritance', 'savings', 'other'];
export type SourcesOfWealth = typeof sourcesOfWealth[number];

export const averageAnnualIncome = ['10000', '25000', '50000', '75000', '100000'];
export type AverageAnnualIncome = typeof averageAnnualIncome[number];


export const approximateWealth = ['0', '100000', '250000', '500000', '1000000'];
export type ApproximateWealth = typeof approximateWealth[number];

export const investedAmountOfWealth = ['20', '40', '60', '80', '100']
export type InvestedAmountOfWealth = typeof investedAmountOfWealth[number];

export const employmentOptions = ['privateBusiness', 'student', "retired", "unemployed", "employed", 'other'];
export type EmploymentOptions = typeof employmentOptions[number];

export enum YesNo {
    Yes = "true",
    No = "false",
}


export interface PowerOfAttorney {
    name: string
    nationality: string
    address: string
}



////ahhhh this is gonna be fun.
export interface KYC {
    id?: string,
    customerID?: string,
    name_en: string,
    name_ar: string,
    civilID?: string,
    civilIDExpiryDate?: Timestamp,
    created?: Timestamp,
    politicalPosition?: PoliticalPosition[],
    relativePoliticalPosition?: PoliticalPosition[],
    nationality?: string,
    ip?: string,
    birthDate?: Timestamp,
    passportExpiryDate?: Timestamp,
    linkID?: string,
    passportNumber?: string,
    paciData?: PACIData,
    gender?: Gender,
    address?: Address,
    address_from_finder?: AddressFromFinder,
    employment?: {
        [key: string]: any
    },
    employment_employer?: string,
    employment_yearsOfExperience?: number,
    employment_jobTitle?: string,
    employment_employerPhone?: string,
    houseID?: string;
    placeOfBirth?: string
    sourcesOfIncome?: SourcesOfIncome[],
    sourcesOfWealth?: SourcesOfWealth[],
    sourcesOfWealth_other_field?: string,
    sourcesOfIncome_other_field?: string,
    updated_data?: {
        updated_by?: string
        updated_on?: Timestamp
        initial_value?: { [key: string]: (string | null)[] | number | string }[],
        updated_field_name?: string[]
        updated_fields?: { [key: string]: (string | null)[] | number | string }[]
        reason?: string
    }[],
    averageAnnualIncome?: AverageAnnualIncome,
    approximateWealth?: ApproximateWealth,
    politicallyExposed?: boolean,
    powerOfAttorney?: boolean,
    investmentAwareness?: string,
    outsideKuwait?: string,
    outsideKuwait_address?: string,
    outsideKuwait_zip?: string,
    outsideKuwait_city?: string,
    outsideKuwait_country?: string,
    tin?: Tin[],
    step?: number,
    mobile: string,

    photo?: PDFImage | Uint8Array | ArrayBuffer | string, //base64

    tradeThroughOtherBroker?: boolean,
    tradeThroughOtherBroker_name?: string,
    brokerForOtherMarkets?: boolean,
    brokerForOtherMarkets_name?: string,
    relyOnFinancialConsultations?: boolean,

    investmentExperience?: string[],
    investmentObjectives?: string[],
    riskAppetite?: string[],
    requestedAssetClass?: string[],
    investedAmountOfWealth?: string,
    expectedVolume?: string,
    //for orders
    iban?: string,
    chosenFund?: string,

    memberOfBoard?: boolean,
    declarationInformation?: {
        position: string,
        entity: string,
        market: string,
    }[],

    tradeForBeneficiaries?: boolean,
    beneficiaryInformation?: {
        relation: string,
        name: string,
    }[],

    relativePoliticalPositionInformation?: {
        relation: string,
        name: string,
    }[],

    clientNature?: string[],  //I should type these since they are finite natures of clients.
    signature?: PDFImage | Uint8Array | ArrayBuffer | string, //base64
    amount?: number,
    units?: number,
    fundType?: SubscriptionType // ([ "subscribe"| "redeem"] ) ?
    // updateType?: string // (["submit" | "order"]) ?
    assisted_by?: string,
    state?: KYCState,
    sourceOfIncomeSentence?: string,   //This is to show something like these in certain closed ended funds: /* Investments in Funds with KFH.  Savings over the years from work.  Investments from the Kuwaiti Stock Market.  Inheritance from father from real estate activities.  End of Service Indemnity. 
    sourceOfWealthSentence?: string,   //This is to show something like these in certain closed ended funds: /* Investments in Funds with KFH.  Savings over the years from work.  Investments from the Kuwaiti Stock Market.  Inheritance from father from real estate activities.  End of Service Indemnity. 

    tradexSyncStatus?: {
        status: "pending" | "success" | "error",
        message?: string,
    }
    highriskOverrideReason?: string

}

export interface UpdateLinkCustomClasses {
    after_class: string,
    class_name: string,
}



export interface FirebaseCustomerBankAccount {
    iban?: string,
    account?: string,
    created?: Timestamp,
    type?: MessageType,
}

export interface UpdateOrderRequest extends FirebaseCustomerBankAccount {
    transferDate?: Date,
    slipFilePaths?: string[]


}
export interface AddAccountRequest extends FirebaseCustomerBankAccount {
    iban: string,
    id: string,
}

export interface Tin {
    country: string,
    available?: boolean
    number?: string
    type?: string

}

export interface Address {
    StreetName?: string,
    PaciBuildingNumber?: string
    Area?: string
    FloorNumber?: string
    Governerate?: string
    UnitType?: string
    BuildingNumber?: string
    UnitNumber?: string
    BlockNumber?: string
    DetailsEnglish?: string
    DetailsArabic?: string
}


//So this is really only used for the address from Kuwait Finder.
//I need it because the address from PACI is in Arabic, which won't work for closed ended funds.
export interface AddressFromFinder {
    ID: string,
    ResultSource: string,
    FeatureType: string,
    TitleArabic: string,
    TitleEnglish: string,
    DetailsArabic: string,
    DetailsEnglish: string,
    X: number,
    Y: number,
    Distance?: number,
    GovernorateArabic: string,
    GovernorateEnglish: string,
    NeighborhoodArabic: string,
    NeighborhoodEnglish: string,
    BlockArabic: string,
    BlockEnglish: string,
    StreetArabic: string,
    StreetEnglish: string,
    ParcelArabic: string,
    ParcelEnglish: string,
    HouseArabic: string,
    HouseEnglish: string,
    Unit?: null,
    CivilID: number

}

export type AdressKeys = keyof Address;



export interface PersonalData {

    NationalityAr: string,
    BloodGroup: string,
    Address?: Address,
    NationalityEn: string,
    Photo: string,
    Gender: string,
    DocumentNumber: string,
    EmailAddress: string,
    CivilID: string,
    MobileNumber: string,
    FullNameAr: string,
    CardExpiryDate: string,
    PassportNumber: string,
    GovData?: {
        SponsorName: string;
        CivilIdExpiryDate: string;
    },
    FullNameEn: string,
    BirthDate: string,

}

export interface PACIData {
    ResultDetails: {
        SignatureData: string,
        UserAction: string,
        SigningDatatype: string,
        UserCivilNo: string,
        UserCertificate: string,
        ResultCode: string,
        HashAlgorithm: string,
        TransactionDate: string,
    }

    PersonalData: PersonalData,
    Signature: {
        SignatureData: string,
        SigningCertificate: string,
    },
    RequestDetails: {
        AdditionalData: string,
        RequestID: string,
        ServiceProviderId: string,
        CivilNo: string,
        RequestType: string,
    }
}

export type MessageType = "submit" | "order" | "none" | "upload_docs" | "needs_update" | "broadcast" | "kfhtrade" | "kfhbrokerage" | "portfolio" | "agreement";;


export interface TwilioContentResponseType {

    "meta": {
        "page": number,
        "page_size": number,
        "first_page_url": string,
        "previous_page_url": string | null,
        "url": string,
        "next_page_url": string | null,
        "key": string
    },
    "contents": TwilioContentType[]

}

export interface TwilioContentType {




    language: string,
    date_updated: string,
    variables: {
        [key: string]: string,

    },
    friendly_name: string,
    account_sid: string,
    url: string,
    sid: string,
    date_created: string,
    types: {
        [key: string]: {
            body: string
        }
    },
    links: {
        approval_fetch: string,
        approval_create: string
    }




}
export interface UpdateLink {
    expires?: Timestamp,
    customerID: string,
    updateType: MessageType,
    id?: string,
    mobile?: string,
    passportRequired?: boolean,
    paymentSlipRequired?: boolean,
    usePACI?: boolean,
    useWhatsapp?: boolean,
    useEmail?: boolean,
    paciData?: PACIData,
    classes_to_add?: UpdateLinkCustomClasses[],
    uploaded_documents?: string[],
    selectedWhatsappTemplate?: TwilioContentType
    selectedEmailTemplate?: string | null,

    kyc?: KYC,  //this is the previous KYC data to show up pre-filled.
    filledData?: KYC,  //this is the data the customer is actually filling in right now.  Could be incomplete.
    //for orders
    orderID?: string,
    serviceID?: string,
    orderInfo?: Order,
    serviceInfo?: Service,
    currency?: string,
    skipKYC?: boolean,  //Sometimes orders don't need KYCs to be filled in (like redemptions), therefore just handling order data is enough.

    //for SMS
    senderID?: string,
    smsMessage?: string,
    smsDocID?: string,
    assisted_by?: string

    //other linkInfo data
    online?: boolean
    disableFundTypeChange?: boolean
    disableFundChange?: boolean
    disableFundAmountChange?: boolean
    showInherentanceDetails?: boolean
    professionalOnly?: boolean
    englishOnly?: boolean
    needsDocuments?: boolean
    multiples?: number
    documentsToInclude?: [{ [key: string]: any }]
    classes_to_remove?: string[]
    manual?: boolean,
    //TODO: Might need to rename this as not to conflict with the agreement object.
    agreements?: {
        checkboxes: {
            label_ar: string,
            label_en: string,
            id: string,
            link?: string

        }[]
    }
    createOnSubmit?: boolean

    needed_documents?: string[],
    agreement?: Agreement,
}

export interface PaciVerificationLink {
    created?: Timestamp,
    expires?: Timestamp,
    customer_id: string,
    request_type: PaciVerificationType,
    id?: string,
    paci_data?: PACIData,
    requested_PACI_at?: Timestamp,
    recieved_PACI_at?: Timestamp,
    is_verified?: boolean,
}

export type ActionType = "approve" | "reject" | "review" | "operations_approve" | "needs_update" | "update" | "authorized_signatory_approve";
export type ActionLevel = "normal" | "executive";
export type PaciVerificationType = "register" | "forgetPassword" | "KYC";

export interface FirebaseToken {
    identities: {
        [key: string]: any;
    };
    /**
     * The ID of the provider used to sign in the user.
     * One of `"anonymous"`, `"password"`, `"facebook.com"`, `"github.com"`,
     * `"google.com"`, `"twitter.com"`, `"apple.com"`, `"microsoft.com"`,
     * `"yahoo.com"`, `"phone"`, `"playgames.google.com"`, `"gc.apple.com"`,
     * or `"custom"`.
     *
     * Additional Identity Platform provider IDs include `"linkedin.com"`,
     * OIDC and SAML identity providers prefixed with `"saml."` and `"oidc."`
     * respectively.
     */
    sign_in_provider: string;
    /**
     * The type identifier or `factorId` of the second factor, provided the
     * ID token was obtained from a multi-factor authenticated user.
     * For phone, this is `"phone"`.
     */
    sign_in_second_factor?: string;
    /**
     * The `uid` of the second factor used to sign in, provided the
     * ID token was obtained from a multi-factor authenticated user.
     */
    second_factor_identifier?: string;
    /**
     * The ID of the tenant the user belongs to, if available.
     */
    tenant?: string;
    [key: string]: any;
}
export interface Approval {
    date: Timestamp,
    ip?: string,
    name: string,
    title?: string,
    type: ActionType
    level?: ActionLevel,
    info?: FirebaseToken,
    id?: string,
    comments?: string,
    missingFiles?: FileTag[],

}


export interface ProcessActionsDocument {
    approvals?: Approval[],
}

export interface Action {
    action: ActionType,
    token: string,
    comments?: string,
    //TODO: I should have something generic here on what item is being actioned on
    transactionID?: string,
    kycID?: string,
    customerID?: string,
    orderID?: string,

    //for SMS
    sendSMS?: boolean,
    useWhatsapp?: boolean,

    linkInfo?: Partial<UpdateLink>,
    missingFiles?: FileTag[],

}

//microsoft crap
export type ODataResponse<T> = {
    [key: string]: any;
    value: T[];
}


export type FundID = "901" | "902" | "500" | "201" | "200" | "Pfn6bBMWKckyFcr2EKPJ" | "usFund" | "XY3ENvdw0UEtjQgLeABZ";


export interface OrdersPath {
    metaKey?: string
    path: string;
    doNotFlatten: boolean;
    title: string;
    agreementType?: "voting" | "nonvoting";
    sourcesOfIncomeTransformers?: Record<string, string>
    sourcesOfWealthTransformers?: Record<string, string>
    addFields?: Record<string, string>
}




export type EditableComponentType = "MultiSelect" | "Select" | "TextInput" | "NumberInput"


export interface Fund {
    id: FundID,
    currency: string,
    name_en: string,
    name_ar: string,
    nav_per_unit?: number,
    nav_update_day_of_week?: number,
    nav_date?: Timestamp,
    updated_at: Timestamp,
    overhead_percent: number,
    min_units: number,
    subscription_fees_percent: number,
    // multiples_of?: number,
    multiples_of_units?: number,
    iban: string,
    new_nav_awaiting_approval?: {
        nav_per_unit: number,
        nav_date: Timestamp,
        updatedBy: string,
    }
    image?: any,
    englishOnly?: boolean
    passportRequired?: boolean
    professionalOnly?: boolean
    needsDocuments?: boolean  //this is for closed ended funds where they ask for source of income documents, etc.  Basically it'll treat everyone as High Risk.
    type: "closed_ended" | "open_ended"
    subscription_tiers?: SubscriptionTier[],
    fees_included_in_transferred_amount?: boolean,
    voting_units?: number,
    code?: string,
    issued_units?: number,
    net_assets?: number,
    returns_since_inception?: string
}

export interface SubscriptionTier {

    from: number,
    to: number,
    fees: number,

}
export interface Approvers {
    kyc_executive: string[],
    kyc_normal: string[],
}

export interface ApprovalData {
    id: string,
    name: string,
    info?: string,
    date?: string,
    level?: string,
    reasons?: string,
    comments?: string,
    active?: boolean,
    type?: "approve" | "reject" | "review" | "operations_approve" | "needs_update",
}

export interface Risk {
    countries: string[],
}
export interface Schedules {
    low_risk_expiry: number,
    high_risk_expiry: number,
    kyc_reminder_frequency?: number
    kyc_remind_before_expiry_by_days?: number
}

//OCR crap
export interface UsefulInfo {
    birthDate?: Date;
    civilID?: string;
    gender?: string;
    nationality?: string;
    houseID?: string | null;
    mobile?: string;
    civilIDExpiryDate?: Date;
    first?: string | null;
    last?: string | null;
    name_en?: string;

    //passport related crap
    passportExpiryDate?: Date;
    passportNumber?: string | null;
    personalNumber?: string | null;
    hasExpiryDate?: boolean;
    expiryDate?: string | null;

    //payment slip related data
    iban?: string | null;
    acct?: string | null;
    transferDate?: Date;
    amount?: string | null;
    currency?: string | null;
}

export interface OcrResult {
    mime?: MimeType | string;
    frontOfCivilID?: boolean;
    backOfCivilID?: boolean;
    passport?: boolean;
    paymentSlip?: boolean;
    usefulInfo?: UsefulInfo;

}


export interface ExtractedOCRData {
    [key: string]: any
}


export interface GenericObject {
    [key: string]: string
}



export interface GenevaRequest {
    portfolioCode?: string,
    portfolioCodes?: string[],
    investmentCode?: string,
    date?: string,
    custom_date?: boolean
}


export interface FileObj {
    name: any;
    link: any;
    metadataName?: string;
    created: Date;
    path?: string;

}


export interface MediaPDFFile {
    mime?: MimeType;
    type?: string;
    file?: any;  //TODO: This needs to be looked at.  It used to be File, but I had to switch it to any.  Seems like code expects string or array buffer.
}

export interface HistoryEntry {
    msg: string,
    details?: string,
    activeKYC?: string,
}

export interface SMSJobResult {
    messagesTotal: number,
    messagesSent: number,
    messagesFailed: number,
    smsSendingStatus: string,
    message: string,
    date: Timestamp,
    byID?: string,
    by?: string,
}

export interface Portfolio extends CustomAutocomplete {
    active?: boolean
    code?: string
    group?: string
    id?: string
    investmentPortfolio?: string
    key?: string
    name?: string
    parent_id?: string
    portfolioType?: string
    portfolioTypeID?: number  //145 is GWI.  149 is Nondiscretionary, etc.
    tradexID?: string  //this is the customer ID, not the portfolio ID.  I needed it for the tradex API.
    tradexPortfolioID?: number,
    position?: PositionDetails;
}

export interface Position {
    code?: string;
    quantity?: number;
    total_market_value?: number;
    total_cost?: number;
    bonus_shares_and_right_issues?: number;
    unrealized_gain_loss?: number;
    name?: string;
    average_cost?: number;
    sector?: string;
    cash?: boolean;
    gain_loss_percent?: number;
    unrealized_equity?: number;
    total_market_value_local?: number;
    total_unrealized_gain_loss_local?: number;
    unrealized_fx?: number;
    exchange?: string;
    currency?: string;
    total_cost_year_close_local?: number;
    portfolio_percent?: number;
}

export interface PositionDetails {
    unrealized_profit_loss?: number;
    fees?: string;
    cash_percent?: number;
    managed_by?: string;
    other_expenses?: number;
    type?: string;
    securities?: number;
    local_position?: Position[];
    foreign_position?: Position[];
    updated_at?: string;
    management_fee?: number;
    cash?: number;
    other_liabilities?: number;
    manager?: string;
    other_income?: number;
    created?: string;
    murabaha?: number;
    sukuk_profit_receivable?: number;
    cash_dividend_ytd?: number;
    dividend_due?: number;
    realized_gain_loss_ytd?: number;
    performance?: number;
    total_capital_market_value?: number;
    name?: string;
    net_income?: number;
    other_receivables?: number;
    status?: string;
}



export interface UserFileObjs extends MediaPDFFile {
    fileMetas: string[]
}




export interface CustodianAccount extends CustomAutocomplete {
    NAMELINE1: string,
    NAMESORT: string,
    _CUSTODIAN_CHAINID_: number | string,
    _CUSTODIAN_NAMESORT_: string,
    _UDF_CCY_: string,
    visible: boolean,
}
export interface FirebaseTransaction {
    active?: boolean,  //What is this?
    //this is for knowing which transcation was deleted .
    amount?: number;
    comments?: string,
    convertedAmount?: number,
    currency?: string,
    currencyFromValue?: string,
    currencyToValue?: string,
    // portfolioCode?: string
    custodianAccount?: CustodianAccount,
    newCustodianAccount?: CustodianAccount,
    portfolio?: Portfolio;
    transactionTimestamp?: string,  //this is a string because you can't send Date() through JSON.  Mantine object seems to be ok with it even though it's a date there O-o.
    customRate?: number,
    custom_date?: boolean,
    customerID?: string
    customerObj?: Customer
    email?: string
    margin?: boolean,
    id?: string
    mobile?: string
    name_ar?: string
    name_en?: string
    securities: SecurityItem[],  //TODO: OK we have a problem here.  If it's Security In, then it's a Security.  If it's secrity_out, then it's an Investment.  I need to unify them in the first call.
    position?: Investment[]
    rate?: number,
    transactionType: FiresotreTransactionType
    LocationAccount?: string,
    entriesList?: JournalEntry[],  //TODO: For journal entries.  Must use a proper type instead of any.
}

export interface TypesenseTransaction {
    amount?: number;
    type: string
    portfolioID: string;
    currency: string;
    custodianAccount: string;
    date: string;
    status: string;
    transactionID: string;
    customerID: string;
    id: string;
}

export type GenevaTransactionType = "SpotFX" | "ReceiveLong" | "Transfer" | "DeliverLong" | "Deposit" | "Withdraw"
export type FiresotreTransactionType = "security_out" | "security_in" | "cash_out" | "cash_in" | "fx" | "journal_entry"

export interface SecurityItem {
    price?: number,
    quantity: number,
    value?: BaseSecurity,
}


export interface JournalEntry {
    credit: number,
    debit: number,
    financialAccount: CustodianAccount,
}

export interface BaseSecurity {

    CODE: string,
    TICKER: string,
    EXCHANGE_CODE: string,
    EXCHANGE_DESCRIPTION: string,
    ISSUE_COUNTRY_CODE: string,
    _KEY_: string

    // ISSUE_COUNTRY_DESCRIPTION: string,

}


export interface Security extends BaseSecurity {
    // CHAINID: number,
    // CODE: string,
    // TICKER: string,
    _EXCHANGE_CHAINID_: number,
    // _EXCHANGE_NAMELINE1_: string,
    // _EXCHANGE_NAMESORT_: string,
    _ISSUECOUNTRY_CHAINID_: number,
    // _ISSUECOUNTRY_CODE_: string,
    _ISSUER_CHAINID_: number,
    _ISSUER_NAMESORT_: string,
    _UDF_ISLAMIC_OR_NOT_: "Yes" | "No",
}





export interface Investment extends BaseSecurity {
    COSTBOOKEND: number,
    COSTLOCALEND: number,
    CUSTODIANACCOUNT: string,
    CUSTODIANACCOUNTCURRENCY: string,
    MARKETVALUEBOOKEND: number,
    MARKETVALUELOCALEND: number,
    QTYEND: number,
    UNITCOSTBOOKEND: number,
    UNITCOSTLOCALEND: number,
    _DENOMINATION_CODE_: string,
    // _INVESTMENT_CODE_: string,  //CODE
    _INVESTMENT_DESCRIPTION_: string,
    // _INVESTMENT_EXCHANGE_DESCRIPTION_: string,  //EXCHANGE
    // _INVESTMENT_EXCHANGE_NAMESORT_: string,
    _INVESTMENT_INVESTMENTTYPE_CODE_: string,
    _INVESTMENT_INVESTMENTTYPE_DESCRIPTION_: string,
    // _INVESTMENT_ISSUECOUNTRY_CODE_: string,
    // _INVESTMENT_ISSUECOUNTRY_DESCRIPTION_: string,
    // _INVESTMENT_TICKER_: string,
}


export interface GenevaTransaction {
    LoaderAction: string
    LoaderType: GenevaTransactionType
    UserTranId1: string
    KeyValue: { [key: string]: any }
    TradexPortfolioId?: string
    Portfolio: string
    LocationAccount?: string
    CounterInvestment?: string
    EventDate: string
    SettleDate: string
    ActualSettleDate: string
    NetCounterAmount?: number
    Comments: string
    CounterFXDenomination?: string
    CounterTDateFx?: number
    FinAccount?: string
    UserDefField1?: string
    Price?: number
    Investment?: string
    Quantity?: number
    ReceiveDatePrice?: number
    TaxLotDate?: string
    TaxLotValue?: number
    ContractFxRate?: string
    ContractFxRateDenominator?: string
    ContractFxRateNumerator?: string
    NetInvestmentAmount?: string
    NewLocationAccount?: string,
    TransferType?: string
}



export interface CustomAutocomplete extends Omit<ComboboxItem, "label" | "value"> {
    id?: string,
    key?: string,
    text_l?: string,
    text_r?: string,
    label?: any,
    value: string,
    group?: string,  //for Select components.
}




export interface SMSResult {
    mobile: string,
    details: string,
    link?: string,
    code?: string,
    description?: string,
    msg?: string,
    status?: "error" | "success",
    statusCode?: string,
}

export interface RequestKYCUpdateRequest {
    updateLink: UpdateLink,
    customers: Customer[],
    // smsMessage?: string
    // updateType: MessageType,
    // senderID: string
    language?: string
}

export type AddOrderActions = "save_for_later" | "send_order_sms" | "manual" | "mobile_order";
export type AddServiceActions = "save_for_later" | "send_service_sms" | "manual";

export interface AddOrderRequest {
    updateLink: UpdateLink,
    order: Order,
    slipFilePaths?: string[],
    token?: string,
    onBehalfOf?: RMUser,  //the person who is actually credited for the order in case someone else is submitting.
    civilID?: string,   //for backward compatibility until everyone updates their page.
    id?: string,
    maxUnits?: boolean,
    action: AddOrderActions,
}

export interface AddServiceRequest {
    updateLink: UpdateLink,
    service: Service,
    token?: string,
    onBehalfOf?: RMUser,  //the person who is actually credited for the order in case someone else is submitting.
    civilID?: string,   //for backward compatibility until everyone updates their page.
    id?: string,
    action: AddServiceActions,
}


export interface DeleteFileRequest {

    token?: string,
    filepath?: string,
}


export interface SendOrderSMSRequest {
    smsDocID: string,
    senderID: string,
    order: Order,
    slipFilePaths?: string[],
    token?: string,
}



export interface SendServiceSMSRequest {
    smsDocID: string,
    service: Service,
    token?: string,
}

export interface SendAgreementSMSRequest {
    smsDocID: string,
    agreement: Agreement,
    token?: string,
}

export interface Agreement extends BasicProductAttributes {
    agreement_id: string,
    title_en: string,
    title_ar: string,
    content_en: string,
    content_ar: string,
    date?: Timestamp,
}

export interface AddKFHRmRequest extends RMUser {

    emailVerified?: boolean,
    phoneNumber?: string,
    password?: string,
    displayName?: string,
    photoURL?: string,
    disabled?: boolean,

}

export interface OrderRefreshRequest {
    orderID: string,
}


export type Metadata = Record<string, string | number>;

export interface UploadFileResult extends BaseResult {
    numFiles: number,
    filePaths: string[],
    google?: Record<string, Metadata>, //key is filename
    paddle?: Record<string, Metadata>, //key is filename
    info?: Record<string, any>, //key is filename  //TODO: REMOVE ANY AND USE UploadFileInfo
}

export interface UploadFileInfo {
    filepath: string,
    metadata: Metadata,
}


export interface BaseResult {
    status: "success" | "error",
    message?: string
}

export interface PACIRequestResult extends BaseResult {
    code?: string,
}
export interface PaciVerificationResult extends BaseResult {
    id?: string,
}

export interface AddOrderResult extends BaseResult {
    link?: string,
    orderId?: string,
}

export interface RmAuthResult extends BaseResult {
    twoFactorEnabled?: boolean,
    active?: boolean,
}

export interface RequestKYCUpdateResult extends BaseResult {
    smsResult?: PromiseSettledResult<SMSResult>[]  //We can get SMSResult from the promise by using .value, but typescript doesn't know?

}

export interface GenericMessageResult extends BaseResult {
    messageID?: string
    messageIDsList?: string[]
}

export interface MobileAppChangePasswordRequest {
    civilID?: string,
    linkId?: string,
    password?: string,
}


export interface OneTimeOverrideKYCRequest {
    customerID: string
    kycData: {
        birthDate?: string | Date
        civilIDExpiryDate?: string | Date
        passportExpiryDate?: string | Date
    }

}

export interface RiskOverrideRequest {
    customerID: string
    action: "add" | "remove"
    reason?: string
    targetRisk?: "high" | "low"
}



export interface RiskOverrideReasonRequest {
    kycID: string
    customerID: string
    action: "update" | "remove"
    reason?: string
    targetRisk?: "high" | "low"
}



export interface CommunicationsMessageRequest {
    title?: string,
    rmRecipients: string[],
    customerRecipients: string[],
    segmentsRecipients: string[],
    tagsRecipients: string[],
    smsMessage?: string,
    emailMessage?: string,
    emailSubject?: string,
    updateLink: Partial<UpdateLink>,
    selectedWhatsappTemplate?: TwilioContentType
    selectedEmailTemplate?: string | null,
    notificationTitle?: string | null,
    notificationBody?: string | null,
    notificationData?: NotificationData,
    notificationDataLink?: string,


    type: ("sms" | "email" | "whatsapp" | "customEmail" | "notification")[]
}
export interface CreateCampaignRequest {
    title?: string,
    rmRecipients: string[],
    customerRecipients: string[],
    segmentsRecipients: string[],
    tagsRecipients: string[],
    smsMessage?: string,
    emailMessage?: string,
    emailSubject?: string,
    updateLink: Partial<UpdateLink>,
    selectedWhatsappTemplate?: TwilioContentType
    selectedEmailTemplate?: string | null,
    notificationTitle?: string | null,
    notificationBody?: string | null,
    notificationData?: NotificationData,
    notificationDataLink?: string,


    type: ("sms" | "email" | "whatsapp" | "customEmail" | "notification")[]
}

export interface NotificationData {
    type: "link" | "fund" | "kyc";
    value: string;
}

export interface Campaign {
    id: string,
    title: string,
    created_by: string,
    created_by_id: string,
    created_on: Timestamp,
    run_by?: string,
    run_by_id?: string,
    run_on?: Timestamp,
    status: "waiting_for_approve" | "done"
    customer_ids: string[],
    tags: string[],
    custom_email?: CampaignCustomEmail,
    email?: CampaignEmail,
    whatsapp?: CampaignWhatsapp,
    sms?: CampaignSMS,
    notification?: CampaignNotification,
    customers?: string[]
}
export interface CustomerNotification {
    campaign_id: string,
    title: string,
    created_on: Timestamp,
    finished_on?: Timestamp,
    status: "running" | "done"
    custom_email?: CampaignCustomEmail,
    email?: CampaignEmail,
    whatsapp?: CampaignWhatsapp,
    sms?: CampaignSMS,
    notification?: CampaignNotification,
}
export interface CampaignCustomEmail extends CampaignTypeBase {
    subject: string,
    message: string,
}
export interface CampaignEmail extends CampaignTypeBase {
    template: string,
}
export interface CampaignWhatsapp extends CampaignTypeBase {
    template: TwilioContentType,
}
export interface CampaignSMS extends CampaignTypeBase {
    message: string,
}
export interface CampaignNotification extends CampaignTypeBase {
    title: string,
    body: string,
    data?: NotificationData,
}


export interface CampaignTypeBase {
    message_total: number,
    message_sent: number,
    message_failed: number,
    errors: CampaignError[],
}

export interface CampaignError {
    customer_id: string,
    message: string,
}

export interface CustomFileWithPath extends FileWithPath {
    name: string;
    // readonly path?: string;
    serverPath?: string;
    // readonly name?: string;
}

export interface OrderActionRequest extends Action {
    customerID: string
    orderID: string
}

export interface ServiceActionRequest extends Action {
    customerID: string
    serviceID: string
}


export interface CustomClaimsRequest {
    token?: string,
}

export interface CustomClaimsResult extends BaseResult {
    customClaims?: CustomClaims,
    customSearchKey?: string,
    rmsCollectionSearchKey?: string
}


export interface GenerateRMUpdateLinkRequest {
    linkID: string,
    token: string,
}

export type ClaimType = "cr" | "admin" | "rm" | "oper" | "exec" | "risk" | "sys" | "kfh_rm" | "kfh_oper";

export type CustomClaimsFlags = Partial<Record<ClaimType, boolean>>;

export interface CustomClaims extends CustomClaimsFlags {
    segment?: string,
    availableSegments?: SegmentType[]
}

export type UploadFunction = (filepaths: string[], fields: Record<string, any>) => Promise<any>;



export interface AddClientRequest {
    id: string,
    name_en: string,
    name_ar: string,
    mobile: string,
    email: string,
    civilID?: string,
    commercial_register?: string,
    nationality: string,
    gender: string,
    isUpdate?: boolean,
}


export interface FirebaseHistory {
    msg?: string
    details?: string
    [key: string]: any
}



export interface FundAutoComplete extends ComboboxProps, ComboboxItem {
    id: FundID,
    currency: string,
    name_en: string,
    name_ar: string,
    nav_per_unit?: number,
    date: Timestamp,
    overhead_percent: number,
    image?: string
    min_units: number,
    subscription_fees_percent: number,
    // multiples_of?: number,
    multiples_of_units?: number,
    iban: string,
    updated_at: Timestamp,
    type: "closed_ended" | "open_ended"
}

export interface MicrosoftUserAutoComplete extends MicrosoftGraph.User, Omit<CustomAutocomplete, "label"> {
    label?: string,
}

export interface RMUser {
    email: string,
    id?: string,
    name_en: string,
    name_ar: string,
    segment?: SegmentType,
    active?: boolean,
    rm_id?: string,
    mobile?: string,
    role?: "rm" | "operations",
    twoFactorEnabled?: boolean
    availableSegments?: SegmentType[]

}

// export interface CustomerAutoComplete extends Customer, CustomAutocomplete {

//     id?: string,
//     lastOperation?: LastOperation,
//     mobile: string,
//     name_en: string,
//     name_ar: string,
//     tags?: { [key: string]: Tag },

// }


export interface CustodianAccountWithBalance extends CustodianAccount {
    balance: number,
    unitCost: number,
}

export interface PDFDocumentsDataTypes {
    name?: "orders" | "kyc" | "customers" | "services"
    getCustomerDataKey?: "string"   //this is the key that contains the Customer object.  The reason it's not just civilID is because I also want the ID of the last KYC contained in the customer object.
    metaKey?: string,
    path?: string
    title?: string
    type?: "collection" | "collectionGroup"
    subCollection?: "order" | "kyc" | "customers" //eg : kyc
    subCollectionAccessorName?: string // eg : value would be 'civilID' so we can get the actual civil id value using data[subCollectionAccessorName] which results in "295111501482" (civil id used to access kyc)
    subCollectionAccessorValue?: string
    documentsToAddFunction?: (obj: GenericProduct) => OrdersPath[]

}


// export interface PDFDocumentsExportData {
//     metaKey?: string
//     doNotFlatten?: boolean
//     path: string
//     title: string
// }



// export type DocumentTypes = "passport" | "frontOfCivilID"


// export type DocumentMetaTypes = {
//     [key: string]: {
//         key: string;
//         name: string;
//         color: string;
//     };
// };

export type FileLink = {
    metadata: {
        key: string;
        name: string;
        color: string;
    };
};

export interface ChangeOrderRmRequest {

    orderId?: string,
    rmEmail?: string,
}

export interface AttachmentJSON {
    content: string;
    filename: string;
    type?: string;
    disposition?: string;
    content_id?: string;
}
export enum FeedbackType {
    complaint = "complaint",
    suggestion = "suggestion",
    bug = "bug",
    other = "other",
}
export interface Feedback {
    type: FeedbackType,
    body: string,
    image_id: string | null,
    email: string | null,
    status: "new" | "closed",
    created_on: Timestamp,
}

export type UpdateLinkType = "sms" | "manual" | "mobile";

export interface GeneralOrderRequest {
    orderId?: string
}
export interface GetNeededDocumentsResult extends BaseResult {
    neededDocuments: string[]
}

export interface NotificationResult {

    successTokens: string[],
    successCount: number,
    failureCount: number,
    failedTokens: string[],
    error?: string
}
export interface NotificationPreferences {
    funds: NotificationPreferencesValues,
    orders: NotificationPreferencesValues,
    statements: NotificationPreferencesValues,
    tokens: NotificationToken[],
    badge_count: number,
}

export interface NotificationPreferencesValues {
    email: boolean,
    inApp: boolean,
    sms: boolean,
}
export interface NotificationToken {
    created_at: Timestamp,
    expires_at: Timestamp,
    isIOS: boolean,
    token: string,
    uuid: string,
}
