import { IconSearch } from "@tabler/icons-react";
import { TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
interface CustomTextSearchFieldProps {
  tableKey: string;
  [x: string]: any;
  placeholder?: string
}
const CustomTextSearchField: React.FC<any> = (
  props: CustomTextSearchFieldProps
) => {
  const {
    customFilters,
    setCustomFilters,
    tableKey,
    storedSearchText,
    setStoredSearchText,
    placeholder,

    lowercase

  } = props;
  const TABLE_KEY = tableKey + "-searchText";

  const [textValue, setTextValue] = useState<string>(
    storedSearchText[TABLE_KEY] || ""
  );
  useEffect(() => {
    setCustomFilters({
      ...customFilters,
      [TABLE_KEY]: textValue,
    });

    setStoredSearchText({
      ...storedSearchText,
      [TABLE_KEY]: textValue,
    });
    // setReloadData(Math.random());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  const handleChange = (event: any) => {


    if (lowercase) { setTextValue(event.target.value.toLowerCase()); }


    else { setTextValue(event.target.value); }
  };
  return (
    <TextInput
      leftSection={<IconSearch size={14} />}
      label="Search"
      placeholder={placeholder || "Search"}
      defaultValue={textValue}
      // style={{ flex: 1 }}
      onChange={handleChange}
    />
  );
};

export default CustomTextSearchField;

