import { Button, Card, Divider, Grid } from '@mantine/core';
import { httpsCallable } from 'firebase/functions';

import { BaseResult } from '../../types';
import { useFunctions } from '../../helpers/firebaseContext';
import { useState } from 'react';

const RefreshDataSettings = () => {
    const functions = useFunctions();

    const [loading, setLoading] = useState({
        kyc: false,
        expirey: false,
        customers: false,
        orders: false,
        transactions: false,
        rms: false,
        thumbnails: false,


    })


    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>


            <Grid gutter={{ base: "xl", xs: "xl" }} >
                <Grid.Col span={4} >
                    <Button
                        loading={loading.kyc}
                        title='Manually refresh customers' onClick={async (e) => {
                            setLoading({ ...loading, kyc: true })

                            const manualRefreshAllKYCFunction = httpsCallable<void, BaseResult>(functions, "manualRefreshAllKYC");

                            //set type to submit because we want the user to create an entirely new KYC
                            await manualRefreshAllKYCFunction();
                            setLoading({ ...loading, kyc: false })

                        }}>Manually refresh customers KYCs (please don't run this)</Button>

                </Grid.Col>

                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.expirey}
                        title='Manually refresh customers' onClick={async (e) => {
                            setLoading({ ...loading, expirey: true })

                            const expiredKYCSMSCallKYCFunction = httpsCallable<void, BaseResult>(functions, "expiredKYCSMSCall");

                            await expiredKYCSMSCallKYCFunction();
                            setLoading({ ...loading, expirey: false })

                        }}>Manually run KYC expiry sms (please don't run this)</Button>

                </Grid.Col>
                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.customers}

                        title='Manually refresh customers'
                        onClick={async (e) => {
                            setLoading({ ...loading, customers: true })
                            const backfillCustomers = httpsCallable<void, BaseResult>(functions, "customersBackfill");

                            await backfillCustomers();
                            setLoading({ ...loading, customers: false })

                        }}>Manually backfill customers (please don't run this)</Button>

                </Grid.Col>
                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.orders}
                        title='Manually refresh orders' onClick={async (e) => {
                            setLoading({ ...loading, orders: true })

                            const backfillOrders = httpsCallable<void, BaseResult>(functions, "orderBackfill");

                            await backfillOrders();
                            setLoading({ ...loading, orders: false })


                        }}>Manually backfill orders (please don't run this)</Button>

                </Grid.Col>
                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.transactions}

                        title='Manually refresh transactions' onClick={async (e) => {
                            const backfillTransactions = httpsCallable<void, BaseResult>(functions, "transactionBackfill");
                            setLoading({ ...loading, transactions: true })

                            await backfillTransactions();
                            setLoading({ ...loading, transactions: false })


                        }}>Manually backfill transactions (please don't run this)</Button>

                </Grid.Col>
                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.rms}
                        title='Manually refresh Rms' onClick={async (e) => {
                            setLoading({ ...loading, rms: true })
                            const backfillRms = httpsCallable<void, BaseResult>(functions, "RmsBackfill");

                            await backfillRms();
                            setLoading({ ...loading, rms: false })

                        }}>Manually backfill Rms (please don't run this)</Button>

                </Grid.Col>
                <Divider />
                <Grid.Col span={4} >

                    <Button
                        loading={loading.thumbnails}
                        title='Manually generate thumbnails' onClick={async (e) => {
                            setLoading({ ...loading, thumbnails: true })
                            const generateThumbnailstoFiles = httpsCallable<void, BaseResult>(functions, "generateThumbnailstoFiles");

                            await generateThumbnailstoFiles();
                            setLoading({ ...loading, thumbnails: false })


                        }}>Manually generate thumbnails (please don't run this)</Button>

                </Grid.Col>
            </Grid>

        </Card >
    )
}

export default RefreshDataSettings