import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { BsPlusCircle } from "react-icons/bs";

import { Button, Card, Grid, Group, Space, ThemeIcon, Title, Tooltip, } from "@mantine/core";
// import KYCStatusFilter from "../../components/KYCStatusFilter";

import { Action, ActionType, BaseResult, CustomClaims, GenericProduct, Order, SubscriptionType, TypesenseOrder, } from "../../types";
import OrdersInstantSearch from "../../components/orders_instant_search";
import { IconEye } from "@tabler/icons-react";
import SendSMSModal from "../../components/send_sms_modal";
import GeneratePDFModal from "../../components/generate_pdf_modal";
import { ordersPath } from "../../helpers/orderFilesPath";
import GenerateCSVModal from "../../components/generate_csv_modal";
import { MyUserDataContext } from "../../contexts";
import CustomListActionsModal from "../../components/custom_actions_modal";
import { httpsCallable } from "firebase/functions";
import { RefreshButton, RefreshButtonRef } from "../../components/customer_instant_search";
import { useFunctions } from "../../helpers/firebaseContext";
import { DocTypes } from "../../components/file_card";



interface ActionsComponentProps {
  orderObj: Order;
}


export const RenderActions = ({ orderObj }: ActionsComponentProps) => {
  // if (orderObj.created && !(orderObj.created instanceof Timestamp)) {
  //   orderObj.created = new Timestamp(orderObj.created as any, 0);
  // }


  const navigate = useNavigate();

  return <Group>


    <ThemeIcon color="blue" variant="light" onClick={() => {
      navigate("/orders/" + orderObj.id, { state: orderObj })
    }}><IconEye size={16} /></ThemeIcon>


  </Group>

}
const SubscriptionAndRedemptionList = () => {

  const userContext = useContext(MyUserDataContext);
  let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

  const functions = useFunctions();

  const [selectedRows, setSelectedRows] = useState([]);
  const [SMSOpened, setSMSOpened] = useState(false);
  const [generatePDFOpen, setGeneratePDFOpen] = useState(false);
  const [generateCSVOpen, setGenerateCSVOpen] = useState(false);
  const [orderActionsOpen, setOrderActionsOpen] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();

  const { refreshOrdersIn } = location.state || {};


  const refreshRef = useRef<RefreshButtonRef | null>(null);

  const handleExternalRefresh = () => {
    if (refreshRef.current) {
      refreshRef.current.refresh();
    }
  };


  const orderAction = httpsCallable<Action, BaseResult>(functions, 'orderAction');



  useEffect(() => {
    if (refreshOrdersIn) {
      const timer = setTimeout(() => {
        // Action to perform after delay
        console.log('Refreshing orders...');
        handleExternalRefresh();
        // Place your code here to refresh orders or any other intended action
      }, refreshOrdersIn);

      // Clean up the timer when the component unmounts or if the value of refreshOrdersIn changes
      return () => clearTimeout(timer);
    }
  }, [refreshOrdersIn]); // Dependency array, re-run effect if refreshOrdersIn changes



  const searchableOrders = useMemo(() =>
    <OrdersInstantSearch
      setSelectedRows={setSelectedRows}
      selectable={true}
      selectedRows={selectedRows}
      renderTags={true}
      navigateDetails={(obj: any) =>
        navigate("/orders/" + obj.id)
      }
      ActionsComponent={RenderActions}
      indexName={process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!}

      additionalChildren={<RefreshButton ref={refreshRef} />}

      topElements={<Group>

        {/* <Button c="orange" loading={approvingAll} onClick={() => {setApprovingAll(true); console.log("ey");}}>Approve all</Button> */}
        <Button
          color="yellow"
          disabled={selectedRows.length === 0}
          onClick={() => setGeneratePDFOpen(true)}
        >
          {`Generate Order forms for ${selectedRows.length} order(s)`}
        </Button>
        {/* <Button
        color="grape"
        disabled={selectedRows.length === 0}
        onClick={() => setOrderActionsOpen(true)}
      >
        {`Change Order status for ${selectedRows.length} order(s)`}
      </Button> */}

        {(userContext.msal || userClaims.kfh_oper) &&
          <Button
            color="teal"
            onClick={() => setGenerateCSVOpen(true)}
          >
            {`Generate CSV`}
          </Button>
        }

      </Group>}


    />,
    // eslint-disable-next-line 
    [selectedRows]);

  const customerModals = useMemo(() => <>
    <SendSMSModal
      rows={selectedRows}
      opened={SMSOpened}
      setOpened={setSMSOpened}
    />
    <GeneratePDFModal
      rows={selectedRows}
      // generatedType={"order"}
      metaTypes={Object.values(DocTypes)}
      opened={generatePDFOpen}
      getCustomerDataKey={"customerObj"}
      documentsToFetch={
        [{
          name: "orders",
          subCollectionAccessorName: "id",
          documentsToAddFunction: (orderObj: GenericProduct) => {
            return (orderObj?.type && orderObj?.fund) ? ordersPath[orderObj?.type as SubscriptionType][orderObj.fund.id] : []
          },
          type: "collectionGroup"

        }]
      }
      setOpened={setGeneratePDFOpen}
    />
    <CustomListActionsModal<TypesenseOrder>
      rows={selectedRows}

      opened={orderActionsOpen}
      setOpened={setOrderActionsOpen}
      availableActions={[
        {
          value: "review",
          label: "Review"
        },
        {
          value: "approve",
          label: "Approve"
        },
        {
          value: "operations_approve",
          label: "Operations Approve"
        },
      ]
      }
      handleSubmit={
        (order: TypesenseOrder, action: string, token?: string) => orderAction({ action: action as ActionType, orderID: order.id, customerID: order?.customerID, token: token! })
      }
      renderErrors={(failedOrder: TypesenseOrder) =>
        <>
          {failedOrder.customerObj?.name_en}-
          {failedOrder.units}-
          {failedOrder.fund?.name_en}

          <Space h="xl" />

        </>
      }
      getItemsData={

        (action: string) => {


          let data: TypesenseOrder[] = []


          if (action === "review")
            data = selectedRows.filter((order: TypesenseOrder) => {
              return order?.tags?.orderStatus.code === "review" || order?.tags?.orderStatus.code === "client_update"
            })


          if (action === "approve")
            data = selectedRows.filter((order: TypesenseOrder) => order?.tags?.orderStatus.code === "approval" || order?.tags?.orderStatus.code === "exec_approval")


          if (action === "operations_approve")
            data = selectedRows.filter((order: TypesenseOrder) => order?.tags?.orderStatus.code === "processing")

          return data

        }
      }
    />



    <GenerateCSVModal
      opened={generateCSVOpen}
      setOpened={setGenerateCSVOpen}
    />

  </>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, SMSOpened, generatePDFOpen, orderActionsOpen, generateCSVOpen])


  return (
    <>
      <Group justify="space-between">
        <Tooltip label={`indexName=${process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION}`}><Title>Orders</Title></Tooltip>
        <Button
          leftSection={<BsPlusCircle />}
          onClick={() => {
            navigate("/add_order");
          }}
        >
          {" "}
          Add order{" "}
        </Button>
      </Group>
      <Space h="xl" />
      {/* <Card shadow="sm" p="lg" radius="md" withBorder> */}
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Grid justify="space-between">
          <Grid.Col>

          </Grid.Col>
          <Grid.Col>
            {searchableOrders}
          </Grid.Col>
        </Grid>
      </Card>
      {customerModals}

      {/* </Card> */}
      {/* </Col>
            </Row> */}
    </>
  );
};


const Orders = () => {
  return (
    <main style={{ padding: "1rem 0" }}>
      <SubscriptionAndRedemptionList />

    </main>
  );
};

export default Orders;
