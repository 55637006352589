import React, { useEffect, useState } from "react";

import { Card, Text, Container } from "@mantine/core";
import Flow from "../../components/flow";
import { DocumentData, collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { ReactFlowProvider } from "reactflow";
import { useFirestore } from "../../helpers/firebaseContext";


export default function FlowEditor() {

    const db = useFirestore();
    const [flows, setFlows] = useState<DocumentData[]>([]);

    //ok let's get the template data and then we can start editing it
    useEffect(() => {
        const customerQuery = query(
            collection(db, "approval_templates")
        );

        return onSnapshot(customerQuery, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.docs.length);
                // return;
            } else {
                console.log("Server data", snapshot.docs.length);
            }

            let rows = snapshot.docs.map((doc) => {
                var data = doc.data();
                data["id"] = doc.id;
                return data;
            });

            setFlows(rows[0]?.steps);
            console.log(rows);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const save = async (obj: any) => {

        //save to firestore
        console.log(obj);
        const docRef = doc(db, 'approval_templates', 'kyc');
        await setDoc(docRef, { steps: obj }, { merge: true });



    }



    return (
        <Container size="xl" px="xs"  >


            <Card shadow="sm" p="lg" radius="md" px="xs" style={{ height: '80vh' }} withBorder>
                <Text p="lg">We are editing a flow!</Text>
                <ReactFlowProvider>


                    <Flow flows={flows} save={save} />
                </ReactFlowProvider>

            </Card>


        </Container>
    );
}
