import { Autocomplete, Grid, NumberInput, } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../contexts';
import BigNumber from "bignumber.js";

const ExchangeRates = ({ form, disabled }: any) => {
  // const [formData, setFormData] = useState({
  //   ...form?.values,
  //   currencyToValue: currencyTo || "KWD",
  //   currencyFromValue: currencyFrom || "KWD",
  //   convertedAmount: form.values.amount

  // })



  // const [currencyToValue, setCurrencyToValue] = useState<any>(form.values.currencyToValue);
  // const [currencyFromValue, setCurrencyFromValue] = useState<any>(form.values.currencyFromValue);
  const configContext = useContext(ConfigContext);

  useEffect(() => {

    if (disabled) return;
    console.log("Currency changed!");

    let rateUpdate = 0
    if (!form.values.currencyFromValue || (!configContext!.exchange_rates?.rates[form.values.currencyFromValue] && form.values.currencyFromValue !== "KWD")) {
      rateUpdate = 0
    }
    else if (form.values.currencyFromValue === form.values.currencyToValue) {
      rateUpdate = 1
    }
    else {
      if (form.values.currencyFromValue === "KWD") {
        // configContext!.exchange_rates?.rates[formData.currencyToValue]
        rateUpdate = 1 / (parseFloat(configContext!.exchange_rates?.rates[form.values.currencyToValue]))
      }
      else {
        rateUpdate = (parseFloat(configContext!.exchange_rates?.rates[form.values.currencyFromValue]))
      }


    }


    form.setFieldValue("rate", rateUpdate);
    // setCurrencyToValue(form.values.currencyToValue)
    // setCurrencyFromValue(formData.currencyFromValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.currencyFromValue, form.values.currencyToValue])




  useEffect(() => {
    if (disabled) return;

    console.log("Rate or amount changed!");
    if (form.values.rate) {

      let amount = new BigNumber(form.values.amount);
      // console.log("We multiplied the amount by the rate!", amount.toNumber(), formData.rate);
      amount = amount.multipliedBy(form.values.rate);

      let converted = amount.toNumber();
      form?.setValues((prev: any) => ({ ...prev, convertedAmount: converted }))

      // setFormData({ ...formData, convertedAmount: converted })
    }
    else {
      // setFormData({ ...formData, convertedAmount: 0 })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.rate, form.values.amount])



  let [currenciesAvailable, setCurrenciesAvailable] = useState<any>([]);
  useEffect(() => {

    setCurrenciesAvailable(form.values.position?.filter((position: any) => position._INVESTMENT_INVESTMENTTYPE_CODE_ === "CASH").map((cash: any) => {

      let currencyCode = cash._INVESTMENT_CODE_;

      let rate = configContext!.exchange_rates?.rates[currencyCode] || "1";

      let data = {} as any;

      data["text_l"] = currencyCode;
      data["text_r"] = `Balance: ${cash.QTYEND.toFixed(3)}`
      data["value"] = currencyCode;
      data["key"] = currencyCode;
      data["id"] = currencyCode;
      data["rate"] = rate;


      return data;
    }) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.position])


  return (
    <>

      <Grid.Col span={{ xs: 3, lg: 3 }}>
        <NumberInput
          disabled={disabled}
          decimalScale={3}
          // withAsterisk
          // required
          label={`Amount in ${form.values.currencyFromValue}`}
          min={0}
          placeholder="amount"
          parser={(value: string) => value.replace(new RegExp(`(,*)`, "g"), '')}

          formatter={(value: string) =>
            !Number.isNaN(parseFloat(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : ''
          }

          {...form.getInputProps("amount")}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 1, lg: 1 }}>
        <Autocomplete
          comboboxProps={{ withinPortal: true }}
          disabled={true}

          // required
          limit={100}
          value={form.values.currencyFromValue}
          // itemComponent={AutoCompleteItem}
          // onChange={setCurrencyFromValue}
          onOptionSubmit={(itemValue) => {
            const item = currenciesAvailable.find((itemObj: { value: string; }) =>
              itemObj.value === itemValue
            )
            if (item) {
              let valuesToUpdate: any = {
                currencyFromValue: item.value,
              }
              if (item.value !== "KWD") {
                valuesToUpdate = { ...valuesToUpdate, "currencyToValue": "KWD" }
              }
              // setFormData({ ...formData, ...valuesToUpdate })
              form?.setValues((prev: any) => ({ ...prev, ...valuesToUpdate }))
              // form?.setFieldValue("customRate", formData.rate)
              // console.log(formData);}
            }
          }}

          label="From"
          data={currenciesAvailable || []}
        // dropdownComponent={({ children }: any) => <ScrollArea
        //   h={200}
        //   w={"100%"}

        // >
        //   {children}

        // </ScrollArea>}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 1, lg: 1 }}>
        <Autocomplete
          comboboxProps={{ withinPortal: true }}
          // required
          disabled={true}

          limit={100}
          value={form.values.currencyToValue}
          // disabled={currencyTo}

          // onChange={setCurrencyToValue}
          // onChange={(value) =>    setFormData({ ...formData, currencyToValue: value })}
          onOptionSubmit={(itemValue) => {
            const item = { value: itemValue }
            let valuesToUpdate: any = {
              currencyToValue: item.value,
            }
            if (item.value !== "KWD") {
              valuesToUpdate = { ...valuesToUpdate, "currencyFromValue": "KWD" }
            }
            // setFormData({ ...formData, ...valuesToUpdate })
            form?.setValues((prev: any) => ({ ...prev, ...valuesToUpdate }))
            // form?.setFieldValue("customRate", formData.rate)
            // console.log(formData);

          }}

          label="To"
          data={[...Object.keys(configContext!.exchange_rates?.rates || {}), "KWD"]}
        // dropdownComponent={({ children }: any) => <ScrollArea
        //   h={200}
        //   w={"100%"}
        // >
        //   {children}

        // </ScrollArea>}
        />
      </Grid.Col>

      <Grid.Col span={{ lg: 2, xs: 2 }} >
        <NumberInput
          disabled={disabled}

          label="Rate"
          min={0}
          value={form.values.rate}
          onChange={(value) => {

            // console.log("CUSTOMM     ---------->>>>", value);

            // setFormData({ ...formData, rate: value })
            form?.setFieldValue("rate", value);

          }}
          // disabled
          decimalScale={4}
        />
        {/* Actual Rate : {formData?.rate} */}
      </Grid.Col>
      <Grid.Col span={{ xs: 3, lg: 3 }}>
        <NumberInput
          label={`Amount in ${form.values.currencyToValue}`}
          min={0}
          disabled={disabled}

          value={form.values.convertedAmount}
          // parser={(value: string) => value.replace(new RegExp(`(,*)`, "g"), '')}

          // formatter={(value: string) =>
          //   !Number.isNaN(parseFloat(value))
          //     ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          //     : ''
          // }

          // disabled
          onChange={(value) => {

            //recalculate the rate
            let amount = new BigNumber(value);
            amount = amount.dividedBy(form.values.amount);
            let rate = amount.toNumber();



            // setFormData({ ...formData, convertedAmount: value, rate: rate })
            form?.setValues((prev: any) => ({ ...prev, convertedAmount: value, rate: rate }))

          }}
          decimalScale={3}

        />
        {/* Actual Amount in {formData.currencyFromValue} : {form.values.amount} */}
      </Grid.Col>
      <Grid.Col span={{ lg: 2, xs: 2 }} >
      </Grid.Col>
      {/* 
      <Grid.Col lg={12} xs={12} >
                <CashAvailable form={form} />
            </Grid.Col> */}
      {/* <Grid.Col span={{ xs: 12, lg: 6 }}>
        <TextInput
          disabled

        />
      </Grid.Col> */}
    </>
  )
}

export default ExchangeRates