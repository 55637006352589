import fontkit from "@pdf-lib/fontkit";
import { PDFDocument } from "pdf-lib";
import { decode } from 'base64-arraybuffer';
import { Order } from "../types";

export const makeOrderPDF = async (data: any, kycData: any, setPending: any, context: any, fund: string, orderType: string, links: any[], actionsAfterRejection: any) => {
    setPending(true);

    //download proper subscription form for that fund
    const arrayBuffer = await fetch(`/${fund}_${orderType}.pdf`).then(res => res.arrayBuffer())




    console.log("Downloaded!");
    const pdfDoc = await PDFDocument.load(arrayBuffer)

    console.log("Loaded!", pdfDoc);


    const fontBytes = await fetch("https://storage.googleapis.com/public-pdfs/Arial.ttf").then(res => res.arrayBuffer())

    let customFont: any;
    if (fontBytes) {
        pdfDoc.registerFontkit(fontkit);
        // await pdfDoc.embedFont(fontBytes);
        customFont = await pdfDoc.embedFont(fontBytes);

    }

    console.log(data);
    //convert data.signature from base64 to arrayBuffer
    const signature = kycData?.signature ? await pdfDoc.embedPng(decode(kycData?.signature)) : null;

    const form = pdfDoc.getForm()

    //reviewed and approved by
    if (actionsAfterRejection) {
        //loop
        let approve = [];
        let review = "";
        for (let i = 0; i < actionsAfterRejection.length; i++) {
            let action = actionsAfterRejection[i];
            if (action.type === "approve") {
                approve.push(action.name);
            }
            if (action.type === "review") {
                review += action.name + "\n";
            }
        }
        data.reviewed = review;
        data.approved = approve.join("\n");
        console.log(review, approve);
    }

    //loop through data
    for (const [key, value] of Object.entries(data)) {
        //fill in field keys with values


        if (key === "signature") {
            let field = form.getButton(key);
            if (signature)
                field.setImage(signature);
        }
        else {
            console.log(key, value);

            //is value an array?
            if (Array.isArray(value)) {
                //loop through array
                for (const [index, item] of (value as any).entries()) {
                    //loop thorugh item as an object
                    let fieldName = `${key}_${item}`;
                    console.log(fieldName);

                    if (form.getFieldMaybe(fieldName)) {
                        console.log("Checking array", `${key}_${item}`);
                        let field = form.getCheckBox(`${key}_${item}`);
                        field.check();
                        if (field.isChecked()) console.log('check box is selected')
                    }
                    else {
                        for (const [subKey, subValue] of Object.entries(item)) {
                            let fieldName = `${key}_${subKey}_${index}`;
                            if (!form.getFieldMaybe(fieldName)) continue;  //some fields might not exist, it's ok to skip over them

                            let field = form.getTextField(fieldName);

                            field.setText(subValue as string);
                        }
                    }

                }
                continue;
            }


            if (form.getFieldMaybe(key)) {
                let field = form.getTextField(key);

                //check if value is a date
                if (value instanceof Object) {
                    let obj: any = value as Object;
                    //is it a date object?
                    if (obj.seconds) {
                        //set field text to date
                        field.setText(new Date(obj.seconds * 1000).toLocaleDateString("en-gb"));
                    }
                }
                else {
                    let str = (value as string).toString();
                    // let page = pdfDoc.getPages().find(x => x.ref === field.acroField.getWidgets()[0].P());

                    // const widgets = field.acroField.getWidgets();
                    // let lastRect = widgets[widgets.length - 1].getRectangle();

                    // widgets.forEach((w) => {
                    //     const rect = w.getRectangle();
                    //     console.log(rect);
                    //   });

                    // page!.drawText(str, {
                    //     x: lastRect.x,
                    //     y: lastRect.y,
                    //     // size: 15,
                    //     // font: timesRomanFont,
                    //     // color: rgb(0, 0.53, 0.71),
                    //   })


                    field.setText(str);
                }
            }
            else {
                let fieldName = `${key}_${value}`;

                if (!form.getFieldMaybe(fieldName)) {
                    console.log("PLEASE CHECK!  Field not found", fieldName);
                }
                else {

                    let field = form.getCheckBox(fieldName);
                    field.check();
                }
            }
        }
    }


    form.updateFieldAppearances(customFont);




    //search links array for payment slip if subscription
    if (orderType === "subscribe") {
        for (const paymentSlip of links) {


            if (paymentSlip.metadata?.customMetadata.paymentSlip === "true") {
                //add page to pdf
                console.log(paymentSlip);
                const page = pdfDoc.addPage();
                //add image to page
                let image = null;

                if (paymentSlip.metadata.contentType === "image/jpeg") {
                    image = await pdfDoc.embedJpg(await fetch(paymentSlip.link).then(res => res.arrayBuffer()));
                }
                else if (paymentSlip.metadata.contentType === "image/png") {
                    image = await pdfDoc.embedPng(await fetch(paymentSlip.link).then(res => res.arrayBuffer()));
                }
                page.drawImage(image!, {
                    x: 50,
                    y: 50,
                    width: page.getWidth() - 100,
                    height: page.getHeight() - 100,
                });
            }
        }
    }

    //TODO: Civil ID somehow?
    //search KYC for civil ID I guess?  What's going to happen when we use Mobile ID?  There's no ID to attach.
    //Let me discuss this with the team first



    const pdfBytes = await pdfDoc.save()

    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
    const pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl);



    //clear all fields for next generate if needed
    form.getFields().forEach(field => {
        let name = field.getName();

        try {
            let checkbox = form.getCheckBox(name);
            checkbox.check();
        } catch (error) {
            try {
                let textField = form.getTextField(name);
                textField.setText("heeey");
            } catch (error) {

            }
        }

    });


    setPending(false);

}


export const calc = (order: Order, units?: number, amount?: number, fees_percent?: number, overhead_percent?: number,): { amt?: number, fees?: number, } => {

    let nav = order.fund?.nav_per_unit || 0;

    fees_percent = fees_percent || 0;
    overhead_percent = overhead_percent || 0;
    if (order.type === "redemption") {
        //no overhead for redeem
        overhead_percent = 0;
    }

    if (units) {
        let amt = nav * units;
        let fees = (amt * fees_percent) / 100;
        let overhead = (amt * overhead_percent) / 100;

        amt = amt + fees + overhead;
        // console.trace(fees_percent, overhead_percent, amt, fees, overhead)
        return { amt, fees, };
        // return amt + fees + overhead;
    } else if (amount) {
        let fees = (amount * fees_percent) / 100;
        let overhead = (amount * overhead_percent) / 100;
        let amt = (amount - fees - overhead) / nav;
        amt = Math.floor(amt);

        return { amt, fees, };
        // return (amount - fees - overhead) / nav;
    }

    return {};

}