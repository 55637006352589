import { NumberInput, NumberInputProps, Text } from '@mantine/core';

interface CurrencyInputProps extends NumberInputProps {
    decimals?: number;
    currency?: string;
}


export function CurrencyInput({ decimals = 3, currency = 'KWD', ...props }: CurrencyInputProps) {
    return (
        <NumberInput
            {...props}
            decimalScale={decimals}
            // fixedDecimalScale
            // thousandsSeparator=","
            rightSection={<Text miw={80} mr={1}>{currency}</Text>}
        // rightSectionPointerEvents="none"

        />
    );
}