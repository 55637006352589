import React, { useEffect, useState } from "react";
import { collection, getDocs, query, QueryDocumentSnapshot } from "firebase/firestore";
import { Table, Button, Loader, Group, Title, Space, Tooltip, Text, useMantineTheme, Badge } from "@mantine/core";
import { BaseResult, Campaign } from "../../types";
import { useFirestore, useFunctions } from "../../helpers/firebaseContext";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useFirestoreCollection from "../../helpers/useFirestoreCollection";
import CustomDataTable from "../../components/custom_data_table";
import { httpsCallable } from "firebase/functions";



const Campaigns: React.FC = () => {
  // const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);

  const db = useFirestore();
  const navigate = useNavigate();
  const TABLE_KEY = "campaigns";
  const functions = useFunctions();
  const [loadingId, setLoadingId] = useState("");



  const campaignsQuery = query(collection(db, "campaigns"));
  let campaignsData: Campaign[] = [];
  const { loading: campaignsLoading, data: data } = useFirestoreCollection(campaignsQuery);
  if (data && data.length > 0) {
    campaignsData = data as Campaign[];
  }


  const theme = useMantineTheme();

  const columns: any[] = [
    {
      name: "Title",
      selector: (row: QueryDocumentSnapshot<Campaign>) => row.data().title,
    },
    {
      name: "Types",
      wrap: true,
      selector: (row: QueryDocumentSnapshot<Campaign>) => {
        //loop through tags and create a badge for each
        let typesList = [];
        if (row.data().email) {
          typesList.push(<Badge size="xs" variant="filled" color={"gray"}  >Email</Badge>);
        }
        if (row.data().custom_email) {
          typesList.push(<Badge size="xs" variant="filled" color={"blue"}  >Custom Email</Badge>);
        }
        if (row.data().sms) {
          typesList.push(<Badge size="xs" variant="filled" color={"pink"}  >SMS</Badge>);
        }
        if (row.data().whatsapp) {
          typesList.push(<Badge size="xs" variant="filled" color={"green"}  >Whatsapp</Badge>);
        }
        if (row.data().notification) {
          typesList.push(<Badge size="xs" variant="filled" color={"yellow"}  >Notification</Badge>);
        }

        return <>{typesList}</>;
      },
    },
    {
      name: "Status",
      selector: (row: QueryDocumentSnapshot<Campaign>) => {
        const status = row.data().status;
        let badge: JSX.Element = <></>;
        if (status == "waiting_for_approve") {
          badge = <Badge size="xs" variant="filled" color={"gray"}  >Waiting for approval</Badge>
        } else if (status == "done") {
          badge = <Badge size="xs" variant="filled" color={"green"}  >Done</Badge>
        }
        // else if (status == "runing") {
        //   badge =  <Badge size="xs" variant="filled" color={"blue"} leftSection={<Loader size="xs" type="dots" color="white"></Loader>} >Running</Badge>
        // }
        if (badge != <></>) {
          // return <Tooltip label={buildCounters(row.data())}>{badge}</Tooltip>
          return badge;
        } else {
          return badge;
        }
      },
    },
    {
      name: "Created Date",
      selector: (row: QueryDocumentSnapshot<Campaign>) => {

        if (row.data().created_on) {
          let orderCreatedByText = "Created By " + row.data().created_by;
          return <Tooltip label={orderCreatedByText}><span>{row.data().created_on.toDate().toLocaleString("en-gb")}</span></Tooltip>
        } else {
          return "-"
        }
      },
    },
    {
      name: "Run Date",
      selector: (row: QueryDocumentSnapshot<Campaign>) => {

        if (row.data().run_on) {
          let runByText = "Run By " + row.data().run_by;
          return <Tooltip label={runByText}><span>{row.data().run_on!.toDate().toLocaleString("en-gb")}</span></Tooltip>
        } else {
          return "-"
        }
      },
    },
    {
      name: "Sent Count",
      selector: (row: QueryDocumentSnapshot<Campaign>) => buildCounters(row.data()),
    },
    {
      name: 'Actions',
      selector: (row: QueryDocumentSnapshot<Campaign>) => <Button fullWidth mt={5}
        onClick={() => runCampaign(row.data().id)}
        variant='outline'
        color={"blue"}
        loading={loadingId == row.data().id}
        disabled={row.data().status != "waiting_for_approve" || loadingId != ''}
      >
        Send
      </Button>,
      grow: 1,
    },

  ];

  //TODO: create the function in cloud functions
  async function runCampaign(id: string) {
    try {
      setLoadingId(id);
      const runCampaign = httpsCallable<string, BaseResult>(functions, "runCampaign");
      const response = await runCampaign(id);
      setLoadingId('');
    } catch (error) {
      setLoadingId('');
    }

  }



  function buildCounters(campaign: Campaign) {
    const rows: JSX.Element[] = [];
    let totalSum = 0;
    let sentSum = 0;
    let FailedSum = 0;

    if (campaign.email) {
      totalSum += campaign.email.message_total;
      sentSum += campaign.email.message_sent;
      FailedSum += campaign.email.message_failed;
      rows.push(
        <tr key="email">
          <td>Email</td>
          <td>{campaign.email.message_total}</td>
          <td>{campaign.email.message_sent}</td>
          <td>{campaign.email.message_failed}</td>
        </tr>
      );
    }
    if (campaign.whatsapp) {
      totalSum += campaign.whatsapp.message_total;
      sentSum += campaign.whatsapp.message_sent;
      FailedSum += campaign.whatsapp.message_failed;
      rows.push(
        <tr key="whatsapp">
          <td>Whatsapp</td>
          <td>{campaign.whatsapp.message_total}</td>
          <td>{campaign.whatsapp.message_sent}</td>
          <td>{campaign.whatsapp.message_failed}</td>
        </tr>
      );
    }
    if (campaign.sms) {
      totalSum += campaign.sms.message_total;
      sentSum += campaign.sms.message_sent;
      FailedSum += campaign.sms.message_failed;
      rows.push(
        <tr key="sms">
          <td>SMS</td>
          <td>{campaign.sms.message_total}</td>
          <td>{campaign.sms.message_sent}</td>
          <td>{campaign.sms.message_failed}</td>
        </tr>
      );
    }
    if (campaign.custom_email) {
      totalSum += campaign.custom_email.message_total;
      sentSum += campaign.custom_email.message_sent;
      FailedSum += campaign.custom_email.message_failed;
      rows.push(
        <tr key="custom_email">
          <td>Custom Email</td>
          <td>{campaign.custom_email.message_total}</td>
          <td>{campaign.custom_email.message_sent}</td>
          <td>{campaign.custom_email.message_failed}</td>
        </tr>
      );
    }
    if (campaign.notification) {
      totalSum += campaign.notification.message_total;
      sentSum += campaign.notification.message_sent;
      FailedSum += campaign.notification.message_failed;
      rows.push(
        <tr key="notification">
          <td>Notification</td>
          <td>{campaign.notification.message_total}</td>
          <td>{campaign.notification.message_sent}</td>
          <td>{campaign.notification.message_failed}</td>
        </tr>
      );
    }

    rows.push(
      <tr key="total">
        <td>Total</td>
        <td>{totalSum}</td>
        <td>{sentSum}</td>
        <td>{FailedSum}</td>
      </tr>
    );

    let table = <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Total</th>
          <th>Sent</th>
          <th>Failed</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>

    return <Tooltip label={table}><span>{sentSum + "/" + totalSum}</span></Tooltip>;
  }

  if (campaignsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Group justify="space-between">
        <Title>Campagins</Title>
        <Button
          leftSection={<BsPlusCircle />}
          onClick={() => {
            navigate("/campaigns/create");
          }}
        >
          {" "}
          Create Campaign{" "}
        </Button>
      </Group>

      <Space h="xl" />
      {/* <Table striped highlightOnHover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Created By</th>
            <th>Counters</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {(campaignsData as Campaign[]).map((campaign) => (
            <tr key={campaign.id}>
              <td>{campaign.id}</td>
              <td>{campaign.created_by}</td>
              <td><Tooltip label={buildCounters(campaign)}><Text>hello</Text></Tooltip></td>
              <td>{campaign.status}</td>
              <td>
                <Button
                  variant="outline"
                  onClick={() => handleActionClick(campaign.id)}
                >
                  Run
                </Button>
              </td>
            </tr>
          ))}

        </tbody>
      </Table> */}
      <CustomDataTable
        orderByAttribute="created_on"
        tableKey={TABLE_KEY}
        // title="Campaigns"
        columns={columns}
        // customFilters={customFilters}
        // customQueries={customQueries}

        paginationComponentOptions={{
          selectAllRowsItem: true,
          selectAllRowsItemText: "All",
        }}
        selectableRows={false}
        selectableRowsVisibleOnly={false}
        selectableRowsHighlight={false}
      // selectedRows={selectedRows}
      // setSelectedRows={handleSelectRows}
      />
    </>
  );
};

export default Campaigns;
