import { ActionIcon, ComboboxItem, Grid, Loader, NumberInput, Space, } from '@mantine/core'
import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { UseFormReturnType } from '@mantine/form';
import { CustomAutocomplete, FirebaseTransaction, Investment, Security, SecurityItem } from '../types';
import { AutocompleteComponent } from './autocomplete_component';
import { useFunctions } from '../helpers/firebaseContext';

export interface SecurityRowProps {

    form: UseFormReturnType<FirebaseTransaction, (values: FirebaseTransaction) => FirebaseTransaction>,
    securitiesList: (Security & CustomAutocomplete)[] | (Investment & CustomAutocomplete)[],
    addedSecuritiesList: SecurityItem[],
    setAddedSecuritiesList: React.Dispatch<React.SetStateAction<SecurityItem[]>>
    // selectedPortfolioCode: string,
    idx: number
    disabled: boolean,

}

export const SecurityRow = ({ form, securitiesList, addedSecuritiesList, setAddedSecuritiesList, idx, disabled }: SecurityRowProps) => {

    const [priceLoading, setPriceLoading] = useState(false);
    const [selectedSecurity, setSelectedSecurity] = useState<(Security & CustomAutocomplete) | (Investment & CustomAutocomplete) | undefined>(undefined);

    // const [price, setPrice] = useState("");

    const functions = useFunctions();

    useEffect(() => {


        if (disabled) return;
        if (!selectedSecurity) return;

        let currentSecurities = form.values.securities

        if (!currentSecurities) return;


        if (form.values.transactionType === "security_out") {
            console.log("Get local price", selectedSecurity);
            const security = selectedSecurity as Investment;
            currentSecurities[idx] = { ...currentSecurities[idx], price: security.UNITCOSTLOCALEND }

            form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
            setAddedSecuritiesList(currentSecurities);
        }
        else {
            //get current price
            const security = selectedSecurity as Security;


            console.log("Get price from geneva");
            const getPriceInfo = httpsCallable(functions, "getPriceInfo");

            setPriceLoading(true);
            //get cash on hand for this portfolio

            // console.log("asking for values", currentSecurities[idx]);
            getPriceInfo({ portfolioCode: form.values.portfolio?.code, investmentCode: security.CODE }).then((response: any) => {
                console.log("response is  ", response);
                setPriceLoading(false)


                if (response.data.length > 0) {
                    currentSecurities[idx] = { ...currentSecurities[idx], price: response.data[0].LATESTPRICE }

                    form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
                    setAddedSecuritiesList(currentSecurities);

                }



            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.transactionType, selectedSecurity?._KEY_]);



    useEffect(() => {
        if (disabled) {
            let currentSecurities = form.values?.securities
            setAddedSecuritiesList(currentSecurities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    // console.log("form securities", form.values.securities, form.values.securities?.length ? (form.values.securities[idx]?.price) : null);
    return <>
        <Grid.Col span={{ xs: 6, lg: 6 }}>

            <AutocompleteComponent
                defaultItem={form.values?.securities?.[idx]?.value}

                textProps={{
                    label: "Security",
                    placeholder: "Choose security",
                    disabled: disabled
                }}
                text_r=""
                value=""
                // {...(form.getInputProps("securities"))}

                handleSubmit={(submittedItemValue: (Security & CustomAutocomplete) | (Investment & CustomAutocomplete)) => {

                    console.log("Value is ", submittedItemValue);
                    const submittedItem = (securitiesList as any).find((securityObj: any) => securityObj.value === submittedItemValue.value)

                    console.log("Setting selected security to ", submittedItem?._KEY_);
                    let currentSecurities = form.values.securities
                    if (!currentSecurities) {
                        currentSecurities = [{ value: submittedItem, quantity: 1 }];
                    }
                    else {
                        currentSecurities[idx] = { ...currentSecurities[idx], value: submittedItem, quantity: 1 }
                    }
                    form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
                    setAddedSecuritiesList(currentSecurities);
                    setSelectedSecurity(submittedItem);

                    console.log("current securities", currentSecurities, securitiesList)

                }}
                // value={disabled ? addedSecuritiesList[idx]?.value?.CODE
                //     : addedSecuritiesList[idx]?.value?.CODE
                // }
                // itemComponent={AutoCompleteItem}
                // comboboxProps={{ withinPortal: true }}
                data={securitiesList as ComboboxItem[]}
            // onChange={(value: any) => {
            //     handleChooseComapny(value, idx)
            // }}

            // filter={(value, item) => {
            //     // console.log("WE LOG!", value, item);
            //     return item.value.toLowerCase().includes(value.toLowerCase().trim()) ||
            //         item.TICKER?.toLowerCase().includes(value.toLowerCase().trim())
            // }
            // }


            // limit={100}
            // clearable={addedSecuritiesList.length > 1}
            // dropdownComponent={({ children }: any) => <ScrollArea
            //     h={200}
            //     w={"100%"}

            // >
            //     {children}

            // </ScrollArea>}

            />


        </Grid.Col>
        <Grid.Col span={{ lg: 2, xs: 6 }}>
            <>
                {/* {
                    console.log("form.values.securities?", form.values.securities)
                } */}
            </>
            <NumberInput
                label="Quantity"
                placeholder="Quantity"
                disabled={disabled}

                // hideControls
                // name="quantity"
                min={1}
                required
                // {...form.getInputProps("securities")}
                value={form.values.securities?.length ? (form.values.securities[idx]?.quantity) : 1}
                // onChange={(value: any) => {
                //     handleQuantityComapny(value, idx)
                // }}

                // parser={(value) => value.replace(new RegExp(`(,*)`, "g"), '')}

                // formatter={(value) =>
                //     !Number.isNaN(parseFloat(value))
                //         ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                //         : ''
                // }

                onChange={(value) => {
                    let currentSecurities = form.values.securities
                    value = Number(value);
                    currentSecurities[idx] = { ...currentSecurities[idx], quantity: value }
                    form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
                    setAddedSecuritiesList(currentSecurities);
                }}
            // onChange={handleChange}
            />
        </Grid.Col>


        <Grid.Col span={{ xs: 12, lg: disabled ? 4 : 2 }}>
            {/* {form.getInputProps("action").value === "ReceiveLong" && */}
            <NumberInput
                decimalScale={4}
                label="Price"
                placeholder="price"
                hideControls
                name="price"
                required
                disabled={form.values.transactionType === "security_out" || disabled}
                leftSection={priceLoading ? <Loader size="xs" /> : undefined}

                value={form.values.securities?.length ? (form.values.securities[idx]?.price) : 0}
                // onChange={(value: any) => {
                //     handleQuantityComapny(value, idx)
                // }}


                onChange={(value) => {
                    value = Number(value)
                    let currentSecurities = form.values.securities
                    currentSecurities[idx] = { ...currentSecurities[idx], price: value }
                    form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
                    setAddedSecuritiesList(currentSecurities);
                }}

            // value={(form.getInputProps("securities")).value[idx]?.price}

            // onChange={handleChange}
            />
            {/* } */}
        </Grid.Col>

        {!disabled && <>
            <Grid.Col span={{ xs: 2, lg: 1 }} >
                <Space h="xl" />
                <ActionIcon

                    style={{ justifyContent: "center" }} onClick={() => {
                        let currentSecurities = form.values.securities || []
                        currentSecurities = [...currentSecurities, { price: 0, quantity: 1 }];

                        setAddedSecuritiesList(currentSecurities);
                        form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))

                    }}>
                    <IconPlus size={25} />
                </ActionIcon>
            </Grid.Col>
            <Grid.Col span={{ xs: 6, lg: 1 }}>
                <Space h="xl" />
                {idx > 0 &&
                    <ActionIcon


                        onClick={() => {
                            let currentSecurities = form.values.securities
                            // currentSecurities.splice(idx, 1);
                            currentSecurities.pop();  //TODO: just removing last element for now since fields don't update properly

                            console.log("Removing at index", idx, currentSecurities);
                            // const newList = addedSecuritiesList
                            // newList.splice(idx, 1);
                            form?.setValues((prev: any) => ({ ...prev, securities: currentSecurities }))
                            setAddedSecuritiesList([...currentSecurities])

                        }}
                    >
                        <IconTrash size={25} />
                    </ActionIcon>
                }



            </Grid.Col>

        </>}

    </>

}

// export default SecurityRow