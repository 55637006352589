import { useContext, } from "react";
import { MyUserDataContext } from "../../contexts";
import CustomersFirebaseListSearch from "./customersFirebaseListSearch";
import CustomersListTypesense from "./CustomersListTypesense";




export default function Customers() {
  const appContext = useContext(MyUserDataContext);
  let userClaims = appContext.firebaseTokenResult?.claims.customClaims;

  return (
    <main style={{ padding: "1rem 0" }}>
      {

        !userClaims["kfh_rm"] ?
          <CustomersListTypesense />
          :
          <CustomersFirebaseListSearch />
      }
    </main>
  );
}
