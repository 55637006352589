import { Card, Container, Grid, Stack, Title } from '@mantine/core'
import React from 'react'

const FormCardWrapper = ({ children, title, justify = "center", gridProps }: any) => {
  return (
    <Stack >
      <Title>{title || ""}</Title>

      <Card mt="xs" shadow="sm" p="xl" radius="md" withBorder >
        <Container>


          <Grid ta="center" justify={justify} {...gridProps} >
            {children}
          </Grid>
        </Container>
      </Card>
    </Stack>
  )
}

export default FormCardWrapper