import { ActionIcon, Alert, Button, Divider, Grid, Group, Select, Stack, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { useEffect, useState } from 'react'
import { httpsCallable } from 'firebase/functions';
import { useLocation, useNavigate } from 'react-router-dom';

import TransactionsPanel from './transactions_main_panel';
import { IconAlertCircle, IconArrowLeft } from '@tabler/icons-react';
import CommentsSection from '../../components/comments_section';
import FormCardWrapper from '../../components/form_card_wrapper';
import ExchangeRates from '../../components/exchange_rates';

import { collection, onSnapshot, query } from 'firebase/firestore';
import CashForm from './cash_form';
import SecurityForm from './security_form';
import TransactionsActions from './transactionsActions';
import JournalEntryForm from './journal_entry_form';
import { CustodianAccount, CustomAutocomplete, FirebaseTransaction, FiresotreTransactionType, Investment, Security } from '../../types';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';

const TRANSACTION_TYPES = [{
    "label": "Transfer In",
    "value": "cash_in",
    // type: "cash",
    // action: "Deposit"
},
{
    "label": "Transfer Out",
    "value": "cash_out",
    // type: "cash",
    // action: "Withdraw"
},
{
    "label": "Security In",
    "value": "security_in",
    // type: "security",
    // action: "ReceiveLong"
},
{
    "label": "Security Out",
    "value": "security_out",
    // type: "security",
    // action: "DeliverLong"
},
{
    "label": "FX + Transfer",
    "value": "fx",
    // type: "fx",
    // action: "SpotFX"  //not needed
},
{
    "label": "Manual Journal Entry ",
    "value": "journal_entry",
    // type: "fx",
    // action: "SpotFX"  //not needed
},

]

const TransactionForm = ({ title, transactionObj = {}, disabled }: any) => {

    // const [selectedTransaction, setSelectedTransaction] = useState<any>();
    // const [transactionType, setTransactionType] = useState<any>();
    const [msg, setMsg] = React.useState(null as any);
    const [isError, setIsError] = React.useState(false);
    const [pending, setPending] = React.useState(false);
    const db = useFirestore();
    // const { customerIDParam } = useParams();
    const [custodianAccounts, setCustodianAccounts] = useState<CustodianAccount[]>([])
    const location = useLocation();

    const navigate = useNavigate();


    const [securitiesList, setSecuritiesList] = useState<Security[]>([])


    useEffect(() => {
        const securitiesQuery = query(
            collection(db, "securities"),);
        onSnapshot(securitiesQuery, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.docs.length);
                // return;
            } else {
                console.log("Server data", snapshot.docs.length);
            }

            let securitiesRows = snapshot.docs.map((doc) => {
                var data = doc.data() as Security & CustomAutocomplete;
                data["id"] = (doc.id).toString();
                data["text_r"] = data.TICKER + " - " + data.EXCHANGE_DESCRIPTION;
                data["text_l"] = data.id;
                data["group"] = data.EXCHANGE_DESCRIPTION;
                data["key"] = doc.id;
                data["value"] = data.id;
                data["label"] = `${data.TICKER} - ${data.EXCHANGE_DESCRIPTION}`;
                return data;
            });

            //order rows by data._EXCHANGE_NAMELINE1_ and data.id
            securitiesRows.sort((a: any, b: any) => {
                if (a._EXCHANGE_NAMELINE1_ < b._EXCHANGE_NAMELINE1_) {
                    return -1;
                }
                if (a._EXCHANGE_NAMELINE1_ > b._EXCHANGE_NAMELINE1_) {
                    return 1;
                }
                return 0;
            });




            setSecuritiesList(securitiesRows);



        });

        {
            const custodianAccountsQuery = query(
                collection(db, "custodian_accounts"),);
            onSnapshot(custodianAccountsQuery, (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("Cached data", snapshot.docs.length);
                    // return;
                } else {
                    console.log("Server data", snapshot.docs.length);
                }

                let custodianAccountsRows = snapshot.docs.map((doc): CustodianAccount => {
                    var data = doc.data() as CustodianAccount;
                    data["id"] = (doc.id).toString();
                    data["text_r"] = data._CUSTODIAN_NAMESORT_;
                    data["text_l"] = data.NAMESORT;
                    data["key"] = doc.id;
                    data["value"] = data.NAMESORT;
                    data["label"] = `${data["text_r"]} - ${data["text_l"]}`
                    return data;
                });

                setCustodianAccounts(custodianAccountsRows);



            });
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])






    const resetForm = {
        currency: "KWD",
        amount: "",
        custodianAccount: {} as any,
        comments: "",
        margin: false,
        portfolio: {} as any,
        currencyFromValue: "KWD",
        currencyToValue: "KWD",
        customRate: 1,
        rate: 1,
        transactionTimestamp: new Date() as any,
        convertedAmount: 0
    }

    // delete transactionObj.newCustodianAccount;
    // delete transactionObj.transactionType;


    const form = useForm<FirebaseTransaction>({

        initialValues: {
            customerID: location?.state?.customer,
            transactionType: location?.state?.type,

            ...resetForm,
            ...transactionObj
        } as FirebaseTransaction,

        validate: {
            amount: (value): string | null => {
                let type = form.values.transactionType;
                // console.log("we should be validating amount", value, type, form.values.currencyFromValue, form.values.currencyToValue);


                if (type === "cash_in" || type === "cash_out" || type === "fx") {
                    console.log(value, Number(value))
                    if (!value || Number(value) <= 0) {
                        return "Please fill out amount";
                    }
                }


                // console.log("transactionType", type);

                if (type === "cash_in" || type === "security_in" || type === "security_out") return null;
                if (type === "journal_entry") {
                    form.setFieldValue("amount", 0)
                    return null;
                }
                // console.log("VALIDATING AMOUNT!", form.values);

                //does he have enough cash on hand?
                // let currency: string = type === "fx" ? form.values?.currencyFromValue : form.values?.currency;

                //check if custodian has that balance.
                let custodianAccount: any = form.values.custodianAccount;

                //ok let's check the position
                let position = form.values.position as any;
                //loop through the trial balance and find the custodian account using row.SUBCAT
                let custodianAccountBalance = position?.find((row: any) => row.CUSTODIANACCOUNT === custodianAccount.id && row.MARKETVALUELOCALEND > 0);

                if (!custodianAccountBalance) return "Can't find custodian";
                if (Number(value) > custodianAccountBalance?.MARKETVALUELOCALEND) {
                    return `Not enough cash.  ${custodianAccountBalance.CUSTODIANACCOUNT} only has ${custodianAccountBalance.MARKETVALUELOCALEND} ${custodianAccountBalance._INVESTMENT_CODE_}`
                }
                return null;


                /*
                                //ok let's check the trial balance
                                let trialBalance = form.values.trialBalance as TrialBalanceRow[];
                                //loop through the trial balance and find the custodian account using row.SUBCAT
                                let custodianAccountTrialBalance = trialBalance.find((row: any) => row.SUBCAT === custodianAccount.id && row.DETAIL.toLowerCase() === "cost");
                
                                if (!custodianAccountTrialBalance) return "Can't find custodian in trial balance";
                                if (Number(value) > custodianAccountTrialBalance?.CLOSINGBAL) {
                                    return `Not enough cash.  ${custodianAccountTrialBalance.SUBCAT} only has ${custodianAccountTrialBalance.CLOSINGBAL} ${custodianAccount._UDF_CCY_}`
                                }
                                return null;
                                */

            },
            securities: (securities): string | null => {


                //TODO: validate based on custodian!


                let type = form.values.transactionType;

                if (type !== "security_in" && type !== "security_out") return null;

                if (!securities || securities.length === 0) return "Please select securities";

                let exchange = ""
                for (let i = 0; i < securities.length; i++) {
                    let security = securities[i];

                    // console.log(security);
                    if (i === 0) {
                        exchange = security.value?.EXCHANGE_CODE || "";
                    }
                    else if (exchange !== security.value?.EXCHANGE_CODE) {
                        return "Please select securities from the same exchange"
                    }

                    // console.log("Exchange:", exchange, security.value._INVESTMENT_EXCHANGE_NAMESORT_);

                    if (type === "security_in") continue; //no need to check securities available for in

                    let quantityAvailable: number = form.values.position?.find((cash: Investment) => cash.CODE === security.value?.CODE)?.QTYEND || 0;

                    if (security.quantity > quantityAvailable) {
                        return `Not enough ${security.value?.TICKER} shares on hand. You have ${quantityAvailable}`
                    }

                }
                return null;

            },
            portfolio: (value): string | null => {
                return !value || !value.code ? "Select a portfolio" : null;
            },

            custodianAccount: (value): string | null => {
                let type = form.values.transactionType;

                if (type === "journal_entry")
                    return null;
                return !value || !value.id ? "Select a custodian account" : null;
            },

            newCustodianAccount: (value): string | null => {

                return form.values.transactionType === "fx" && (!value || !value.id) ? "Select a custodian account" : null;
            },


            entriesList: (entriesList): string | null => {
                const type: string = form.values.transactionType;
                if (entriesList && type === "journal_entry") {
                    for (let i = 0; i < entriesList?.length; i++) { }
                    if (type === "journal_entry") {
                        for (let i = 0; i < entriesList.length; i++) {
                            let calcValue = 0;

                            entriesList!.map((entryValue: any) =>
                                calcValue += (entryValue.credit + entryValue.debit)
                            )
                            if (calcValue !== 0)
                                return "Journal Entries must total to 0"
                        }
                    }
                }

                return null;
            },

            // capitalAccount: (value) => {
            //     let type: string = form.values.transactionType;
            //     return type !== "fx" && (!value || !value.id) ? "Select a capital account" : null;
            // }
        },

    });
    const functions = useFunctions();

    // console.log("Transaction type", form.values.transactionType);

    return (

        <>
            <form
                onSubmit={form.onSubmit(async (values, _event) => {

                    // console.log(_event, _event.nativeEvent.preventDefault);


                    //check if it's Enter
                    // if (_event. === "Enter") return;



                    // return;
                    // console.log("zubmitting", values)
                    setPending(true);
                    // values.civilID = values.id

                    try {

                        // console.log(transactionType)
                        let result = {} as any;

                        //deleting "temp" will mess up the form since some fields rely on it.  Let's clone instead
                        let clonedValues: any = { ...values };
                        delete clonedValues["temp"];

                        console.log("cloned values", clonedValues, JSON.stringify(clonedValues));

                        const addTransaction = httpsCallable(functions, 'addTransaction');

                        console.log("addtran", addTransaction);
                        result = await addTransaction(JSON.parse(JSON.stringify(clonedValues)));
                        console.log("result", result);
                        // e.target.reset();

                        //check if the result data has no errors
                        if (result.data.result.response) {
                            //check if the string error exists
                            if (result.data.result.response.toLowerCase().includes("error")) {
                                //throw
                                throw new Error(result.data.result.response);
                            }
                        }
                        //the href is absolutely stupid.  I'm reutilizing the sendBatchSMS from firebase functions, so yeah.
                        setMsg(
                            <Stack>

                                <Text> {result.data.result.response}</Text>
                            </Stack>
                        );
                        setIsError(!result.data.result.successful);

                        //go back on success.
                        // navigate("/transactions");

                        navigate("/transactions", {
                            state: {
                                refreshTransactionsIn: 1000,
                            }
                        });


                    } catch (error: any) {
                        setIsError(true);
                        console.log(error);
                        setMsg(error.message);
                    }

                    setPending(false);
                }, (validationErrors, _values, _event) => {
                    // console.log("Handle enter");

                    setIsError(true);
                    setMsg(<Text>Validation error: {Object.keys(validationErrors).map((key) => <Text>{key}: {validationErrors[key]}</Text>)}</Text>)
                })
                }

            >
                <Group>
                    <ActionIcon onClick={() => {
                        //go back
                        navigate("/transactions");
                    }}>
                        <IconArrowLeft size="3rem" />
                    </ActionIcon>
                    <Title> {title || "Transactions Form"}</Title>
                </Group>
                <FormCardWrapper>

                    {transactionObj?.length &&
                        <Grid.Col span={{ xs: 12, lg: 6 }}>
                            <TextInput
                                label="Transcation ID"
                                disabled={true}
                                value={transactionObj.id}
                            />
                        </Grid.Col >
                    }

                    <Grid.Col span={{ xs: 12, lg: 6 }}>
                        {<Select
                            disabled={disabled}
                            label="Transaction Type"
                            placeholder="Trasaction Type"
                            onChange={(type) => {
                                // setTransactionType(type);

                                //find element with that type
                                // let transactionType = TRANSACTION_TYPES.find((t: any) => t.value === type);


                                // setTransactionType(transactionType!.type);
                                form.setFieldValue("transactionType", (type as FiresotreTransactionType));
                                // form.setFieldValue("type", transactionType!.type);
                                // form.setFieldValue("selected", transactionType!.type);
                            }}
                            value={form.values.transactionType}
                            data={TRANSACTION_TYPES}
                        />}
                    </Grid.Col >
                    {/*  <Grid.Col span={{xs:6}}></Grid.Col> */}
                    <TransactionsPanel
                        disabled={disabled}
                        form={form}
                        custodianAccountsParent={custodianAccounts}
                        transactionType={form.values.transactionType}
                        disableFetch={false}
                    />

                    {/* </FormCardWrapper> */}


                    {(form.values.transactionType === "cash_in" || form.values.transactionType === "cash_out") &&
                        // <FormCardWrapper>
                        <CashForm form={form} disabled={disabled} />
                        // </FormCardWrapper>
                    }

                    {
                        (form.values.transactionType === "security_in" || form.values.transactionType === "security_out") &&
                        // <FormCardWrapper justify="start">

                        <SecurityForm disabled={disabled} form={form} securitiesList={securitiesList} />
                        // </FormCardWrapper>
                    }
                    {
                        (form.values.transactionType === "journal_entry") &&

                        <JournalEntryForm disabled={disabled} custodianAccounts={custodianAccounts} form={form} />
                    }

                    {
                        form.values.transactionType === "fx" &&
                        // <FormCardWrapper justify="start">
                        <>

                            <Grid.Col span={{ xs: 12 }}>
                                FX + Transfer (from {form.values?.currencyFromValue} to {form.values?.currencyToValue})
                            </Grid.Col>

                            <Divider />

                            <ExchangeRates
                                currencyFrom={disabled ? form.values?.currencyFromValue : null}
                                currencyTo={disabled ? form.values?.currencyToValue : null}
                                disabled={disabled}
                                form={form}

                            />
                        </>
                    }



                    {/* <FormCardWrapper> */}


                    {/* </FormCardWrapper> */}

                    {/* <FormCardWrapper> */}

                    <Grid.Col span={{ xs: 12, lg: 12 }} >
                        <CommentsSection
                            disabled={disabled}
                            value={form.values?.comments}
                            setValue={(value: any) => form.setFieldValue("comments", value)}

                        />
                    </Grid.Col>



                    {!disabled && <Grid.Col span={{ xs: 12, lg: 6 }}>
                        <Button fullWidth type="submit" loading={pending}>
                            Submit
                        </Button>
                    </Grid.Col>}

                    <TransactionsActions transactionObj={transactionObj} />

                </FormCardWrapper>

            </form >

            {msg && <Alert
                style={{ marginTop: 20 }}
                icon={<IconAlertCircle size={16} />}
                c={isError ? "red" : "green"}
                title={isError ? "Failure" : "Success"}
            >
                {msg}
            </Alert>
            }
        </ >



    )
}

export default TransactionForm