
import { SimpleGrid, Loader } from '@mantine/core';
import { useEffect, useState } from 'react';

import { UploadBox } from './upload_box';
import { DocTypes, FileCard } from './file_card';
import getKycFiles from '../helpers/getKycFiles';
import { uploadToStorage } from '../helpers/uploadToStorage';
import { DocType } from '../types';
import { IconFileTime, IconQuestionMark } from '@tabler/icons-react';



type FilesListProps = {
    path: string,
    setMetaTypes?: React.Dispatch<React.SetStateAction<DocType[]>>,
    links: any[],
    forceRefresh?: number | null
    // triggerRefresh: () => void
    setLinks: (links: any[]) => void  //this is to inform whoever is calling us that we have new links.
    onChange?: () => void,
    customDocTypes?: Record<string, DocType>,
    showUploader?: boolean,
}

export function FilesList(props: FilesListProps) {

    const { path } = props;
    const { forceRefresh } = props;

    const [links, setLinks] = useState<any[]>(props.links);

    const [uploading, setUploading] = useState(false);


    const [triggerRefresh, setTriggerRefresh] = useState(false);

    const [loading, setLoading] = useState(true);
    const availableDocTypes: Record<string, DocType> = {
        ...DocTypes,
        unreadable: {
            icon: IconQuestionMark,
            name: "Unreadable",
            color: "red",
            key: "unreadable"
        },
        hasExpiryDate: {
            icon: IconFileTime,
            name: "Has Expiry Date",
            key: "hasExpiryDate",
            color: "gray"
        },
    }
    useEffect(() => {
        if (props.setMetaTypes) {
            let docTypes: DocType[] = []
            links?.forEach((linkData) => {

                let meta = linkData.metadata?.customMetadata;
                for (let key in meta) {
                    if (!availableDocTypes[key]) {
                        console.log("document type is unknown", key);
                    }
                    else {
                        docTypes.push(availableDocTypes[key]);
                    }
                }
            })

            docTypes = [
                ...new Map(docTypes.map((item: any) => [item?.key, item])).values(),
            ];

            //We need to remove all the duplicate crap from the metatypes after merging.
            props.setMetaTypes((oldDocTypes) => {
                const mergedArray = [...oldDocTypes, ...docTypes];
                const uniqueArray = mergedArray.filter((docType, index, self) =>
                    index === self.findIndex((t) => t.key === docType.key)
                );
                return uniqueArray;
            });

            console.log("Meta types is: ", docTypes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links])



    useEffect(() => {

        const getData = async () => {


            setTriggerRefresh(false);
            const linkData = await getKycFiles(path, null) || []


            setLinks(linkData.filter((x: any) => x !== null));
            setLoading(false);
            //inform whoever is calling us that we have new links.
            if (props.setLinks) props.setLinks!(linkData.filter((x: any) => x !== null));
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerRefresh, path,]);

    useEffect(() => {
        setTriggerRefresh(true)
    }, [forceRefresh])


    return <>
        {loading && <Loader />}
        <SimpleGrid cols={4}>


            {links.map((linkData: any) => {
                // console.log("lunkdata", linkData);
                return <FileCard key={linkData.name} linkData={linkData} triggerRefresh={setTriggerRefresh} onChange={props.onChange} />
            }
            )}


            {(props.showUploader??true) && <UploadBox loading={uploading}

                onDropWithTag={async (files, tags) => {
                    setUploading(true);

                    // console.log(files);
                    // const listRef = ref(storage, path);

                    //upload to firebase storage.
                    const promises = files.map(async (file) => {
                        uploadToStorage(file, path,tags);
                    });

                    await Promise.all(promises);

                    // const messaging = getMessaging(getApp());

                    //wait 6 seconds.  I can't know when the thumbnail is ready.
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve(true);
                        }, 6000);
                    });

                    //refreshes listing
                    setUploading(false);
                    setTriggerRefresh(true)

                    if (props.onChange) props.onChange();



                }}
                customDocTypes={props.customDocTypes}
                 />}

        </SimpleGrid>
        {/* <p>Note: All files must be tagged</p> */}
    </>


}

