import React, { useState, useEffect } from "react";

import { DocumentReference, QueryDocumentSnapshot, doc } from "firebase/firestore";
import { onSnapshot, where, collectionGroup, query, collection } from "firebase/firestore";

import { useParams } from "react-router-dom";
import { Tabs } from "@mantine/core";

import { TagBadge } from "../../components/tag_badge";
import { History } from "../../components/history";
import { OrderInfo } from "../../components/order_info";

import { Order } from "../../types";
import { useFirestore } from "../../helpers/firebaseContext";


//create CustomerInfo component
export default function OrderPage(props: any) {
  const { id } = useParams();

  const db = useFirestore();
  const [orderData, setOrderData] = useState<Order>();
  const [orderDocument, setOrderDocument] = useState<QueryDocumentSnapshot<Order>>();

  const [customerRef, setCustomerRef] = useState<DocumentReference>();
  const [orderRef, setOrderRef] = useState<DocumentReference>();



  // const navigate = useNavigate();

  // const tabValue = null;
  const [activeTab, setActiveTab] = React.useState<string | null>("info");

  useEffect(() => {
    const orderQuerySnapshot = query(collectionGroup(db, "orders"), where('id', '==', id))
    return onSnapshot(
      orderQuerySnapshot,
      (snapshot) => {
        if (!snapshot.empty) {
          const orderRef = snapshot?.docs[0] as QueryDocumentSnapshot<Order>;
          setOrderDocument(orderRef)
          const orderData = orderRef.data();
          setOrderRef(snapshot?.docs[0].ref);
          const fullPath = snapshot?.docs[0].ref.path; // get the full path of the order document
          const pathParts = fullPath.split('/');
          const customerId = pathParts[pathParts.length - 3]; // get the customerId from the path
          setOrderData(orderData as Order)
          setCustomerRef(doc(db, 'customers', customerId))
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!orderData || !customerRef || !orderRef) {
    return <div>Loading...</div>
  }

  console.log("Order is", orderData);

  return (
    <>
      {/* <Tabs defaultValue="kyc" value={tabValue} onChange={(value: any) => navigate(`/customers/${id}/${value}`)}> */}
      <Tabs onChange={setActiveTab} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab
            value="info"
            rightSection={<TagBadge tag={orderData?.tags?.orderStatus} />}
          >
            Info
          </Tabs.Tab>
          <Tabs.Tab value="history">History</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="info">
          <OrderInfo orderDoc={orderDocument} order={orderData} customerRef={customerRef} orderRef={orderRef} />
        </Tabs.Panel >


        <Tabs.Panel value="history">

          {orderData && <History
            historyCollection={collection(
              orderRef,
              "history"
            )}
          />}

        </Tabs.Panel>
      </Tabs >
    </>
  );
}
