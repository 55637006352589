import React, { useRef, useState } from 'react';
import { Modal, Button, Group } from '@mantine/core';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FileWithPath } from '@mantine/dropzone';

interface ImageCropperModalProps {
    imageFile: FileWithPath | undefined;
    isOpen: boolean;
    onClose: () => void;
    onSave: (imageBlob: Blob) => void;
}

const ImageCropperModal: React.FC<ImageCropperModalProps> = ({
    imageFile,
    isOpen,
    onClose,
    onSave,
}) => {
    const [cropper, setCropper] = useState<Cropper>();
    const cropperRef = useRef<ReactCropperElement>(null);
    const [CropBoxInfo, setCropBoxInfo] = useState<{ width: number; height: number; scale: number; minCropSize: number; canBeCropped: boolean } | null>(null);


    /**
         * Sets the initial data for the image cropper when it is ready.
         * Resets the cropper to display the entire image with no cropping.
     */
    const onReady = () => {
        // Get the cropper instance from the ref
        const cropper = cropperRef?.current?.cropper;

        // If cropper instance exists, set its initial data to display the entire image
        cropper?.setData({
            x: 0,
            y: 0,
            width: Number.MAX_VALUE,
            height: Number.MAX_VALUE,
        });
    };


    /**
         * Calculates and returns the dimensions for cropping an image based on the specified CropBoxInfo.
         * Ensures that at least one dimension is within the range of 500 to 700 pixels.
         * If both dimensions are already within this range, returns the original CropBoxInfo.
         * If either dimension is less than 500 pixels or greater than 700 pixels,
         * scales the image while maintaining aspect ratio to fit within the specified range.
         * @param CropBoxInfo The original dimensions of the image with extra data.
         * @returns The dimensions for cropping the image.
    */
    function getToCropImageSize(CropBoxInfo: { width: number; height: number; scale: number; minCropSize: number; canBeCropped: boolean }): { width: number; height: number; scale: number; minCropSize: number; canBeCropped: boolean } {
        // Define the reference scale for resizing
        let scaleRef = 500;
        let maximumScaleRef = 700;

        // Check if either dimension is greater than or equal to 500 pixels
        if (CropBoxInfo.height >= 500 || CropBoxInfo.width >= 500) {
            // If either dimension is greater than or equal to 700 pixels, update the reference scale
            if (CropBoxInfo.height >= maximumScaleRef || CropBoxInfo.width >= maximumScaleRef) {
                scaleRef = maximumScaleRef;
            } else {
                // If both dimensions are within the range, return the original CropBoxInfo
                return CropBoxInfo;
            }
        }

        // Scale the image to fit within the specified range while maintaining aspect ratio
        if (CropBoxInfo.height >= CropBoxInfo.width) {
            let scale = scaleRef / CropBoxInfo.height;
            let width = Math.floor(CropBoxInfo.width * scale);
            let minCropSize = 100;
            let canBeCropped = minCropSize < CropBoxInfo.width || minCropSize < CropBoxInfo.height;
            minCropSize = minCropSize * scale;
            return {
                height: scaleRef,
                width: width,
                scale: scale,
                minCropSize: minCropSize,
                canBeCropped: canBeCropped,
            };
        } else {
            let scale = scaleRef / CropBoxInfo.width;
            let height = Math.floor(CropBoxInfo.height * scale);
            let minCropSize = 100;
            let canBeCropped = minCropSize < CropBoxInfo.width || minCropSize < CropBoxInfo.height;
            minCropSize = minCropSize * scale;
            return {
                height: height,
                width: scaleRef,
                scale: scale,
                minCropSize: minCropSize,
                canBeCropped: canBeCropped,
            };
        }
    }


    /**
         * Sets the resolution of the image and updates the component state accordingly.
         * It reads the file provided and extracts its resolution using an HTMLImageElement.
         * If successful, it sets the CropBoxInfo state to the resolution of the image.
         * If there's an error loading or reading the file, it sets a default CropBoxInfo and logs an error.
         * @param file The File object representing the image file.
     */
    function setImageResolution(file: File): void {
        const defaultValue = { width: 300, height: 300, scale: 1, minCropSize: 100, canBeCropped: true };
        // Create a new FileReader object to read the image file
        const reader = new FileReader();

        // Define onload event handler for when the file is successfully loaded
        reader.onload = (event) => {
            // Create a new Image object to extract the resolution of the image
            const img = new Image();

            // Define onload event handler for when the image is successfully loaded
            img.onload = () => {
                // Log the natural width and height of the image to the console
                console.log({ width: img.naturalWidth, height: img.naturalHeight });
                // Get the desired size for cropping based on the image resolution and update the component state
                let minCropSize = 100;
                let canBeCropped = minCropSize < img.naturalWidth || minCropSize < img.naturalHeight;
                setCropBoxInfo(getToCropImageSize({ width: img.naturalWidth, height: img.naturalHeight, scale: 1, minCropSize: minCropSize, canBeCropped: canBeCropped }));
            };

            // Define onerror event handler for when there's an error loading the image
            img.onerror = () => {
                // Set a default CropBoxInfo and log an error message
                setCropBoxInfo(defaultValue);
                console.error("Failed to load image");
            };

            // Set the src attribute of the image element to the data URL of the loaded file
            if (event.target && typeof event.target.result === "string") {
                img.src = event.target.result;
            } else {
                // If the event target result is not a string, set a default CropBoxInfo and log an error message
                setCropBoxInfo(defaultValue);
                console.error("Invalid file");
            }
        };

        // Define onerror event handler for when there's an error reading the file
        reader.onerror = () => {
            // Set a default CropBoxInfo and log an error message
            setCropBoxInfo(defaultValue);
            console.error("Failed to read file");
        };

        // Read the contents of the provided file as a data URL
        reader.readAsDataURL(file);
    }



    /**
         * Handles saving the cropped image.
         * Checks if both the cropper instance and the image file are available.
         * If both are available, retrieves the cropped image as a Blob and calls the onSave callback with the blob.
         * Finally, closes the modal after saving the image.
     */
    const handleSave = () => {
        // Check if both the cropper instance and the image file are available
        if (cropper && imageFile) {
            // Retrieve the cropped image as a Blob using the cropper's getCroppedCanvas method
            cropper.getCroppedCanvas().toBlob((blob) => {
                // Check if a valid Blob is obtained
                if (blob) {
                    // Call the onSave callback with the cropped image blob
                    onSave(blob);
                    // Close the modal after saving the image
                    onClose(); // or onClose();
                }
            }, imageFile.type);
        }
    };

    /**
        * If an image file exists and the CropBoxInfo state is not set,
        * trigger a function to determine and set the resolution of the image.
    */
    if (imageFile && !CropBoxInfo) {
        setImageResolution(imageFile);
    }


    console.log("CropBoxInfo: ", CropBoxInfo)
    return (
        // Render a modal dialog for cropping the image
        <Modal
            opened={isOpen} // Indicates whether the modal is open or closed
            onClose={onClose} // Callback function to handle modal closure
            title="Crop your image" // Title of the modal
            size="auto" // resize based on cropper size
            centered={true} // Centers the modal vertically and horizontally
        >
            {/* Render the cropper component if both imageFile and CropBoxInfo states are available */}
            {imageFile && CropBoxInfo && (
                <>
                    <Cropper
                        src={URL.createObjectURL(imageFile)} // Image source URL for cropping
                        style={{ height: CropBoxInfo.height + 'px', width: CropBoxInfo.width + 'px', margin: 'auto' }} // Inline styles for cropper component
                        initialAspectRatio={CropBoxInfo.width / CropBoxInfo.height} // Initial aspect ratio based on image size
                        allowFullScreen={true} // Enables fullscreen mode for the cropper
                        zoomable={false} // Disables zooming functionality in the cropper
                        guides={true} // Displays guides for cropping in the cropper
                        ref={cropperRef} // Reference to the cropper instance
                        ready={onReady} // Callback function to handle cropper readiness
                        minCropBoxHeight={CropBoxInfo.minCropSize}
                        minCropBoxWidth={CropBoxInfo.minCropSize}


                        // Callback function to initialize the cropper instance
                        onInitialized={(instance) => {
                            setCropper(instance); // Sets the cropper instance when initialized
                        }}
                    />
                    {!CropBoxInfo.canBeCropped && <p>Note: This image cannot be cropped because it is too small.</p>}
                </>
            )}
            {/* Render buttons for cancelling and saving/cropping the image */}
            <Group mt="md"> {/* Margined Group component for button alignment */}
                {/* Cancel button to close the modal */}
                <Button variant="default" onClick={onClose}>
                    Cancel
                </Button>
                {/* Crop & Save button to trigger the cropping and saving process */}
                <Button onClick={handleSave} disabled={CropBoxInfo == null ? true : !CropBoxInfo.canBeCropped}>
                    Crop & Save
                </Button>
            </Group>
        </Modal>
    );

};

export default ImageCropperModal;