import React, { useContext } from 'react';
import { Tooltip, Badge, NumberFormatter } from '@mantine/core';
import { ConfigContext, GlobalDataContext } from '../contexts';
import { Customer, Portfolio } from '../types';

interface PositionBadgesProps {
    portfolioData: Portfolio[];
    customerData: Customer;
}

const PositionBadges: React.FC<PositionBadgesProps> = ({ portfolioData, customerData }) => {

    //use context data
    const configContext = useContext(ConfigContext);
    const globalDataContext = useContext(GlobalDataContext);

    // Calculate portfoliosTotalValue
    let portfoliosTotalValue = -1;
    if (portfolioData && portfolioData.length > 0) {
        const clientHavePortfolios = portfolioData.filter((portfolio) => portfolio.portfolioTypeID != 145).length > 0;
        if (clientHavePortfolios) {
            portfoliosTotalValue = 0;
            portfolioData.forEach(portfolio => {
                portfoliosTotalValue += portfolio.position?.cash ?? 0;
                portfoliosTotalValue += portfolio.position?.securities ?? 0;
            });
            portfoliosTotalValue = Math.round(portfoliosTotalValue);
        }
    }

    // Calculate fundsTotalValue
    let fundsTotalValue = -1;
    if (customerData.holdings) {
        fundsTotalValue = 0;
        Object.values(customerData.holdings).forEach((holding) => {
            let fund = globalDataContext.funds?.find(obj => obj.code === holding.code);
            if (fund) {
                if (fund.currency === "KWD") {
                    fundsTotalValue += holding.total;
                } else {
                    let rate = parseFloat(configContext.exchange_rates?.rates[fund.currency] ?? 0);
                    fundsTotalValue += (holding.total * rate);
                }
            }
        });
        fundsTotalValue = Math.round(fundsTotalValue);
    }

    return (
        <>
            {fundsTotalValue >= 0 && (
                <Tooltip label="Funds">
                    <Badge color="pink" size="xs" rightSection="KWD" mr={5}>
                        <NumberFormatter value={fundsTotalValue} thousandSeparator="," />
                    </Badge>
                </Tooltip>
            )}
            {portfoliosTotalValue >= 0 && (
                <Tooltip label="Portfolios">
                    <Badge color="purple" size="xs" rightSection="KWD" mr={5}>
                        <NumberFormatter value={portfoliosTotalValue} thousandSeparator="," />
                    </Badge>
                </Tooltip>
            )}
        </>
    );
};

export default PositionBadges;
