import { Loader } from '@mantine/core'

import React, { useEffect, } from 'react'

import { useParams, } from 'react-router-dom';


import FormCardWrapper from '../../components/form_card_wrapper';

import { collectionGroup, onSnapshot, query, where } from 'firebase/firestore';

import TransactionForm from './transaction_form';
import { useFirestore } from '../../helpers/firebaseContext';



const TransactionDetails = () => {


    const [transactionObj, setTransactionObj] = React.useState(null as any);
    const db = useFirestore();
    const { transactionID } = useParams();







    useEffect(() => {
        const transcationQuery = query(query(collectionGroup(db, "transactions")), where('id', '==', transactionID))
        onSnapshot(transcationQuery, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.docs.length);
                // return;
            } else {
                console.log("Server data", snapshot.docs.length);
            }

            // console.log("Setting transaction obj");
            if (!snapshot.empty) {
                setTransactionObj(
                    { ...snapshot?.docs[0]?.data(), transcationRef: snapshot?.docs[0].ref }
                )
            }

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionID])

    return (
        <>
            {transactionObj ? <TransactionForm
                transactionObj={transactionObj}
                disabled={true}
                title={"Transaction Details"}
            /> :
                <FormCardWrapper >
                    <Loader />
                </FormCardWrapper>
            }

        </ >



    )
}

export default TransactionDetails