import { Accordion, Table, Title, Text, Group, Paper } from '@mantine/core';
import { useContext } from 'react';
import { CustomerFundData, CustomerFundDataTransaction } from '../../types';
import NumberDisplay from '../../components/NumberDisplay';
import { ConfigContext } from '../../contexts';

export interface CustomerFundsTransactionsProps {
  fundData: CustomerFundData[];
}


export const CustomerFundsTransactions = ({ fundData }: CustomerFundsTransactionsProps) => {

  //use context data
  const configContext = useContext(ConfigContext);


  if (!fundData || fundData.length <= 0) {
    return <Paper ta={'center'} p={25} ><Text>There are no records to display</Text></Paper>
  }


  return (
    <Accordion chevronPosition="right" variant="contained">
      {fundData?.map(fundDataRow => {
        let fundTransactions: CustomerFundDataTransaction[] = []
        Object.entries(fundDataRow.transactions!).forEach(([key, transactionRow]) => {
          if (transactionRow.currency !== "KWD" && transactionRow.currency) {
            let rate = parseFloat(configContext.exchange_rates?.rates[transactionRow.currency] ?? 0);
            transactionRow.amountInKWD = ((transactionRow.amount ?? 0) * rate);
          } else {
            transactionRow.amountInKWD = transactionRow.amount;
          }
          fundTransactions.push(transactionRow);
        });
        fundTransactions = fundTransactions.sort((a, b) => (b.deal_date?.toMillis() ?? 0) - (a.deal_date?.toMillis() ?? 0));

        return <Accordion.Item value={fundDataRow.gl_code!} key={fundDataRow.gl_code}>
          <Accordion.Control>

            <Group >
              <Title order={4}>{fundDataRow.gl_name}</Title>
            </Group>

          </Accordion.Control>
          <Accordion.Panel>
            <Paper shadow="xs" p="md">
              <Table striped withTableBorder withColumnBorders ta="center" >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th ta="center">Transaction Date</Table.Th>
                    <Table.Th ta="center">Type</Table.Th>
                    <Table.Th ta="center">Description</Table.Th>
                    <Table.Th ta="center">Amount</Table.Th>
                    <Table.Th ta="center">Amount (KWD)</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {fundTransactions.map(transaction => {
                    return <Table.Tr>
                      <Table.Td>{transaction.deal_date?.toDate().toLocaleDateString("en-gb") ?? "-"}</Table.Td>
                      <Table.Td>{transaction.type}</Table.Td>
                      <Table.Td>{transaction.description}</Table.Td>
                      <Table.Td><NumberDisplay value={transaction.amount} withCommas currency={transaction.currency} /> </Table.Td>
                      <Table.Td><NumberDisplay value={transaction.amountInKWD} withCommas /> </Table.Td>
                    </Table.Tr>
                  })}
                </Table.Tbody>
              </Table>
            </Paper>
          </Accordion.Panel>
        </Accordion.Item>
      }
      )}

    </Accordion>
  )

}
