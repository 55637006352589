import { useContext, useMemo, useState } from "react";
import { CustomClaims, RMUser } from "../../types";

import { QueryDocumentSnapshot, where } from "firebase/firestore";
import CustomDataTable from "../../components/custom_data_table";
import { Badge, Button, Group, Menu, Modal, Title, Tooltip, UnstyledButton } from "@mantine/core";
import { BsPlusCircle, BsThreeDotsVertical } from "react-icons/bs";
import AddRmForm from "./AddRmForm";
import { IconSettings } from "@tabler/icons-react";
import UpdateRMPasswordModal from "./UpdateRMPasswordModal";
import { useDebouncedState } from "@mantine/hooks";
import useLocalStorage from "use-local-storage";
import { MyUserDataContext } from "../../contexts";
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';


const TABLE_KEY = "rms";

const KfhRMSettings = () => {

    const userContext = useContext(MyUserDataContext);
    let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};


    const [addRmModal, setAddRmModal] = useState<boolean>(false);
    const [updateRmModal, setUpdateRmModal] = useState<boolean>(false);
    const [rmUser, setRmUser] = useState<RMUser | null>();
    const [storedSearchText, setStoredSearchText] = useLocalStorage<{
        [key: string]: string[] | string;
    }>("storedSearchText", { [TABLE_KEY + "-searchText"]: "" });

    const [customFilters, setCustomFilters] = useDebouncedState({
        [TABLE_KEY + "-searchText"]: storedSearchText[TABLE_KEY + "-searchText"] || "",
    }, 800);

    const handleStatusUpdate = (row: RMUser) => {
        setAddRmModal(true)
        setRmUser(row)
    }
    const handleChangePassword = (row: RMUser) => {
        setUpdateRmModal(true)
        setRmUser(row)
    }

    const columns: any[] = [

        {
            name: "ID",
            selector: (row: QueryDocumentSnapshot<RMUser>) => row.data().rm_id,
            sortable: true,
            sortField: "rm_id"
        },
        {
            name: "Name",
            selector: (row: QueryDocumentSnapshot<RMUser>) => {
                return <Tooltip openDelay={1000} label={row.data().name_en}>
                    <span>{row.data().name_en}</span>
                </Tooltip>
            },
            sortable: true,
            sortField: "name_en"
        },
        {
            name: "Email",
            selector: (row: QueryDocumentSnapshot<RMUser>) => {
                return <Tooltip openDelay={1000} label={row.data().email}>
                    <span>{row.data().email}</span>
                </Tooltip>
            },
            sortable: true,
            sortField: "email"
        },
        {
            name: "Role",
            selector: (row: QueryDocumentSnapshot<RMUser>) => {
                switch (row.data().role) {
                    case "rm":
                        return <Badge color="#4682B4" size="sm">{row.data().role}</Badge>
                    case "operations":
                        return <Badge color="#D2691E" size="sm">{row.data().role}</Badge>

                    default:
                        return <span></span>

                }
            },
            sortable: true,
            sortField: "role"
        },
        {
            name: "Segment",
            selector: (row: QueryDocumentSnapshot<RMUser>) => {


                switch (row.data().segment) {
                    case "Private Banking":
                        return <Badge color="#c5a548" size="sm">{row.data().segment}</Badge>
                    case "Individual Finance":
                        return <Badge color="orange" size="sm">{row.data().segment}</Badge>
                    case "Ruwad":
                        return <Badge color="royalblue" size="sm">{row.data().segment}</Badge>
                    case "Tamayoz":
                        return <Badge color="maroon" size="sm">{row.data().segment}</Badge>
                    case "KFH Capital":
                        return <Badge color="forestgreen" size="sm">{row.data().segment}</Badge>

                    default:
                        return <span></span>

                }
            },
            sortable: true,
            sortField: "segment"
        },
        {
            name: "Active",
            selector: (row: QueryDocumentSnapshot<RMUser>) => row.data().active ? <IconCircleCheck color="green" /> : <IconCircleX color="red" />
            ,
            sortable: true,
            sortField: "active"
        },
        {
            name: "Update",
            selector: (row: QueryDocumentSnapshot<RMUser>) =>
                <>
                    <Menu shadow="md" width={200}>
                        <Menu.Target>
                            <UnstyledButton variant="">

                                <BsThreeDotsVertical size={16} />
                            </UnstyledButton>
                        </Menu.Target>

                        <Menu.Dropdown>
                            {/* <Menu.Label>Application</Menu.Label> */}
                            <Menu.Item leftSection={<IconSettings />} onClick={() => handleStatusUpdate(row.data())}>
                                Update User
                            </Menu.Item>
                            <Menu.Item leftSection={<IconSettings />} onClick={() => handleChangePassword(row.data())}>
                                Change Password
                            </Menu.Item>


                        </Menu.Dropdown>
                    </Menu>


                </>

            ,
            sortable: false,
        },
    ]


    const rmsTableMemo = useMemo(() => {

        let rmSearchQueries = [
            // where("role", "==", "rm"),
        ];

        if (userClaims.kfh_oper || userClaims.kfh_rm) {
            rmSearchQueries.push(where("segment", "==", userClaims.segment));
        }

        return <CustomDataTable

            tableKey={TABLE_KEY}
            columns={columns}
            customQueries={{
                "rms-searchQ": rmSearchQueries,
            }}

            orderByAttribute={"email"} />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customFilters])

    return (
        <div>
            <Group justify="space-between">
                <Title>KFH RMs</Title>
                <Button
                    leftSection={<BsPlusCircle />}
                    onClick={() => setAddRmModal(true)}
                >
                    Add RM
                </Button>
            </Group>
            <Modal
                title=
                {<Title>
                    {rmUser ? "Update" : "Add"} KFH RM
                </Title>}
                opened={addRmModal}
                onClose={() => {
                    setAddRmModal(false)

                    setRmUser(null)
                }

                }
            >
                <AddRmForm rmUser={rmUser}
                    handleSubmit={() => {
                        setAddRmModal(false)
                        setRmUser(null)
                    }
                    }

                />

            </Modal>
            {rmUser &&
                <>
                    <UpdateRMPasswordModal
                        open={updateRmModal}
                        user={rmUser}
                        onClose={() => {
                            setUpdateRmModal(false)
                            setRmUser(null)
                        }} />

                </>
            }


            {rmsTableMemo}

        </div>
    )
}

export default KfhRMSettings