import React from "react";

import { Card, Group, Space, Stack, Text, Container, TextInput, SegmentedControl } from "@mantine/core";

import { Button } from "@mantine/core";

import OpenAI from "openai";
// import { ChatCompletionRequestMessage, Configuration, OpenAIApi } from "openai";

import { useForm } from '@mantine/form';

//I know this is stupid ok.  If someone finds out, I'll just revoke.
let k = "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";

const openai = new OpenAI({
    apiKey: k,
    dangerouslyAllowBrowser: true
});



export default function Robo() {



    console.log("Redraw!!!");

    // const [question, setQuestion] = useState('');
    const [answer, setAnswer] = React.useState<string | undefined>("");
    const [fund, setFund] = React.useState<string | undefined>("");
    const [service, setService] = React.useState<string | undefined>("");

    const [pending, setPending] = React.useState(false);


    const form = useForm({
        initialValues: {
            question: '',
            language: "English",
        },

        validate: {
            question: (value) => value.length > 0 ? null : 'Ask a question!',
        },
    });


    return (
        <Container size="xs" px="xs">


            <Card shadow="sm" p="lg" radius="md" px="xs" withBorder>
                <Stack>
                    <Text>Hello, I am your KFH Capital financial advisor AI and I will assist you as best I can.  Ask me a question!</Text>


                    <form onSubmit={form.onSubmit(async (values) => {

                        setAnswer("");
                        setFund("");
                        setService("");


                        let params: OpenAI.Chat.ChatCompletionCreateParamsNonStreaming = {
                            model: "gpt-4-1106-preview",

                            messages: [
                                {
                                    "role": "system", "content": `You are a financial advisor for the investment company KFH Capital.  You will be providing advice to new customers on what service to choose and what fund to invest in.

- Reason why you chose that specific service and/or fund.
- Use as much marketing as possible
- Give instructions to contact KFH Capital at 1801212 for more information.
- You can provide multiple services.

You can only use the following information to answer the questions:

We have three services available: Portfolio Management, Funds, and Trading services.


--- For Trading Services, the code is KFHTRADE.  We have this description:

We make trading effortless with unrivalled brokerage services offered through KFHTrade. All our stocks are Islamic Shariah compliant.
Supported by a dedicated team of brokers, this seamless and advanced online platform is marked by its convenience in oﬀering investors multiple services to access local and GCC markets through a single login.

--- For Portfolio Management, the code is PORTFOLIOS.  We have this description:
The department provides customized investment solutions to the investors and access to opportunities in emerging sectors – both traditional and alternative – across all major asset classes, for enhanced portfolio performance.
We offer discretionary and non-discretionary portfolio management services in local & GCC markets, facilitate our clients’ actions to trade in the regional markets and provide an online platform to view live market updates through KFH Trade.
Our track record of success and customer satisfaction is driven by our highly professional team who specialize in devising rational, research-based portfolio-specific strategies, ensuring a balance of risk versus rewards.
Our work with clients goes beyond managing their portfolios. We support, guide, and consult them throughout their investment journey.
We continuously evaluate economic trends and market ﬂuctuation. Our client portfolios are consistently reviewed, assessed and adjusted accordingly to achieve the desired investment goals.
We devise strategies aimed to mitigate risks. Our portfolio management model includes ascertaining our clients’ asset base, identifying their short and long-term objectives, and gauging their investment risk tolerance to ensure their investments are safe.


--- For funds, the code is FUNDS.  The 3 open-ended funds are as follows:
1 - MMF:
Name in English: Money Market Fund
Name in Arabic: صندوق بيتك كابيتال لأسواق النقد بالدينار الكويتي

Description in English: The Fund strives to provide attractive investment opportunities for those interested in investing in money market instruments such as bank deposits, high credit quality sukuk, and other money market fund units in Kuwait, GCC, and other countries. The Fund will operate in accordance with the principles and precepts of Islamic Shari’a.
Description in Arabic: يهــدف صنــدوق بيتــك كابيتــال لأســواق النقــد بالدينــار الكويتــي إلــى توفيــر فــرص اســتثمارية جذابــة للراغبيــن فــي الاســتثمار فــي أدوات النقـد مثـل الودائـع المصرفيـة، والصكـوك عاليـة الجـودة الائتمانيـة، ووحـدات صناديـق اسـواق النقـد بدولـة الكويـت ودول ً مجلس التعاون لدول الخليج العربية والدول الاخرى. كما سيعمل الصندوق وفقا لمبادئ وتعاليم الشريعة الإسلامية.

Key Fund Facts

NAV: 1.06224 KD
Weekly Annualized Return 3.832 %
Annualized YTD return 3.694 %
Return Since Inception 6.224 %
Fund Type	: Public
Fund Structure	: Open-Ended
Inception Date	: August 2019
Fund Tenor	: 15 calendar years (renewable)
Domicile	: Kuwait
Nominal Unit Value	: 1 unit = 1KD (Subcsription/Redemption based on NAV)
Periodic NAV Calculation	: Weekly on Tuesday
Subscription/Redemption Cycle	: Weekly on Monday
Subscription/Redemption Fee	: None
Minimum Subscription	: 10,000 units


2- GCC:
Name in English: GCC Equities Fund
Name in Arabic: صندوق الأسهم الخليجية
Description in English: The Fund aims to provide an attractive investment opportunity for investors, who are focusing on listed stocks in the Kuwait Stock Exchange and GCCs' markets that are compatible with the provisions of Islamic Sharia.
Description in Arabic:  يهـــدف الصنـــدوق إلـــى توفيـــر فرصـــة اســـتثمارية جذابـــة للمســـتثمرين الراغبين في الاســـتثمار في سوق الكويت للأوراق المالية وأســـواق دول مجلس التعـــاون الخليجي المتوافقة مع أحكام الشريعة الإسلامية.

Key Fund Facts

Net Asset Value: 1.099 KD
YTD Return: 0.4%
Return Since Inception: 9.86 %

Fund Type	: Public
Inception Date	: September-08
Fund Structure	: Open-Ended
Fund Manager	: KFH Capital Investment Company
Par Value	: 1 KD
Fund Valuation	: Weekly
Redemption Frequency	: Weekly
Subscription Frequency	: Weekly
Redemption Fee	: No Redemption Fee
Annual Management Fees	: 1.50%
Subscription Fees	: 1.00%


3- SUKUK: 

Name in English: Sukuk fund
Name in Arabic: صكوك
Description in English:  To generate appropriate returns with capital growth.  To invest in Sukuks diversified by country, sector, issuer and credit structure in order to maximize the diversification. Construct a diversified portfolio comprising Senior and Sub-ordinated USD denominated rated and un-rated Sukuk. May also invest in money market funds or undertake treasury placements.
Description in Arabic:


Key Fund Facts

NAV:   $10.16
Return Since Inception:   27.60%
Inception Date: June-2016
Highest NAV: 10.59 09-Jan-20
Lowest NAV: 9.64 16-Apr-20
Minimum Subscription: 3,000 Units
Fund Valuation: Weekly
Subscription Frequency: Weekly
Redemption Frequency: Weekly
Subscription Fees: None
Redemption Fees: None
Annual Management Fees: 0.50%





` },
                                { "role": "user", "content": values.question + `.  Answer only in JSON in the following format:  {"answer": "your answer here in ${values.language}", "funds": [fund codes here], "services": [service codes here], "confidence": "confidence level here from 1 to 5"}` },
                            ]
                        };



                        setPending(true);

                        try {
                            const res = await openai.chat.completions.create(params);

                            console.log(res);
                            try {
                                let r = JSON.parse(res.choices[0].message?.content!);
                                setAnswer(r.answer);
                                setFund(r.funds.join(", "));
                                setService(r.services.join(", "));

                            } catch (error) {
                                setAnswer(res.choices[0].message?.content || "unknown error");

                            }



                        } catch (error) {
                            console.log(error);
                            setAnswer("Error " + error);

                        }






                        setPending(false);

                    })}>


                        <TextInput {...form.getInputProps('question')} />

                        <Space h="xl" />
                        <Group justify="space-between">
                            <Button color="teal" loading={pending} type="submit">Ask!</Button>
                            <SegmentedControl
                                {...form.getInputProps("language")}


                                data={[
                                    { label: 'English', value: 'English' },
                                    { label: 'عربي', value: 'Arabic' },
                                ]}
                            />
                        </Group>

                    </form>


                </Stack>
            </Card>
            <Space h="xl" />
            <Card shadow="sm" p="lg" radius="md" px="xs" withBorder>
                <Stack>
                    <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{answer}</Text>
                    <Space h="xl" />

                    <Text td="underline">{service}</Text>
                    <Space h="xl" />
                    <Text fw={700}>{fund}</Text>
                </Stack>
            </Card>
        </Container>
    );
}
