import React from 'react';
import { Text, NumberFormatter } from '@mantine/core';

interface NumberDisplayProps {
  value?: number;
  withColor?: boolean;
  withBrackets?: boolean;
  withCommas?: boolean;
  withPercent?: boolean;
  currency?: string;
  customColor?: string
}

const NumberDisplay: React.FC<NumberDisplayProps> = ({
  value,
  withColor = false,
  withBrackets = false,
  withCommas = false,
  withPercent = false,
  currency = "",
  customColor,
}) => {
  const formatNumber = (num: number | undefined): React.ReactNode => {
    if (!num) return '-';
    const isNegative = num < 0;
    if ((withColor || withBrackets) && isNegative) {
      num = num * (-1);
    }
    num = parseFloat(num.toFixed(3));

    let formattedNumber: React.ReactNode = num;

    if (withCommas) {
      formattedNumber = (
        <NumberFormatter value={num} thousandSeparator="," />
      );
    }

    if (withBrackets && isNegative) {
      formattedNumber = <>({formattedNumber})</>;
    }
    if (withPercent) {
      formattedNumber = <>{formattedNumber} %</>;
    }

    return <>{formattedNumber} {currency}</>;
  };

  const getColor = (num: number | undefined): string => {
    if (customColor) return customColor;
    if (!withColor || num === undefined) return 'black';
    if (num > 0) return 'green';
    if (num < 0) return 'red';
    return 'black';
  };

  const displayValue = formatNumber(value);
  const color = getColor(value);

  return <Text size="sm" c={color}>{displayValue}</Text>;
};

export default NumberDisplay;
