import { useState, useEffect } from 'react';
import { onSnapshot, DocumentData, DocumentReference } from 'firebase/firestore';

type UseFirestoreDocResult<T> = {
    data: T | undefined;
    loading: boolean;
};

function useFirestoreDoc<T>(doc: DocumentReference<DocumentData>, setId: boolean = false): UseFirestoreDocResult<T> {
    const [data, setData] = useState<T | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        const unsubscribe = onSnapshot(doc, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.data());
            } else {
                console.log("Server data", snapshot.data());
            }

            const data = snapshot.data();
            if (setId && data) {
                data.id = snapshot.id;
            }

            setData(data as T);
            setLoading(false);
        });

        // Cleanup function to unsubscribe when the component unmounts or dependencies change
        return () => {
            unsubscribe();
        };
    }, []);
    console.log("useFirestoreDoc return: loading: ", loading, " data: ", data);

    return { data, loading };
}



export default useFirestoreDoc;
