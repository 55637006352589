import { useState, useEffect } from 'react';
import { onSnapshot, Query, DocumentData, QuerySnapshot } from 'firebase/firestore';

type UseFirestoreCollectionResult = {
  data: DocumentData[] | undefined;
  snapshot: QuerySnapshot<DocumentData> | undefined;
  loading: boolean;
};

function useFirestoreCollection(query: Query<DocumentData>, setId: boolean = false): UseFirestoreCollectionResult {
  const [data, setData] = useState<DocumentData[] | undefined>(undefined);
  const [snapshot, setSnapshot] = useState<QuerySnapshot<DocumentData> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const unsubscribe = onSnapshot(query, (firestoreSnapshot) => {
      if (firestoreSnapshot.metadata.fromCache) {
        console.log("Cached data", firestoreSnapshot.docs.length);
      } else {
        console.log("Server data", firestoreSnapshot.docs.length);
      }

      const data = firestoreSnapshot.docs.map((doc) => {
        const row = doc.data() as DocumentData;
        if (setId) {
          row.id = doc.id;
        }
        return row;
      });

      setData(data);
      setSnapshot(firestoreSnapshot);
      setLoading(false);
    });

    // Cleanup function to unsubscribe when the component unmounts or dependencies change
    return () => {
      unsubscribe();
    };
  }, []);

  return { data, loading, snapshot };
}

export default useFirestoreCollection;
