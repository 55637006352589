import { Box, PasswordInput, PasswordInputProps, Popover, Progress, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import React, { useState } from 'react'

interface PasswordStrengthComponentType {
    passwordValue: string
    passwordProps: PasswordInputProps
}

export const passwordRequirements = [
    { re: /[0-9]/, label: 'Must Include a number' },
    { re: /[a-z]/, label: 'Must Include a lowercase letter' },
    { re: /[A-Z]/, label: 'Must Include an uppercase letter' },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Must Include an special symbol' },
];




const getStrength = (password: string) => {
    let multiplier = password.length > 5 ? 0 : 1;

    passwordRequirements.forEach((requirement: any) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}


const PasswordRequirement = ({ meets, label }: { meets: boolean; label: string }) => {
    return <Text
        c={meets ? 'teal' : 'red'}
        style={{ display: 'flex', alignItems: 'center' }}
        mt={7}
        size="sm"
    >
        {meets ? (
            <IconCheck style={{ width: (14), height: (14) }} />
        ) : (
            <IconX style={{ width: (14), height: (14) }} />
        )}{' '}
        <Box ml={10}>{label}</Box>
    </Text>

}
const PasswordStrengthComponent = ({ passwordValue, passwordProps }: PasswordStrengthComponentType) => {


    const [popoverOpened, setPopoverOpened] = useState(false);





    const strength = getStrength(passwordValue);
    const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';
    const checks = passwordRequirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(passwordValue)} />
    ));
    return (
        <div>

            <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ transition: 'pop' }}>
                <Popover.Target>
                    <div
                        onFocusCapture={() => setPopoverOpened(true)}
                        onBlurCapture={() => setPopoverOpened(false)}
                    >
                        <PasswordInput
                            {...passwordProps}

                            withAsterisk
                            label="Password"
                            placeholder="password"

                        />
                    </div>
                </Popover.Target>
                <Popover.Dropdown>
                    <Progress color={color} value={strength} size={5} mb="xs" />
                    <PasswordRequirement label="Includes at least 8 characters" meets={passwordValue.length > 7} />
                    {checks}
                </Popover.Dropdown>
            </Popover>

        </div>
    )
}

export default PasswordStrengthComponent