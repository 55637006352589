import { useContext, useEffect } from 'react';
import { ConfigContext } from '../contexts';

import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import { Button, Group, Text } from '@mantine/core';





export function VersionChecker() {

  const configContext = useContext(ConfigContext);

  const version = Number(process.env.REACT_APP_VERSION || 0);


  useEffect(() => {
    if (version === 0) {
      console.warn("REACT_APP_VERSION not set.  Can't compare versions for version checker.");
    }
    else {


        if (Number(configContext.version?.webapp) > version) {
          console.warn("Version mismatch", configContext.version?.webapp, version);
          notifications.show({
            id: "version-mismatch",
            title: "Updated version",
            message: <Group><Text size='sm'>{`You are running an old version.  Please refresh.`}</Text> <Button onClick={() => window.location.reload()} size='xs'>Refresh</Button></Group>,
            color: "yellow",
            autoClose: false,
            icon: <IconExclamationCircle size={24} />,
          })
        }
      }
    }, [configContext.version?.webapp, version])

  console.log("VersionChecker", configContext.version?.webapp, version);

  return <></>;
}