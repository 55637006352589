
import { Button, Card, Grid, MultiSelect, Notification, NotificationProps, Space, Text, } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore';
import { areEqualArrays, getCountriesList } from '../../utils';
import { ConfigDataType } from '../../types';
import { ConfigContext, MyUserDataContext } from '../../contexts';
import { useFirestore } from '../../helpers/firebaseContext';


const RiskCountriesSettings = () => {

    const configContext: ConfigDataType = useContext(ConfigContext);
    const appContext: any = useContext(MyUserDataContext);

    const db = useFirestore();



    let allCountries = getCountriesList();

    const [riskCountries, setRiskCountries] = useState<string[]>();
    const [notificationData, setNotificationData] = useState<null | NotificationProps>();

    const handleChangeRiskCountries = (values: string[]) => {

        setRiskCountries(values.sort())

    }

    useEffect(() => {

        if (configContext?.risk?.countries)
            setRiskCountries(configContext?.risk?.countries.sort())
    }, [configContext?.risk?.countries])





    const updateRiskCountries = () => {

        setNotificationData(null)

        const configCollectionRef = doc(db, "config", "risk",);
        updateDoc(configCollectionRef, {
            "countries": riskCountries,
            "updatedBy":
                appContext?.msal?.account?.username
            ,
        }).then(() => {
            setNotificationData({ color: "green", children: <>Risk Countries updated Successfully</> })
        }).catch(() => {
            setNotificationData({ color: "red", children: <>Risk Countries Failed to Update</> })

        });
    }

    const resetRiskCountries = () => {

        setNotificationData(null)
        setRiskCountries(configContext?.risk?.countries)
    }

    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>
            <Grid
                justify='flex-start'
                ta='center'
            >

                {notificationData && <Grid.Col span={{ xs: 12 }}>

                    <Notification {...notificationData} onClose={() => setNotificationData(null)} />
                </Grid.Col>}
                <Grid.Col span={{ xs: 12 }}>
                    <Text fw={700} m={"xl"}>Risk Countries</Text>
                </Grid.Col>
                <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    <Grid.Col span={{ xs: 12 }}  >

                        <MultiSelect style={{ height: "100%" }}

                            data={allCountries}

                            value={riskCountries}
                            comboboxProps={{
                                withinPortal: true,
                                position: "bottom",
                            }}
                            // valueComponent={Item}
                            // itemComponent={Item}
                            searchable
                            onChange={handleChangeRiskCountries}
                            //  comboboxProps={{ withinPortal: true }}
                            // dropdownPosition="bottom"
                            placeholder="Choose Risk countries"

                        />
                    </Grid.Col>
                </Grid.Col>
                {!areEqualArrays(riskCountries, configContext?.risk?.countries) && <Grid.Col span={{ lg: 6, xs: 12 }}  >
                    {/* <Card shadow="sm" padding="lg" radius="md" withBorder> */}
                    <Grid justify='flex-start'>



                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                c={"green"}
                                variant='outline'
                                onClick={updateRiskCountries}
                            >
                                Submit Change
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={{ lg: 3, xs: 6 }}  >

                            <Button
                                variant='outline'
                                c={"red"}
                                onClick={resetRiskCountries}
                            >
                                Cancel
                            </Button>
                        </Grid.Col>
                    </Grid>

                    {/* </Card> */}

                </Grid.Col>}

            </Grid >
            <Space h="xl" />


        </Card >
    )
}

export default RiskCountriesSettings